import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { Interaction } from 'src/app/core/models/troly/interaction.model';
import { InteractionService } from 'src/app/core/services/troly/interaction.service';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'create-interaction-modal',
    templateUrl: './create-interaction.modal.html',
    styleUrls: ['./create-interaction.modal.scss'],
	 standalone: true,
	 imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule]
})
export class CreateInteractionModal extends TrolyModal<Interaction> {
   
	override readonly __name:string = 'CreateInteractionModal'
   
	@Input("record") record?: Interaction = new Interaction();
	@Input("customer") customer?: Customer;
    
	_formFields = ['id', 'body','source','customer_id','operation','direction','follow_up_in'];
	_formDefaults:Object = { source:'user', direction:'received', follow_up_in:'no' }

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected interactionService: InteractionService = inject(InteractionService);

	constructor() {
		super();

		this.log(`${this.__name}.constructor`, 'STACK');
			
		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.interactionService;

		this.initForm()
	}

	protected receiveInputParams(changes: SimpleChanges): void {
		
		if (changes.record.currentValue && !(changes.record.currentValue instanceof Interaction)) {
			this.record = new Interaction(changes.record.currentValue);
		}
		
		super.receiveInputParams(changes);
		this.setTemporaryWindowTitle({ name : 'pending' })  // needs to be after the call to super to ensure nowEditingRecord is set correctly.

	}

	onSubmit(): void {
		
		this.markAsLoading(this.form);
		let changes = this.form.getChanges();

		this.form.disable();
		if (!changes) {
			this.form.resetCodes({error: 'NOCHANGE' });
			this.markAsLoaded(this.form);
			this.form.enable()
		} else {
			
			this.service.saveOrCreate(new Interaction(changes)).subscribe((_) => {

					this.form.patchValue({'id':_.id});
					this.form.resetCodes({success: (this.record.id ? 'SAVED':'CREATED') });

					this.record = _ as Interaction;
					
					// delay redirection by a second so the user can see the success message
					this.form._timer = setTimeout(() => {
						this.resolveModal('timeout');
					}, this.form.countdown());

			}, (err) => {
					if (this.form.checkObjectErrors(err.error)) {
						this.markAsLoaded(this.form);
					} else {    
						this.form.setApiError('9428-sentinel-COORDINATE-red',this,'form');
					}
			});
		}
	}
}
