import { Injectable } from '@angular/core';

import { Customer } from '../../models/troly/customer.model';
import { TrolyObject } from '../../models/troly_object';
import { ITrolyService, TrolyService } from './troly.service';

import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Address } from '../../models/troly/address.model';
import { Membership } from '../../models/troly/membership.model';
import { Order } from '../../models/troly/order.model';
import { DemographicStat } from '../../models/troly/stats.model';
import { CustomerTag, Tag } from '../../models/troly/tag.model';

import { TrolySearch } from '../../models/form_objects';
import { PaymentCard } from '../../models/troly/payment_card.model';
import { Task } from '../../models/troly/task.model';
import { uuid } from '../../models/utils.models';
import { ITaggableService, TaggableModule } from './modules/taggable.module';

@Injectable({
	providedIn: 'root',
})
/*
  This class is in charge of all loading and unloading of a customer's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current customer to the customer in question
*/
export class CustomerService extends TrolyService<Customer> implements ITaggableService<CustomerTag>, ITrolyService<Customer> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * @see TrolyService.__name
	 */
	override readonly __name:string = 'CustomerService';

	/**
	 * And provides additional (reused) functions for dealing with tags within the context of a Customer
	 */
	public taggableModule: TaggableModule = new TaggableModule(this);

	/**
	 * 
	 * @param http 
	 * @param msg 
	 */
	constructor() { super('customers'); }

	public make(payload: {} = {}): Customer { return payload instanceof Customer ? payload : new Customer(payload); }


	public createCard(payload: PaymentCard, record?:Customer): Observable<PaymentCard> {
		return this.post(payload, `${record?.id ||  this.record$.value.id}/cards`).pipe(map(_ => new PaymentCard(_.body['payment_card'])));
	}
	public removeCard(id: uuid, record?:Customer): Observable<PaymentCard> {
		return this.delete(new Customer({ id: record?.id || this.record$.value.id }), `/cards/${id}`).pipe(map(_ => new PaymentCard(_.body['payment_card'])))
	}

	public createAddress(payload: Address, record?:Customer): Observable<Address> {
		return this.post(payload, `${record?.id || this.record$.value.id}/addresses`).pipe(map(_ => new Address(_.body['address'])));
	}
	public removeAddress(id: uuid, record?:Customer): Observable<Address> {
		return this.delete(new Customer({ id: record?.id || this.record$.value.id }), `/addresses/${id}`).pipe(map(_ => new Address(_.body['address'])))
	}

	/**
	 * Is responsible for updating a tag attached to this a Customer record.
	 * @param verb 
	 * @param id 
	 * @param obj 
	 * @param record 
	 * @returns 
	 */
	public updateTag(verb, id:uuid, obj?:CustomerTag, record?:Customer): Observable<CustomerTag> {
		// this uses a call (instead of create/save/delete) to more easily handle every operations in one call. Also must handles a raw api HttpResponse 
		return this.call<CustomerTag>(verb, obj, `${record?.id || this.record$.value.id}/tags/${id}`).pipe(map(_ => new CustomerTag(_.body['customer_tag'])))
	}

	public searchTags(params?: {}, record?: Customer): Observable<TrolySearch<Tag>> {
		
		let obj = this.make({id: params['customer_id'] || record?.id })
		delete params['customer_id']

		return this.call<TrolySearch<Tag>>('get', obj, 'tags/search', params).pipe(map(_ => {
			let search = new TrolySearch<Tag>(_.body, 'tags');
			search.results = search.results.map(_ => new Tag(_));
			return search;
		 }))
	}
	
	public makeObjectTag(payload: {}): CustomerTag { return payload instanceof CustomerTag ? payload : new CustomerTag(payload); }

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadMemberships(params?: {}, record?: Customer): Observable<Membership[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('memberships')

		if (!force && record.memberships) { return of(record.memberships) }

		return this.getList<Membership>(obj, 'memberships', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new Membership(o)) ))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadCustomerTags(params?: {}, record?: Customer): Observable<CustomerTag[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('customer_tags')

		if (!force && record.customer_tags) { return of(record.customer_tags) }
	
		return this.getList<CustomerTag>(obj, 'tags', params, force, 'customer_tags').pipe(filter(_ => !!_), map(list => list.map(o => new CustomerTag(o))))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadOrders(params?: {}, record?: Customer): Observable<Order[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('orders')

		if (!force && record.orders) { return of(record.orders) }

		return this.getList<Order>(obj, 'orders', params, force).pipe(filter(_ => !!_), map(list => list.map(_ => new Order(_)) ))
	}

	public loadDemographicStat(params?: {}, record?: Customer): Observable<DemographicStat> {
		
		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('stat')

		if (!force && record.stat) { return of(record.stat) }

		return this.getSingleObject<DemographicStat>(obj, params, 'stat', force, 'demographic_stat').pipe(filter(_ => !!_), map(o => new DemographicStat(o) ))
	}

	public loadDemographicStats(params?: {}, record?: Customer): Observable<DemographicStat[]> {
		
		params ||= {}
		record ||= this.record$.value
		
		params['limit'] = params['limit'] || 2
		params['range'] = params['range'] || 'month'

		let obj = this.make({id: params['customer_id'] || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		delete params['customer_id']

		const force = record && record.isStale('stats')

		return this.getSingleObject<DemographicStat[]>(obj, params, 'stats', force, 'demographic_stats').pipe(filter(_ => !!_)).pipe(map(list => list.map(o => new DemographicStat(o)) ))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadTasks(params?: {}, record?: Customer): Observable<Task[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('tasks')

		if (!force && record.tasks) { return of(record.tasks) }

		return this.getList<Task>(obj, 'tasks', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new Task(o)) ))
	}

	public notify(template_name, type:'email'|'sms'|'all', customer_ids, body?:string, subject?:string): void {

		if (!Array.isArray(customer_ids)) { customer_ids = [customer_ids] }

		const payload = new TrolyObject('', { 
			template: template_name, 
			ids: customer_ids,
			subject:subject,
			body:body
		})

		this.call<any>('post', payload as Customer, `send_message/${type}`).subscribe((_) => {
			this.snack('Message successfully sent', 'success')
		}, (err) => {
			this.snack('Could not send message', 'error')
		});
	}

}
