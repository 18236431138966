import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Company } from '../../models/troly/company.model';
import { Signup } from '../../models/troly/signup.model';
import { User } from '../../models/troly/user.model';
import { ITrolyService, TrolyService } from './troly.service';

@Injectable({
    providedIn: 'root',
})

/**
 * This class is in charge of all loading and unloading of a Signup profile. 
 * There can only ever be a single profile loaded for a given app instance.
 */
export class SignupService extends TrolyService<Signup> implements ITrolyService<Signup> {
	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'SignupService';

	// Use singular to get the company object in response for step 1
	constructor() {
		// the signup service is a little special -- while hitting the /signup endpoint, we receive different objects based on which method we use
		// and so as a result, we prefer to enforce wrong values here and explicitely define what nodes we're looking for in each response
		super('signup','xxx','xxx') // we don't expect to use any of the API node names so setting a dodgie value here
	}
	public make(payload: {} = {}): Signup { return payload instanceof Signup ? payload : new Signup(payload); }

	/**
	 * !!! for some reason the API doesn' return the company logo_img in the response (When serializing Company.account_partners[] as Company[] -- suspected TaggedUploader mounted and un/serialized)
	 * !!! this is a quick bypass to retrieve the company obj when referral is valid.
	 * @param uuid 
	 * @returns 
	 */
	public findCompany(uuid): Observable<Company> {
		return this.call<Company>('get', null, `${uuid}`).pipe(map(_ => new Company(_.body['company'])))
	}


	/**
	 * Calls the (PUT) /signup/:id/company endpoint to save changes to an existing company, OR (POST) /signup/company to create a new company.
	 * @param company the object to update (with the id set) and (optionally) products
	 * @returns 
	 */
	public saveCompany(company: Company, params?:{}): Observable<Company> {
		
		company.id ||= this.record$.value?.company?.id;
		const verb = company.id ? 'put' : 'post';

		// explicitely set the company id in the url given this is the SignupService 
		// (and the GenericService only handles this for a  'Sinupg' object, not a 'Company' object)
		const method = (company?.id ? `${company.id}` : '')

		params ||= {}
		params['with_affiliated_companies'] ||= true; // request for partner to be returned with the response.
		params['with_products'] ||= true;

		return this.call<Company>(verb, company, method, params).pipe(
			map(_ => new Company(_.body['company'])),
			tap(_ => {
				
				if (!params?.['dryrun']) {
					// if we're just testing the request, don't update the record
					const _signup = this.record$.value || new Signup();
					this._next(Object.assign(_signup, {company: _, products: _.products}))
				}

				if (_.affiliated_companies?.length > 0) { 
					this.findCompany(_.affiliated_companies[0].id).subscribe(_partner => {
						const _signup = this.record$.value || new Signup();
						this._next(Object.assign(_signup, {partner: _partner}))
					})
				} else {
					const _signup = this.record$.value || new Signup();
					this._next(Object.assign(_signup, {partner: null}))
				}
				
			}))
	}


	/**
	 * Calls the (POST) /signup/:id/user endpoint to attach a user to a companny record (optionally create it first).
	 * Note: 
	 *   - a user attaching a freshly created company is made owner of that company. 
	 *   - if the company already exists, the user is NOT automatically granted access to the company (request must be accepted by the actual account owner)
	 *   - a user that already exist will NOT see their password updated by this call (irrespective of the company) and will need to login.
	 * @param user 
	 * @returns 
	 */
	public createUser(user: User, params?:{}): Observable<User> {

		// here, we're trying to POST /signup/:id/user where :id is the COMPANY ID and not the user id (which is unknown at this point)
		// `.call` only sets the /:id part of the URL if the object passed matches this.make() object -- here it doesn't

		const _signup = this.record$.value || new Signup();

		params ||= {}
		params['with_companies'] ||= true; // request for companies to be returned with the response.

		delete user['id'] // the ID is always automatically added (and forced) and must not be present as 'empty'
		
		return this.call<User>('post', user, `${_signup.company?.id}/user`, params).pipe(
			map(_ => new User(_.body['user'])),
			tap(_ => {
				if (!params?.['dryrun']) { 
					const _signup = this.record$.value || new Signup();
					this.record$.next(Object.assign(_signup, {user: _}));
				}
			})
		)
	}

	
	/**
	 * 
	 * @param company 
	 * @param action 
	 * @returns 
	 */
	public storedSignup(signup?: Signup): Signup {

		if (signup) {
			if ((typeof signup.id) == undefined) {
				window.localStorage.removetItem('su');
			} else {
				if (signup instanceof Signup) {
					window.localStorage.setItem('su', JSON.stringify(signup.toLocalStorage()));
				} else {
					console.warn(`storedSignup has not updated, data received is NOT instanceof Signup`, signup)
				}
			}
		}
		let value = window.localStorage.getItem('su');
		return value ? new Signup(JSON.parse(value)) : new Signup();
	}

}
