import { TopbarNotification } from "../topbar.models";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Carton } from "./carton.model";
import { Customer } from "./customer.model";
import { Document } from "./document.model";
import { Membership } from "./membership.model";
import { Payment } from "./payment.model";
import { Shipment } from "./shipment.model";
import { SalesStat } from "./stats.model";
import { OrderTag, Tag } from "./tag.model";
import { User } from "./user.model";
import { Warehouse } from "./warehouse.model";

export class Order extends TrolyObject {

	public override _trolyPropertyArray: {} = { shipment:Object };

	declare company_id: uuid
	declare customer_id: uuid
	declare membership_id: uuid
	declare membership_purchase_sequence: number
	declare recipient_id: uuid
	declare warehouse_id: uuid
	declare warehouse?: Warehouse;
	declare invoice_id: uuid

	declare batch_order_id: uuid
	declare batch_order_settings: {}

	declare billing_name: string;
	declare number: string;
	declare token: string;

	/**
	 * Notes for order processing
	 */
	declare product_notes: string /** shown before order is confirmed */
	declare payment_notes: string		/** shown before order is paid */
	declare packaging_notes: string	/* show before order is packed/labeled */
	/* ie gift message, or other automatically generated message */
	declare personal_message: string
	declare order_notes: string /* always shown */

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string
	declare readonly:boolean;

	/**
	 * 
	 */
	declare orderlines: Orderline[]; // always loaded with the object
	/** complete order content brokendown by carton, with allocated content */
	declare allocated_cartons: Carton[];
	declare preferred_carton: Carton;
	declare total_weight: number;

	/**
	 * Financial
	 */
	declare total_value_exc: number;
	declare total_value_inc: number;
	declare total_tax1: number;
	declare total_tax2: number;
	declare total_tax3: number;
	declare total_tax4: number;
	declare total_tax5: number;
	declare total_tax6: number;

	declare production_cost: number;

	declare tax_compliance_code: string;

	/**
	 * Order Processing Steps
	 */
	declare status: string;

	declare created_by_id: uuid
	declare confirmed_at: Date
	declare confirmed_by_id: uuid
	declare planned_processing_date: Date
	declare paid_at: Date
	declare paid_by_id: uuid
	declare packed_at: Date
	declare packed_by_id: uuid
	declare labelled_at: Date
	declare labelled_by_id: uuid
	declare planned_completion_date: Date
	declare completed_at: Date
	declare completed_by_id: uuid

	declare delay_until: Date
	declare lock: ILock;

	declare billing_name2: string
	declare billing_address: string
	declare billing_suburb: string
	declare billing_state: string
	declare billing_country: string
	declare billing_postcode: string

	declare billing_email: string
	declare billing_phone: string

	/**
	 * GPS Data
	 */
	declare billing_lat: string
	declare billing_lng: string

	/** The (sometimes indicative) latitude coordinate from where the order was received from */
	declare captured_lat: number
	/** The (sometimes indicative) longitude coordinate from where the order was received from */
	declare captured_lng: number
	/** The ip address from where the order was received from */
	declare captured_ip: string

	/**
	 * Tracking and Reporting
	 */
	/** System that originated/created this record from */
	declare track_source: string;
	/** The channel this order came from to the company */
	declare track_channel: string;
	/** The partner who has created this order for the company */
	declare track_partner: string;
	/** internal marketing campaign name */
	declare track_campaign: string;

	declare track_gender: string;
	
	declare referral_code_applied: string

	declare payment_status: string;
	declare last_payment_details: string[]; /** virtual field */
	declare last_shipment_details: string[]; /** virtual field */
	declare trade_account_enabled:boolean;
	declare is_guest_order: boolean;

	/**
	 * API Model Relationships, (usually) loaded after the object was loader
	 */
	declare payments?: Payment[];
	declare documents?: Document[];
	/** The actual Tag object applied to this order */
	declare tags?: Tag[];
	/** The weighted OrdersTag object applied to this order */
	declare order_tags?: OrderTag[];
	declare customer?: Customer;
	declare recipient?: Customer;
	declare shipment?: Shipment;

	declare membership?: Membership;

	declare stat?: SalesStat;
	declare stats?: SalesStat[];

	declare provider_data: {
		users: User[]
	}

	constructor(values?: Object) {
		super('order', values);

		if (values) {

			let k = 'warehouse'
			if (values[k]) { this[k] = new Warehouse(values[k]); }

			k='shipment'
			if (values[k]) { this[k] = new Shipment(values[k]); }

			k='membership'
			if (values[k]) { this[k] = new Membership(values[k]); }

			k = 'documents'
			if (values[k] && values[k].length && !(values[k][0] instanceof Document)) {
				values[k].map((obj, i) => { this[k][i] = new Document(obj) });
			}

			k = 'orderlines'
			if (values[k] && values[k].length && !(values[k][0] instanceof Orderline)) {
				values[k].map((obj, i) => { this[k][i] = new Orderline(obj) });
			}
		}
	}

	public toString() : string {
		return this.billing_name + (this.is_guest_order ? ` (${this.token.substring(0,2).toUpperCase()})` : '');
	}


	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			link: '/orders/' + this.id
		}, notification)) as TopbarNotification
	}

	public tagBySlug(tag: string): Tag | null {
		return this.tags?.find(_ => _.slug == tag) || null
	}
	public tagAsAssigned(tag: Tag): OrderTag {
		return this.order_tags?.find(pt => pt.tag_id == tag.id) || {} as OrderTag;
	}

	/** used to filter which tags are displayed on the first / important row, versus the other ones */
	public tagsForRow(no: number): Tag[] {

		const row0 = ['orders']

		if (!this.tags && this.order_tags) {
			this.tags = this.order_tags?.map(_ => _.tag)
		}

		const tags = this.tags?.filter(_ => _.category != 'config') || []

		if (no == 0) {
			return tags.filter(_ => row0.includes(_.category))
		} else if (no == 1) {
			return tags.filter(_ => !row0.includes(_.category) && ['marketing', 'products-beer', 'products'].includes(_.usage));
		} else if (no == 2) {
			return tags.filter(_ => !row0.includes(_.category) && ['customers', 'orders', 'companies'].includes(_.usage));
		} else {
			return []
		}
	}


	public get paymentToRefund(): Payment | null {
		const lastPayment:Payment = this.payments?.sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0]
		if (lastPayment && ['offline','charge','auth'].includes(lastPayment.trx)) {
			return lastPayment
		}
		return null
	}
	public get usersInvolved(): User[] { return (this.provider_data?.users || []).map(_ => new User(_)) }
	public user(id:uuid): User | null {
		return super._user(id, this.usersInvolved)
	}
	
	public get createdBy(): User | null  { return this.user(this.created_by_id) }
	public get confirmedBy(): User | null  { return this.user(this.confirmed_by_id) }
	public get paidBy(): User | null  { return this.user(this.paid_by_id) }
	public get packedBy(): User | null  { return this.user(this.packed_by_id) }
	public get labelledBy(): User | null  { return this.user(this.labelled_by_id) }
	public get completedBy(): User | null  { return this.user(this.completed_by_id) }

	/** Returns a 2 letter initials for this order based on the name, meta, or whether it's a  */
	public initials(guest_customer_id?:uuid):string {
		if (this.customer_id != guest_customer_id && this.billing_name) {
			const parts = this.billing_name.split(/ /)
			if (parts.length > 1) {
				return parts[0][0] + parts[1][0]
			} else {
				return parts[0].substring(0,2)
			}
		}
		return this.token.substring(0,2)
	}
	public get fname():string {
		return this.billing_name?.split(' ')[0];
	}

	public genderBgClass(gender?): string {
		gender ||= this.track_gender
		if (gender == 'female') { return 'bg-pink'; }
		if (gender == 'male') { return 'bg-primary'; }
		if (gender == 'non-binary') { return 'bg-success'; }
		return 'bg-light';
	}

	get personalmessage(): Document | null { return this.getDocument('personal_message','application/pdf') }
	get packinglist(): Document | null { return this.getDocument('packing_list','application/pdf') }
	get packinglists(): Document[] { return this.getDocuments('packing_list', 'application/pdf') }
	get packingsummary(): Document | null { return this.getDocument('packing_list', 'text/csv') }
	get label(): Document | null { return this.getDocument('shipping_label', 'application/pdf') }
	get labels(): Document[] { return this.getDocuments('shipping_label', 'application/pdf') }
	get labelsummary(): Document | null { return this.getDocument('shipping_label','text/csv') }
	get manifest(): Document | null { return this.getDocument('manifest','application/pdf') }
	get manifestsummary(): Document | null { return this.getDocument('manifest','text/csv') }
	get invoice(): Document | null { return this.getDocument('customer_invoice','application/pdf') }

	protected getDocument(doc_type?: string, media_type?: 'application/pdf'|'text/csv'): Document | null {
		if (this.documents) { return this.getDocuments(doc_type, media_type)[0] }
		return null;
	}
	
	protected getDocuments(doc_type?: string, media_type?: 'application/pdf'|'text/csv'): Document[] | null {
		if (this.documents) {
			return this.documents
				.sort((a, b) => b.created_at.getTime() - a.created_at.getTime()) // sort descending by date (latest first)
				.filter((_) => { return (!doc_type || _.doc_type == doc_type) && (!media_type || _.media_type == media_type) }) // filter on the doc type
		}
		return null;
	}

	/**
	 * 
	 * @param which_address 
	 * @returns 
	 */
	public captureAddressInParts(addr: string): boolean {
		const poBoxRegex = RegExp(/(PO BOX|GPO BOX|POST OFFICE BOX|P\.O\. BOX|LOCKED BAG|PRIVATE BAG)/, 'i');
		return addr && addr.match(poBoxRegex) != null;
	}

	set locked(value: ILock | string | boolean) {
		if (typeof value == 'object') {
			this.lock = value
		} else if (typeof value == 'boolean') {
			this.lock = value ? { purpose: '__tmp_' } : null
		} else if (typeof value == 'string') {
			this.lock = { purpose: value } as ILock
		}
	}

	get locked(): boolean {
		if (this.lock == null) {
			return false;
		}
		return Object.keys(this.lock).length > 0
	}

	get shippingFeeOrderline(): Orderline | null {
		return this.orderline_by_product('835aa120-6b4b-4253-ba02-5f9ebc9cfe7d') // this value is hardcoded in the backend API, @see constants.rb
	}

	get merchantFeeOrderline(): Orderline | null {
		return this.orderline_by_product('f60a7340-7430-4d51-a94b-64a567211b0e') // this value is hardcoded in the backend API, @see constants.rb
	}

	public get itemsToShip():  number {
		return 0
	}
	public get itemsInCart():  number {
		return this.orderlines?.reduce((partialSum, _) => partialSum + _.qty, 0) || 0
	}
	public orderline_by_product(product_id: uuid): Orderline | null {
		return this.orderlines?.find(_ => _.product_id == product_id)
	}

	public totalSaved():number {
		return this.orderlines?.map(ol => (ol.unit_price_retail - ol.unit_price_payable) * ol.qty).reduce((a,b) => a+b, 0) || 0
	}

	public contentCanBeChanged(): boolean {
		return ['draft','confirmed'].includes(this.status);
	}

	public get total_taxes():number {
		if (this.total_tax1 == undefined) { return 0 }
		return parseFloat(this.total_tax1.toString())+ parseFloat(this.total_tax2.toString()) + parseFloat(this.total_tax3.toString()) + parseFloat(this.total_tax4.toString()) + parseFloat(this.total_tax5.toString()) + parseFloat(this.total_tax6.toString());
	}
	
	public orderSubtotal(inclusive=true): number {
		if (this.total_value_exc == undefined) { return 0 }
		return parseFloat(this.total_value_exc.toString()) + (inclusive ? this.total_taxes : 0);
	}

	public canDo(operation?:string): boolean {
		return false;
	}

}

export class Orderline extends TrolyObject {

	declare qty: number;
	declare name: string;
	declare product_id: uuid;

	declare unit_price_retail: number;
	declare unit_price_payable: number;
	declare unit_price_applied: number;
	declare unit_price_override: number;
	
	declare display_only: boolean;
	declare customer_supplied_rating: number;
	declare customer_supplied_notes: string;

	declare tax1:number;
	declare tax2:number;
	declare tax3:number;
	declare tax4:number;
	declare tax5:number;
	declare tax6:number;

	declare total_value_exc: number;
	declare total_value_inc: number;

	declare bundle_content_ol_id: uuid;
	declare price: number;
	

	//* When true, will trigger the record to be deleted from the database, when passed to OrderService.save (given there's no direct Orderline API endpoit!) */
	declare _destroy: boolean; 

	constructor(values?) {
		super('orderline', values);

		if (values && values['provider_data']) {
			this.display_only = values['provider_data']['display_only'];
			this.unit_price_override = values['provider_data']['unit_price_override'];
			this.unit_price_applied = values['provider_data']['unit_price_applied'];
			this.customer_supplied_notes= values['provider_data']['customer_supplied_notes'];
			this.customer_supplied_rating= values['provider_data']['customer_supplied_rating'];
		}
	}

	public get total_taxes():number {
		return parseFloat(this.tax1.toString())+ parseFloat(this.tax2.toString()) + parseFloat(this.tax3.toString()) + parseFloat(this.tax4.toString()) + parseFloat(this.tax5.toString()) + parseFloat(this.tax6.toString());
	}

	public unitPricePaid(inclusive=true): number {
		return parseFloat(this.unit_price_payable.toString()) + (inclusive ? (this.total_taxes / this.qty) : 0);
	}
	public unitPriceRetail(inclusive=true): number {
		return parseFloat(this.unit_price_retail.toString()) + (inclusive ? (this.total_taxes / this.qty) : 0);
	}

}

export class BatchOrder extends TrolyObject {

	declare delivery_method: 'ship'|'collect';
	declare club_id:uuid;
	declare segment_id: string;
	declare processing_date: Date;
	declare shipment_date: Date;
	declare product_id: uuid;
	declare applicable_price: number;
	declare member_changes: string;
	declare keep_manual_adjustment: boolean;
	declare confirmation_required: boolean;
	declare send_notification: boolean;
	declare notification_subject: string;
	declare notification_body: string;
	declare include_members: 'all'|'scheduled'|'unscheduled';

	constructor(values?:{}) {
		super('batch_order', values);
	}
}

export interface ILock {
	job_id?: uuid;
	user_id?: uuid;
	user_name?: string;
	purpose: string,
	at?: Date
}