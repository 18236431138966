import { NgModule } from '@angular/core';

import { TrolyAngularModule } from '../angular.module';
import { TrolyMaterialModule } from '../material.module';
import { DatapointsWithChartsCard } from './datapoints-w-charts/datapoints-w-charts.card';

import { TrolySharedModule } from '../shared.module';
import { BarChartWidget } from './bar-chart/bar-chart.widget';
import { CalculatorWidget } from './calculator/calculator.widget';
import { CardBadgeWidget } from './cardbadge/card-badge.widget';
import { CircularChartWidget } from './circular-chart/circular-chart.widget';
import { DatapointWidget } from './datapoint/datapoint.widget';
import { SocketProgressWidget } from './sockets/socket-progress.widget';

const standardCardsDeclarations = [

	DatapointsWithChartsCard,
	DatapointWidget,
	BarChartWidget,
	CircularChartWidget,

	CalculatorWidget,
	SocketProgressWidget,
	CardBadgeWidget
]

@NgModule({

	imports: [

		TrolyAngularModule,
		TrolyMaterialModule,
		TrolySharedModule // needed for avatar rendering in RecentWidget

	],

	declarations: standardCardsDeclarations,
	exports: standardCardsDeclarations,
})
export class TrolyWidgetsModule { }
