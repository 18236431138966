import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'documentToUrl'
})
export class DocumentToUrlPipe implements PipeTransform {

	transform(result: any, length: number = 4): SafeHtml {
		let name = result.filename.split(/[\._]/);
		name = `${name[0]}_${name[1].substring(0, length)}.${name[2]}`

		return `<a href="${result.data.url}" target='blank'>${result.filename}</a>`
	}

}
