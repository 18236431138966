<div class="card {{ loading.record }} " [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

        <div class="{{ bsCssClassPrefix }}-header">
            <img [src]="record.getIcon()" style="width: 32px; height: 32px; margin-top:-5px;margin-bottom:-5px; border-radius:3px;"
                class="me-3" />

            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!record.isInstalled() && record.isRequestOnly()"><span> Access Request:</span> {{ record.nameWithoutCompany() }}</h4>
				<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!record.isInstalled() && !record.isRequestOnly()">{{ record.nameWithoutCompany() }} <span> ― {{ selectedCompany.business_name }}</span></h4>
				<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="record.isInstalled()">{{ record.name }} 
					<span class="badge bg-danger text-white" *ngIf="record.status == 'error'">{{ __name + '.Badges.' + record.status | translate }}</span>
					<span class="badge bg-yellow text-white" *ngIf="record.status == 'warning'">{{ __name + '.Badges.' + record.status | translate }}</span>
				</h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>

        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

            <div class="row">
                <div class="col-3"><!-- lhs -->
						<mini-nav-widget
							[(selected)]="focusOn"
							[menus]="[
								{ 
									value:'info',
									label: 'Info',
									icon: (focusOn == 'info' ? 'bxs-error-circle' : 'bx-error-circle'),
									disabled: false,
									visible: true
								},
								{
									value:'request',
									label: 'Request',
									icon: (focusOn == 'request' ? 'bxs-user-voice' : 'bx-user-voice'),
									disabled: false,
									visible: record.isRequestOnly()
								},
								{
									value:'config',
									label:'Configure',
									icon: (focusOn == 'config' ? 'bxs-cog' : 'bx-cog'),
									disabled: !record.isInstalled() || !hasConfigs(),
									visible: !record.isRequestOnly(),
									statusIcon: (record.status == 'connected' ? 'text-yellow bxs-error-circle' : '')
								},
								{
									value:'track',
									label: 'Track',
									icon: (focusOn == 'track' ? 'bxs-check-circle' : 'bx-check-circle'),
									disabled: !record.isInstalled() || record.status == 'connected',
									visible: !record.isRequestOnly(),
									statusIcon: (record.status == 'error' ? 'text-yellow bxs-error-circle' : '')
								},
								{
									value:'ops',
									label: 'On Demand',
									icon: 'bx-list-check',
									disabled: !record.isInstalled() || record.status == 'connected' || !record.ondemand,
									visible: !record.isRequestOnly()
								},
								{
									value:'help',
									label: 'Support',
									icon: (focusOn == 'help' ? 'bxs-help-circle' : 'bx-help-circle'),
									disabled: !record.isInstalled() || record.status == 'connected',
									visible: !record.isRequestOnly()
								}
							]"
						></mini-nav-widget>
                    
                </div><!-- /col-3 lhs -->

                <div class="col-9">
                    <!-- rhs -->
                    <form-status [formGroup]="form" (dismissCallback)="resolveModal($event)" [record_label]="record.name" successAction="hide">

                        <span #status code="OAUTH_CODE_CHECK">Verifying your credentials <header-loading-indicator [status]="form.loading"></header-loading-indicator></span>
                        <span #status code="OAUTH_ERROR_REMOTE">An error was encountered when trying to authenticate you. Please check your credentials and try again</span>
								<span #status code="OAUTH_ERROR_LOCAL">Could not validate your permissions</span>
								<span #status code="REQUESTED">Thank you. Your request has been recorded. <span class="text-primary cursor-pointer" (click)="resolveModal('close')">Closing...</span> ({{ form.seconds }})</span>
                        <span #status code="INSTALLED">{{ record.name }} has been activated and can now be configured</span>
								<span #status code="UNINSTALLED">{{ record.nameWithoutCompany() }} has been uninstalled
									<small *ngIf="form.seconds > 0">&nbsp;({{ form.seconds }})</small>
								</span>
								<span #status code="READY">Congratulations, {{ record.name }} is ready to be used.</span>

                    </form-status>

						  <div *ngIf="focusOn == 'info'" [ngTemplateOutlet]="infoTpl"></div>

                    <div *ngIf="focusOn == 'request'">
                        <p>Access to this app is restricted by {{ record.nameWithoutCompany() }}. Use the following form to
                            confirm your interest in having your Troly account connected with {{ record.nameWithoutCompany() }}.</p>
								<ngb-alert [dismissible]="false" *ngIf="record.created_at">
									<p>Your request was received on {{ record.created_at }}, and is shown here as a reference.</p>
									<p>You may also wish to follow up on your request in the <a href>community requests</a>.</p>
								</ngb-alert>
								<h5>Request Access to {{ record.nameWithoutCompany() }} for {{
									selectedCompany.business_name }}</h5>
								<p>Please explain who you currently use {{ record.nameWithoutCompany() }} and to what extent do you expect Troly to exchange some data with the platform.</p>
                        <div class="row" formGroupName="request_details">
                            <div class="col-10">
											<mat-form-field>
                                    <mat-label>Platform Details</mat-label>
                                    <textarea matInput formControlName="usage"></textarea>
                                </mat-form-field>

										  <h5>How much and how soon</h5>
										  <p>Confirm how valuable this is to you by selecting how often you use {{ record.nameWithoutCompany() }} and how frequently.</p>
                                <mat-form-field>
                                    <mat-label>I use {{ record.nameWithoutCompany() }} at least once..</mat-label>
                                    <mat-select formControlName="frequency">
                                       <mat-option value="daily">Daily</mat-option> 
													<mat-option value="weekly">Weekly</mat-option> 
													<mat-option value="daily">Monthly</mat-option> 
													<mat-option value="daily">Quarterly</mat-option> 
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>I would like to connect {{ record.nameWithoutCompany() }} with Troly.. </mat-label>
                                    <mat-select formControlName="urgency">
													<mat-option value="now">As soon as possible</mat-option> 
													<mat-option value="soon">Sometimes soon would be great</mat-option> 
													<mat-option value="later">Later is fine as I'm happy to wait for availability</mat-option> 
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>

                                
											<h5>Budget</h5>
											<p>To help the community manage their work and priorities, please indicate your budget and cost expectations. Tick all that applies.</p>
											<div class="ps-1">
												<mat-checkbox [disabled]="form.disabled" [checked]="form.get('request_details.budget').value.includes('none')" (change)="patchDirtyCheckbox('request_details.budget', $event.checked,'none')" value="none">I would only consider this if completely free</mat-checkbox>
												<mat-checkbox [disabled]="form.disabled" [checked]="form.get('request_details.budget').value.includes('some')" (change)="patchDirtyCheckbox('request_details.budget', $event.checked,'some')" value="some">I'd pay a fee for setup, if ongoing usage is free</mat-checkbox>
												<mat-checkbox [disabled]="form.disabled" [checked]="form.get('request_details.budget').value.includes('sponsor')"  (change)="patchDirtyCheckbox('request_details.budget', $event.checked,'sponsor')" value="sponsor">I would like to sponsor this app to expedite access</mat-checkbox>
												<mat-checkbox [disabled]="form.disabled" [checked]="form.get('request_details.budget').value.includes('ongoing')"  (change)="patchDirtyCheckbox('request_details.budget', $event.checked,'ongoing')" value="ongoing">I understand ongoing value means ongoing fee</mat-checkbox>
											</div>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="focusOn == 'ops'" [ngTemplateOutlet]="operationsTpl"></div>
                    <div *ngIf="focusOn == 'config'">

                        <p *ngIf="record.env?.admin_url">Your connected {{ record.nameWithoutCompany() }} is available from the <i
                                class="bx bx-customize align-middle font-size-17"></i> Connected Dashboards menu in the
                            top navigation.</p>
                        <p *ngIf="hasConfigs()">The following configurations impact how Troly interacts with {{
									record.nameWithoutCompany() }}. Review carefully and adjust as required.</p>

                        <div class="row" formGroupName="params" *ngIf="hasConfigs()">
                            <ng-container *ngFor="let f of this.record.fieldConfigs()">
                                <!-- {{ f.name }} -->
                                <div class="col {{ f.colclass }}">

                                    <!-- field switch -->
                                    <ng-container [ngSwitch]="f.type">
                                        <!-- field switch -->

													<!-- field switch:select -->
													<ng-container *ngSwitchCase="'select'">
														<mat-form-field>
															<mat-label>{{ f.label }}</mat-label>
															<mat-select [formControlName]="f.name">
																<mat-option *ngFor="let item of f.options" [value]="item.id">
																	{{ item.name }}
																</mat-option>
															</mat-select>
															<mat-hint [innerHTML]="f.hint"></mat-hint>
															<mat-error [innerHTML]="f.error"></mat-error>
														</mat-form-field>
													</ng-container>
													<!-- /field switch:select -->
                                        
													 <!-- field switch:select -->
                                        <ng-container *ngSwitchCase="'radio'">
															<mat-radio-group [formControlName]="f.name">
																<h6>{{ f.label }} <span class="text-danger" *ngIf="form.isFieldRequired('params.' + f.name)">*</span></h6>
																<ng-container *ngFor="let item of f.options">
																	<mat-radio-button *ngIf="item.id.match('true|TRUE')" [value]="true">{{ item.name }}</mat-radio-button>
																	<mat-radio-button *ngIf="item.id.match('false|FALSE')" [value]="false">{{ item.name }}</mat-radio-button>
																	<mat-radio-button *ngIf="!item.id.match('true|TRUE|false|FALSE')" [value]="item.id">{{ item.name }}</mat-radio-button>
																</ng-container>
															</mat-radio-group>
															<mat-hint [innerHTML]="f.hint"></mat-hint>
															<mat-error [innerHTML]="f.error"></mat-error>
														<!-- /field switch:select -->
												  </ng-container>

                                        <!-- field switch:text -->
                                        <ng-container *ngSwitchCase="'label'">
                                            <mat-form-field class="disabled">
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="text" [readonly]="true" />
                                                <i matSuffix
																	(click)="copyToClipboard(form.get('params.'+f.name).value, $event)"
																	class="bx bx-copy cursor-pointer"></i>
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:text -->
                                        </ng-container>

                                        <!-- field switch:text -->
                                        <ng-container *ngSwitchCase="'text'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="text" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:text -->
                                        </ng-container>

                                        <!-- field switch:text -->
                                        <ng-container *ngSwitchCase="'number'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="number" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:text -->
                                        </ng-container>

                                        <!-- field switch:text -->
                                        <ng-container *ngSwitchCase="'date'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="date" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:text -->
                                        </ng-container>
													 
													 <!-- add checkboxes rendeing -->

                                        <!-- field switch:text -->
                                        <ng-container *ngSwitchCase="'currency'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <span matPrefix>$</span>
                                                <input matInput [formControlName]="f.name" type="number" step=".01" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:text -->
                                        </ng-container>

                                        <!-- field switch:password -->
                                        <ng-container *ngSwitchCase="'password'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="password" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:password -->
                                        </ng-container>

													 <!-- field switch:customer -->
                                       <ng-container *ngSwitchCase="'customer'">
														<search-dropdown searchScope="c" [searchLabel]="f.label" (selectCallback)="form.patchValue($event)"></search-dropdown>
														<!-- /field switch:customer -->
												  	</ng-container>
													<!-- field switch:product -->
													<ng-container *ngSwitchCase="'product'">
														<search-dropdown searchScope="p" [searchLabel]="f.label" (selectCallback)="form.patchValue($event)"></search-dropdown>
														<!-- /field switch:customer -->
												  	</ng-container>

                                        <!-- field switch:email -->
                                        <ng-container *ngSwitchCase="'email'">
                                            <mat-form-field>
                                                <mat-label>{{ f.label }}</mat-label>
                                                <input matInput [formControlName]="f.name" type="email"
                                                    [required]="form.isFieldRequired('params.' + f.name)" />
                                                <mat-hint [innerHTML]="f.hint"></mat-hint>
                                                <mat-error [innerHTML]="f.error"></mat-error>
                                            </mat-form-field>
                                            <!-- /field switch:email -->
                                        </ng-container>

                                        <!-- field switch:section -->
                                        <ng-container *ngSwitchCase="'section'">
                                            <h5 *ngIf="f.name != '_extension'">{{ f.label }}:</h5>
                                            <h5 *ngIf="f.name == '_extension'">Platform Extension: <i class="bx bxs-plus-circle text-green" placement="right" ngbTooltip="This application enables additional tools integrated directly within Troly"></i></h5>
                                            <p *ngIf="f.hint" [innerHTML]="f.hint"></p>
                                            <!-- /field switch:section -->
                                        </ng-container>

                                        <!-- /field switch -->
                                    </ng-container><!-- / field switch -->

                                </div> <!-- /col -->

                            </ng-container> <!-- / all fields loop -->

                        </div> <!-- /row -->

								<div class="col-12"><h5>Dashboard Integration:</h5><p>You may tweak the following options to adjust how this application is available to you within your Troly application.</p></div>
								<div class="col-11 mb-2">
									<h6>Show in navigation</h6>
									<mat-radio-group formControlName="show_in_navigation" [ngClass]="{'mdc-radio--disabled':form.disabled}">
										<mat-radio-button value="">When errors are detected</mat-radio-button>
										<mat-radio-button	value="always">Always</mat-radio-button>
										<mat-radio-button value="never">Never</mat-radio-button>
									</mat-radio-group>
								</div>
								<div class="col-11 mb-2">
									<h6>In case of problems with {{ record.nameWithoutCompany() }}</h6>
									<mat-radio-group formControlName="send_notifications_to" [ngClass]="{'mdc-radio--disabled':form.disabled}">
										<mat-radio-button value="all">Notify all team members</mat-radio-button>
										<mat-radio-button	value="">Only platform administrators</mat-radio-button>
									</mat-radio-group>
								</div>
                    </div>

                    <div *ngIf="focusOn == 'track'">

                        <p>Here is a list of interactions between Troly and <strong>{{ record.name }}</strong>. Each apps
                            records different information, but this helps understanding what is working well, and what
                            is not.</p>

                        <list-apps-events-card cardTitle="" containerCss="" toolbar="status" pagination="pageChange"
									 [applicationCol]="false" [advancedCol]="false" 
									 [provider]="record.provider"></list-apps-events-card>

                    </div>

                    <div *ngIf="focusOn == 'help'">

                        <p>Problems are never fun. While we all appreciate being self-sufficient, sometimes seeking
                            help is the best way to address unexpected problems.</p>
                        
                        <ng-container *ngIf="record.processor != 'Base'">
                            <h5>Support from {{ record.nameWithoutCompany() }}</h5>
                            <p>The {{ record.nameWithoutCompany() }} team is best positioned to help with questions regarding how to use
                                platform.</p>
                                <p *ngIf="record.support" [innerHTML]="record.support"></p>
                                <p>For more information, visit <a href="{{record.support_url || record.website}}">{{
                                    record.support_url || record.website }}</a>.</p>
                        </ng-container>

                        <h5>Community support for Troly</h5>
                        <p *ngIf="record.processor != 'Base'">For any issues with connecting to or information exchange
                            with {{ record.nameWithoutCompany() }}, please review the error details under <a href
                                (click)="!!setFocus('track');">Track</a>. This helps understand the source of
                            the problem experienced. You can also find more information about {{ record.nameWithoutCompany() }} in the
                            <a href>help section</a> available from the topbar.</p>
                        <p *ngIf="record.processor == 'Base'">For information about how to use {{ record.nameWithoutCompany() }}, visit
                            the <a href>help section</a> available from the topbar.</p>
                        <p>You can contact our support team via <a
                                href="mailto:help@troly.co&subject=test&body=tttt">help&#64;troly.co</a> or via chat.
                            Remember that you can also get help from your own developers who are able to contribute to
                            Troly, thanks to our not-for-profit, open source approach to providing this software as a
                            service.</p>

                    </div>

                </div> <!-- /col-9 rhs -->

                <div class="col-12 ps-3 mt-3" *ngIf="record.isInstalled() && !record.provider.startsWith('Troly')" style="margin-bottom:-8px;">
                    <a class="text-danger uninstall font-size-12" href
                        (click)="!!uninstallIntegration('Uninstall ' + record.name + '?', 'Disconnecting all data communication with the application has unrecoverable impacts. Do you wish to continue?')">
                        Uninstall
                    </a>
                </div>
            </div> <!-- /row -->
			<debug [form]="form"></debug>
		</div> <!-- /card-body -->

      <div class="{{ bsCssClassPrefix }}-footer">

			<div>
				
				<ng-container *ngIf="!record.isInstalled()">
					&nbsp;<!-- if nothing shows, holds the footer height; -->
				</ng-container>

				<ng-container *ngIf="focusOn == 'info'">
                <button type="button" (click)="onInstall()"
                    class="btn btn-primary {{ form.loading }}"
                    [disabled]="record.isInstalled() || record.isRequestOnly() || form.loading != 'loaded' || form.invalid">
                    Install
                </button>
            </ng-container>

            <ng-container *ngIf="focusOn == 'request'">
                <button *ngIf="record.isRequestOnly()" type="button" (click)="onRequest()"
                    class="btn btn-primary {{ form.loading }}"
                    [disabled]="form.cannotSubmit">
                    Request
                </button>
            </ng-container>

            <ng-container *ngIf="focusOn == 'config'">
                <button type="button" (click)="onSave()"
                    class="btn btn-primary {{ form.loading }}"
                    [disabled]="form.cannotSubmit">
                    Save
                </button>
            </ng-container>

				<ng-container *ngIf="['track','help'].includes(focusOn)">
					&nbsp;<!-- if nothing shows, holds the footer height; -->
				</ng-container>
				
			</div>

			<div class="d-block font-size-11" *ngIf="record.isInstalled()">
				<p class="m-0" *ngIf="record.last_30days_usage">
					<strong>This month usage: </strong>{{ record.last_30days_usage || 'no' }} actions recorded
				</p>
				<p class="m-0" *ngIf="record.last_event_time">
					<strong>Last action: </strong>{{ moment(record.last_event_time).format(datetimeFull) }}
				</p>
			</div>
			
		</div>
	</form>
</div>












<ng-template #operationsTpl>

    <ng-container *ngIf="record.ondemand">

		  <p *ngFor="let details of record.ondemand.split('\n')" [innerHTML]="details"></p>
        <p class="mb-4">You can also request the following one-off operations from {{ record.name }}:</p>

        <div *ngFor="let op of onDemandOperations">
            <button class="btn btn-light mb-3" [disabled]="!op.id.endsWith('s')" (click)="onDemand(op.id)" [innerHTML]="op.name"></button>
        </div>

        <ng-container *ngIf="this.record.fieldConfig('_extension').hint">
            <h5>Platform Extension: <i class="bx bxs-plus-circle text-green" placement="right" ngbTooltip="This application enables additional tools integrated directly within Troly"></i></h5>
            <p [innerHTML]="this.record.fieldConfig('_extension').hint"></p>
        </ng-container>

		  <p class="mt-4 font-size-11"><strong>Note:</strong> Operations on a single record runs almost immediately, unlike operations impacting multiple
			records which could take a few hours to complete.</p>

			<debug [form]="form"></debug>

    </ng-container>

</ng-template>













<ng-template #infoTpl>

	<ng-container *ngIf="record.description">
		<p *ngFor="let details of record.description.split('\n')" [innerHTML]="details"></p>
	</ng-container>
	<ng-container *ngIf="!record.description">
		<p [innerHTML]="record.info"></p>
	</ng-container>
    
    <ng-container *ngIf="split(record.benefits,'\n').length > 0">
        <h5>Benefits:<sup *ngIf="record.benefits.indexOf('<sup>') > 0">*</sup></h5>
        <ul>
            <li *ngFor="let str of split(record.benefits,'\n')" [innerHTML]="str"></li>
        </ul>
    </ng-container>
    <ng-container *ngIf="record.instructions">
        <h5>Additional Instructions:</h5>
		  <p *ngFor="let str of split(record.instructions,'\n')" [innerHTML]="str"></p>
    </ng-container>
    <ng-container *ngIf="record.premium != null">
        <h5>Premium Application <i class="bx bxs-badge-check text-primary font-size-18"></i></h5>
        <p>{{ record.nameWithoutCompany() }} comes with more comprehensive technical capability and support. Often, our team can liaise
            directly with {{ record.nameWithoutCompany() }} on your behalf when issues are experienced.</p>
        <p *ngIf="record.premium" [innerHTML]="record.premium"></p>
    </ng-container>
    <ng-container *ngIf="record.price">
        <h5>Pricing Details</h5>
        
		  <p *ngIf="record.pricing" [innerHTML]="record.pricing"></p>

		  <p *ngIf="record.setup">{{ record.setup | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }} charged once, for initial setup.</p>

        <ng-container [ngSwitch]="record.pricedAs()">
            <p *ngSwitchCase="'range'">
                <strong>Starts at</strong> {{ record.price_min | currency:
                selectedCompany.currency:'symbol-narrow':'1.0-0' }} and up to {{ record.price | currency:
                selectedCompany.currency:'symbol-narrow':'1.0-0' }} per month, automatically added to your invoice.
            </p>

            <p *ngSwitchCase="'fixed'">
                {{ record.price | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }} per month,
                automatically added to your invoice.</p>

            <p *ngSwitchCase="'free'">
					<ng-container *ngIf="record.provider.startsWith('Troly')"><strong>Free</strong> as in "free beer"! In fact, this is included in your chosen plan, and as such you'll incurr no additional cost to install and use.</ng-container>
					<ng-container *ngIf="!record.provider.startsWith('Troly')">This is free to install and use. However {{ record.nameWithoutCompany() }} may charge a fee, seperate to Troly.</ng-container>
				</p>
				
            <p *ngSwitchCase="'percent'">
                This is free to install. All new sales tracked from {{ record.nameWithoutCompany() }} will incur a {{ record.price }}
                fee, added to your invoice.</p>
        </ng-container>
		  <p *ngIf="record.pricing">Any additional costs incurred will be detailed and may be configurable via your <a
				href (click)="!!resolveRedirect(['/settings/billing'], { fragment:record.provider.toLowerCase() })">Subscriptions Dashboard</a>, in your business portal.</p>
        <p *ngIf="record.pricing_url">Integrated billing may be available through Troly. See <a
                href="{{ record.pricing_url }}" target="blank">pricing page</a> for more details.</p>
    </ng-container>
    <ng-container *ngIf="record.website">
        <h5>More information</h5>
        <p>You can find more information on the <a [href]="record.website" target="_blank">{{ record.nameWithoutCompany() }} website</a>, including
            features and pricing.</p>
    </ng-container>


</ng-template>