import { TrolyObject } from '../troly_object';

export class Address extends TrolyObject {

	constructor(values?) {
		super('address', values)
	}

	/** A human-readable label to identify this address. eg. Home, Work, etc. */
	declare label: string

	/** Internal notes on using this address */
	declare notes: string

	/** Any access instructions or note printed on shipping label */
	declare delivery_instructions: string

	/** Whether or not the user has requested for this addresses to be captured in parts or using lookup+validation */
	declare is_manual_override: boolean;

	/** Once an address has been validated, this is the 1-liner address format */
	declare formatted_address: string;

	/** Whether this address is for a residence or a commerce */
	declare is_residential:boolean

	/** Street number and name, and in some country appartment number, level, etc. */
	declare line1: string

	/** Human-entered additional address information such as building name, or addressee - not validated. */
	declare line2: string

	/** Normally the suburb, post code and state name */
	declare line3: string

	/** Normally the country */
	declare line4: string

	/** Street number for this address */
	declare number: string

	/** Suite,unit or appartent number for this address */
	declare unit: string

	/** Full street name for this address (line 1 is sometimes abbreviated eg. Road -> Rd.) */
	declare street: string

	/** Name of the suburb, neighbourhood or local area for this address*/
	declare suburb: string

	/** Region at large, not the state, but the regional area, such as metropolitan area or geographic area */
	declare region: string

	/** Postcode for this address */
	declare postcode: string

	/** Full-length name of the state for this address */
	declare state: string

	/** Abbreviated state name for for this address */
	declare state_abbr: string

	/** Full-length country name */
	declare country: string

	/** Country abbreviation as alpah3 country code */
	declare country_abbr: string

	/** Date at which this address was last used to generate a shipping label */
	declare last_used_at: Date

	/** Total order value for the last order sent to the address */
	declare last_amount: number

	/** The original GoogleAPI response either from the frontend or backend */
	declare google_response: {}

	/** The what3words identifier for this address */
	declare what3words: string

	/** Latitude GPS coordinate */
	declare lat: number

	/** Loongitude GPS coordinate */
	declare lng: number


	/**
	 * 
	 */
	declare sync_warehouse: boolean

	declare precision: string

	/**
	 * Whether or not this address seems like a PO Box address -- treated differently given some carriers to not ship to PO Boxes, and addres validation is not as comprehensive
	 * @param value address to check, defaults to line1 of the current object
	 * @returns 
	 */
	public isPoBox(value?): boolean {
		value = value || this.line1 || ''
		const poBoxRegex = RegExp(/(CASIER POSTAL|BOITE POSTALE|PO BOX|GPO BOX|POST OFFICE BOX|P\.O\. BOX|LOCKED BAG|PRIVATE BAG)/, 'i');
		return (value && value.match(poBoxRegex) != null);
	}

	public toString() : string {
		// used to sort addresses in the UI
		return `${this.label}, ${this.line1} (${this.suburb})`
	}

	/**
	 * Sort Function. Determines whether an address should be sorted before another based on country name, and suburb
	 * @param other 
	 * @param asc 
	 * @returns 
	 */
	public before(other: Address, asc = true): number {
		return this.country.toLowerCase < other.country.toLowerCase || (this.country.toLowerCase == other.country.toLowerCase && this.suburb.toLowerCase < other.suburb.toLowerCase) ? (asc ? -1 : 1) : (asc ? 1 : -1)
	}

	/**
	 * Sets the Google Response attribute for this addresses and extracts the various address parts (when available) to the current object
	 */
	public set setGoogleResponse(value: any) {

		this.lat = value.geometry.location.lat
		this.lng = value.geometry.location.lng

		this.precision = value.geometry.location_type;

		let premise = value.address_components.find(_ => _['types'].includes('premise'))?.long_name
		this.unit = value.address_components.find(_ => _['types'].includes('subpremise'))?.long_name
		this.number = value.address_components.find(_ => _['types'].includes('street_number'))?.long_name
		this.street = value.address_components.find(_ => _['types'].includes('route'))?.short_name
		this.suburb = value.address_components.find(_ => _['types'].includes('locality'))?.long_name
		this.state = value.address_components.find(_ => _['types'].includes('administrative_area_level_1'))?.long_name
		this.state_abbr = value.address_components.find(_ => _['types'].includes('administrative_area_level_1'))?.short_name
		this.region = value.address_components.find(_ => _['types'].includes('administrative_area_level_2'))?.long_name
		this.country = value.address_components.find(_ => _['types'].includes('country'))?.long_name
		this.country_abbr = value.address_components.find(_ => _['types'].includes('country'))?.short_name
		this.postcode = value.address_components.find(_ => _['types'].includes('postal_code'))?.long_name

		if (value.formatted_address) {

			if (premise) {
				// in some case the building name is the same as the address and this is just annoying, just 
				// remove the building name all together, eg. 44 Montgomery Street San Francisco
				value.formatted_address = value.formatted_address.replace(`${premise}, `, '')
			}
			this.formatted_address = value.formatted_address;
			const parts = value.formatted_address.split(', ')
			if (parts.length == 2) {
				this.line3 = parts[0]
				this.line4 = parts[1]
			} else if (parts.length == 3) {
				this.line1 = parts[0]
				this.line3 = parts[1]
				this.line4 = parts[2]
			} else {
				// with google, when there are more than 3 parts formatted, it "seems" that the last 3 are akways state, 
				// postcode and country info, and everything else, is pertaining to the street address
				this.line4 = parts.pop();
				this.line3 = parts.pop();
				this.line3 = parts.pop() + ', ' + this.line3;
				this.line1 = parts.join(', ')
			}
		} else {
			this.line1 = (this.unit ? `${this.number} ${this.street}, #${this.unit}` : `${this.number} ${this.street}`)
			this.line3 = `${this.suburb}, ${this.state_abbr} ${this.postcode}`
			this.line4 = this.country
		}

		this.google_response = value;
	}


	/**
	 * Returns a string used to search/auto-complete this address using the geo-search.
	 * @see GeoSearchComponent
	 */
	public get searchArea() {
		return this.line3 && this.line4 ? `${this.line3}, ${this.line4}` : (this.line3 || this.line4 || '')
	}

}