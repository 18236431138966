<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

        <div class="{{ bsCssClassPrefix }}-header">
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!record?.id">
					<span *ngIf="!form.get('operation').value">Record a new Note</span>
					<span *ngIf="form.get('operation').value == 'call'">Record a new Call</span>
					<span *ngIf="form.get('operation').value == 'email'">Record a new Email</span>
					<span *ngIf="form.get('operation').value == 'visit'">Record a new Visit</span>
				</h4>
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="record?.id">Edit {{ form.get('operation')?.value }} Note</h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>
        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

            <form-status [formGroup]="form" (dismissCallback)="resolveModal($event)" successAction="close" [record_label]="form.get('name')?.value">

            </form-status>

            <div class="row">
                <div class="col-6" *ngIf="!form.get('operation')">
                    <mat-form-field>
                        <mat-label>{{ 'Models.Interaction.Attributes.operation' | translate }}</mat-label>
                        <mat-select formControlName="operation">
                            <mat-option *ngFor="let t of ['email','call','visit']" value="{{ t }}">{{
                                'Models.Interaction.Values.operation.' + t | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="textfield-full-width">
                        <mat-label *ngIf="!form.get('operation').value">Your notes</mat-label>
                        <mat-label *ngIf="form.get('operation').value == 'call'">Your notes about this call</mat-label>
                        <mat-label *ngIf="form.get('operation').value == 'email'">Your notes about this email</mat-label>
                        <mat-label *ngIf="form.get('operation').value == 'visit'">Your notes about this visit</mat-label>
                        <textarea matInput type="text" formControlName="body"
                            placeholder="eg. Mentioned they would visit next April."></textarea>
                    </mat-form-field>
                </div>
					 <div class="col-4">
						<mat-form-field>
							<mat-label>Follow up reminder</mat-label>
							<mat-select formControlName="follow_up_in">
								<mat-option value="no">Not required</mat-option> 
								<mat-option value="4.weeks">in 4 weeks</mat-option> 
								<mat-option value="3.months">in 3 months</mat-option> 
								<mat-option value="6.months">in 6 months</mat-option> 
								<mat-option value="1.year">next year</mat-option> 
							</mat-select>
							<mat-error></mat-error>
					  </mat-form-field>
					 </div>
					 <ng-container *ngIf="['call', 'dm', 'sms','email'].includes(form.get('operation').value)">
						<div class="offset-4 col-4" *ngIf="['call', 'dm', 'sms','email'].includes(form.get('operation').value)">
							<mat-form-field>
								<mat-label>This {{ form.get('operation').value }} was</mat-label>
								<mat-select formControlName="direction">
									<mat-option value="received">received</mat-option> 
									<mat-option value="sent" *ngIf="form.get('operation').value != 'call'">sent</mat-option> 
									<mat-option value="sent" *ngIf="form.get('operation').value == 'call'">made</mat-option> 
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-12" *ngIf="form.get('direction').value == 'sent' && !service.cachedUiKey('Alert.uK5lO3')">
							<ngb-alert type="info" class="ms-2 me-2 mb-1 mt-1"
								(closed)="service.storeUiKey('Alert.uK5lO3', 'Alert.uK5lO3', 90 * 24)">
								<h6>Capture messages sent automatically</h6>
								<p>Review <a [routerLink]="['/brand/settings']" fragment="notifications">Smart Notifications</a> templates to automatically keep customers in the loop and reduce messages sent manually.</p>
							</ngb-alert>
						</div>
						<div class="col-12" *ngIf="form.get('direction').value == 'received' && !service.cachedUiKey('Alert.uK5zQ3')">
							<ngb-alert type="info" class="ms-2 me-2 mb-1 mt-1"
								(closed)="service.storeUiKey('Alert.uK5zQ3', 'Alert.uK5zQ3', 90 * 24)">
								<h6>Record messages automatically</h6>
								<p>To better understand the quality of your communications with every contact, visit <a [routerLink]="['/apps/dashboard']">Apps Marketplace</a> to install additional sources of 1-on-1 and marketing communications.</p>
							</ngb-alert>
						</div>
					</ng-container>
					<ng-container *ngIf="['visit'].includes(form.get('operation').value)">
						<div class="col-12" *ngIf="form.get('direction').value == 'received' && !service.cachedUiKey('Alert.Wfe72N')">
							<ngb-alert type="success" class="ms-2 me-2 mb-1 mt-1"
								(closed)="service.storeUiKey('Alert.Wfe72N', 'Alert.Wfe72N', 90 * 24)">
								<h6>Record visits automatically</h6>
								<p>Using the built-in or (some) connected Point-of-Sale (POS) automatically record visits for customers. Enable your GPS sharing within the <a [routerLink]="['/orders/new']">built-in POS</a>, or visit <a [routerLink]="['/apps/dashboard']">Apps Marketplace</a> to connect your external POS.</p>
							</ngb-alert>
						</div>
					</ng-container>
            </div>
				<debug [form]="form"></debug>
        </div>

        <div class="{{ bsCssClassPrefix }}-footer">
            <button type="submit" class="btn btn-primary {{ form.loading }}"
                [disabled]="form.cannotSubmit">
                Save
            </button>
        </div>
    </form>
</div>