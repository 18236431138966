import { HttpParams } from "@angular/common/http";
import { AbstractControl, FormArray, FormControl, ValidatorFn, Validators } from "@angular/forms";

import { Color } from '@vipstorage/material-color-picker';
import * as moment from 'moment';
import { TopbarNotification } from "./topbar.models";
import { TrolyFormGroup } from "./troly_form";
import { TrolyObject } from "./troly_object";

/**
 * Encapsulates the tools required in order to take a TrolyObject and convert 
 * to a form, retrieve changes, save, and maintain the form data in sycn;
 */
export class FormObject extends Object {

	private _debug: boolean = false; // common debugging flag

	/**
	 * Model attributes that should NEVER be included in a form (why is this needed, usage?)
	 */
	public _excludeFormAttributes: string[];

	/**
	 * Internal Model name as used/sent to the API
	 */
	public _trolyModelName: string;

	/**
	 * 
	 */
	public _formFields: ITrolyFormField[] = [];
	
	/**
	 * List of model properties with their associated datatype. This is used for building forms 
	 * and especially with attributes which are Arrays or Objects (numbers string and dates do 
	 * not need declaration here). This is required given typescript doesn't have introspection 
	 * abilities (for a class to look at it's own properties and data types)
	 */
	public _trolyPropertyArray: {} = {};

	/**
	 * Assign the current debug level to this object and copies any attributes as needed
	 * @param values attributes to be copied to the newly created object
	 */
	constructor() {
		super()

		//this._trolyModelName = '';
		this._debug = window.localStorage.getItem('de') == 'true' || false;

		this._excludeFormAttributes = [];
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return Object.assign({
			type: this._trolyModelName, // used for rendering a translated name for this notification -- defaults to model name
			status: 'success',			 // used as part of the translation, defaults to success --- see en-AU.json
			name: this.toString(),
		}, notification) as TopbarNotification
	}

	/**
	 * Allows to apply the correct timezone offset and when irrelevant (eg Date and not Datetime) then not apply one and show the correct date.
	 * Because the API doesn't return "raw dates", everything is a datetime
	 * @param date 
	 * @param attribute 
	 * @returns 
	 */
	public toDateWithOffset(date:string, attribute?:string): Date {
		if (date && date.toString().endsWith("T00:00:00.000Z")) {
			let new_date = new Date(date);
			// api always returns utc dates with timezone offsets -- if there's no time set, we assume 
			// only the date component is of value, and we apply the offset accordingly)
			new_date.setTime(new_date.getTime() + new_date.getTimezoneOffset()*60*1000)
			return new_date
		} else if (date) {
			return new Date(date);
		}
		return null;
	}

	/**
	 * 
	 */
	public isStale(attr:string, threashold:number=5): boolean {
		const THRESHOLD_IN_MINUTES = threashold * 60 * 1000
		if (this.isDemoData(attr)) { threashold *= 2 } // once we have loaded demo data -- we know there's no point in checking too regularly
		return this.staleTimer(attr) > THRESHOLD_IN_MINUTES
	}

	public setStale(attr:string) {
		const DAYS_IN_MS = 24 * 60 * 60 * 1000
		this[`__${attr}`] = (new Date()).getTime() - (90 * DAYS_IN_MS)
	}
		

	/** Returns the number of seconds since the data was loaded, or 999999 if it was never loaded  */
	public staleTimer(attr:string): number {
		// it's important that the __attr is defined for us to check it -- if it's not defined, it indicates that it was NOT LOADED, 
		// not that it's staled. -- this is used to default different force flags, so it should be this way,
		return this[`__${attr}`] == undefined ? 0 : (new Date()).getTime() - (new Date(this[`__${attr}`]).getTime())
	}
	public notStale(attr:string) {
		this[`__${attr}`] = new Date();
	}

	/**
	 * Used to compare objects betwen each other like obj1 = obj2 without bothering on all the properties being equal
	 * @returns a unique string representing this object.
	 */
	valueOf(): string {
		return `<${this._trolyModelName ? this._trolyModelName + '=' : ''} unknown at ${new Date().toDateString()}/>`;
	}

	public localeCompare(b:FormObject, asc:boolean=true): number { 
		console.error(`localCompare is useless if not correctly overriden by object`)
		return 0 
	}

	/**
	 * 
	 * @param obj 
	 * @returns 
	 */
	public sameAs(obj: FormObject): boolean {
		return this.valueOf() == obj?.valueOf();
	}

	/**
	 * Creates a FormGroup for the current object based on attributes definition or validation defined.
	 * @param formFields string[] list of object attributes to add to the form
	 * @param baseObj defines the object to observe for attributes. Used for recursion and 'sub objects'.
	 * @param updateOn manual override to how this for detection is monitored -- blur or change
	 * @returns a [TrolyFormGroup] with validation and default values.
	 */
	public toFormGroup(formFields?: ITrolyFormField[], baseObj?: FormObject, updateOn?: 'blur'|'change'): TrolyFormGroup {

		baseObj ||= this;
		formFields = formFields && formFields.length > 0 ? formFields : Object.keys(this.cleanObjectProperties(baseObj)|| []);
		updateOn ||= 'blur';

		let fg = new TrolyFormGroup({}, { updateOn: (updateOn == 'blur' ? 'blur' : 'change') });
		fg.formFields = formFields
		fg.formFields.forEach(function (key) {

			if (typeof key == 'object') {

				// first we're handling the case where parameters are an object with subattributes to callback recursively
				// eg. { company_customer: ['notes','since_date'] }
				Object.keys(key).forEach((sKey) => {
					if (baseObj._trolyPropertyArray[sKey] === Array) { // needs to be === to compare with Array class
						let controls = [];
						// in the case where we have an array of objects, we only want to add a record 
						// when we have actual data, making sure that forms will not loop through empty "nested records"
						if (baseObj[sKey]) {
							baseObj[sKey].forEach((element) => {
								if (!(element instanceof FormObject)) {
									element = new TrolyObject(key.toString(), element);
								}
								controls.push(element.toFormGroup(key[sKey], element, updateOn))
							});
						}
						fg.addControl(sKey, new FormArray(controls));

					} else if (baseObj._trolyPropertyArray[sKey] instanceof Object) { // needs to be instance of to compare with {}
						let subForm = this.toFormGroup(key[sKey], baseObj[sKey], updateOn)
						//subForm.removeControl('id')
						fg.addControl(sKey, subForm);
					}
				})

			} else if (typeof key == 'string' && key[0] != '_') { // && !baseObj._excludeFormAttributes.includes(key)) {

				let fc = this.formControl(key, baseObj[key], baseObj);
				if (typeof baseObj[key] == 'number') {
					fc.setValue(baseObj[key] + 0)
				}

				fg.addControl(key, fc);

			}
		}, this);

		// this ensure we don't always have to specify ID in the default attributes. if there is one, it should always be in the form, so that we can save a change
		fg.addControl('id', new FormControl(baseObj['id']));

		fg.liveLoading = (updateOn == 'blur');

		return fg;
	}

	/**
	 * This is an extension of `Object.merge` and allows merging object attributes **only** if they are undefined. 
	 * This is used mostly to define default values onto an object, yet, not override the object attributes when 
	 * values are already present.
	 * @param values 
	 * @returns 
	 */
	public mergeIfUndefined(values: Object): FormObject {
		for (const [key, value] of Object.entries(values)) {
		//Object.keys({...values}).forEach((key) => {
			if (this[key] === undefined || this[key] === null) {
				this[key] = values[key];
			}
		//}, this);
		}
		return this
	}

	/** 
	 * Review and or filter the changes to be saved, before they are saved. 
	 * ! note: retugning false will halt the save/create process
	 * ! note 2: this is also present on the component, so that we can override at either level, depending on which is most appropriate
	 */
	public beforeSave(originalObject:FormObject): boolean {
		return true;
	}
	public postChangesSaved(updatedObject: FormObject): FormObject {
		return updatedObject;
	}

	public patchableValues(baseObj?) {

		// Here we clone the base values because we are actually modiying some attributes
		// and this is ok for the return value, but we don't want that to be persistent on
		// the original object.
		// eg. attribute created_at has a time value that we may want to display after patching a form to edit the date
		let result = Object.assign({}, baseObj || this); 

		//if (Array.isArray(this))
		Object.keys(result).forEach((key) => {
			if (key[0] != '_' && result[key]) {
				if (result[key] instanceof Date) {
					if (result[key].toString().endsWith("T00:00:00.000Z")) {
						result[key] = moment.utc(result[key]).format('YYYY-MM-DD')
					} else {
						// timezone matters.
						result[key] = moment(result[key]).format('YYYY-MM-DD')
					}
				} else if (result._trolyPropertyArray && result._trolyPropertyArray[key] == Array && result[key].length > 0) {
					result[key] = result[key].map((element) => {
						if (element instanceof TrolyObject) {
							return element.patchableValues(element)
						} else {
							console.warn(`cannot patch ${key}: element is not a TrolyObject#patchableValues`, element)
						}
					});
				}
			}
		});

		return result;
	}

	/**
	 * Recursive function to clean up any object and remove the angular-only attributes, such as all properties starting with `_`;
	 * @param collection 
	 * @param onlyAttr 
	 * @returns 
	 */
	public cleanObjectProperties(obj:FormObject) : FormObject {

		if (obj) {
			//obj = structuredClone(obj)
			if (Array.isArray(obj)) {
				obj.forEach((item, key) => {
					obj[key] = this.cleanObjectProperties(item)
					if (obj[key] == null) { delete obj[key]; }
				});

			} else if (obj instanceof Date) {
				// NOT doing this causes problems below, becase a date is an object, with .keys == 0
			} else if (typeof obj === 'object') {

				Object.keys(obj).forEach((key) => {
					if (	key[0] == '_' && // anything starting with _ is an internal model information to be discarded
							key[1] != "_" && // except for __ which is used to a timestamp of when the attribute was loaded
							(key != '_destroy' || this[key] === false)	// except for _destroy which (when set to true) is used to delete a record
						) {
						// Anything starting with _ is an internal model information to be discarded
						// however, anything with double_is a stale timestamp to be kept
						delete obj[key];
					}
					else {
						obj[key] = this.cleanObjectProperties(obj[key]);
					}
				});

				if (Object.keys(obj).length == 0) {
					return null;
				}
			}
		}

		return obj;
	}

	/**
	 * Checks whether a certain attribute currently contains demo data.
	 * @param attribute_name 
	 * @returns 
	 */
	public isDemoData(attribute_name: string): boolean {
		// when there's no data loaded, we assume (true) -- demo data is coming until proven wrong
		return !this[attribute_name] || this[attribute_name] == this[`_${attribute_name}Default`]
	}

	/**
	 * 
	 * @param attribute_name 
	 * @returns boolean indicating whether or not the call needs to be made again
	 */
	public shouldRefreshData(attribute_name: string, expiry_in_hours?: number): boolean {
		let now = new Date()

		if (!expiry_in_hours) {
			return !this._attributes_write_time[attribute_name] || this._attributes_write_time[attribute_name] < now
		}

		now = new Date(now.getTime() + (expiry_in_hours * 60 * 60 * 1000));
		this._attributes_write_time[attribute_name] = now

		return true;
	}
	/**
	 * Stores the name of attributes which we want to cache "but only for a certain time"
	 */
	private _attributes_write_time: { [key: string]: Date } = {};

	/**
	 * 
	 * @param verb 
	 * @param onlyAttr 
	 * @returns 
	 */
	public toHttpBody(): {} {

		let payload = {};

		// The Troly API requires for objects to be identified in the body (eg PUT or POST {"customer": {name: "Mitchel"}})
		// if this was not done upstream, let's enforce it here.
		if (this._trolyModelName != '' && !this[this._trolyModelName]) {
			payload[this._trolyModelName] = Object.assign({}, this);
		} else {
			payload = Object.assign({}, this);
		}

		return this.cleanObjectProperties(payload as FormObject) || {};
	}



	/**
	 * Converts the form object to HttpParams for a specific HTTP verb.
	 * 
	 * @param verb - The HTTP verb (e.g., 'get', 'delete').
	 * @param onlyAttr - Optional. An array of attribute names to include in the HttpParams.
	 * @returns The HttpParams object.
	 */
	public toHttpParams(verb: string, onlyAttr?: string[]): HttpParams {

		let httpParams = new HttpParams()

		if (verb == 'get' || verb == 'delete') {

			Object.keys(this).forEach((key) => { // note the `=>` is important an allows this to be references within the loop - see https://stackoverflow.com/questions/55209965/access-this-inside-object-keys-in-es6
				if (key[0] != '_') { // all 'underscore' attributes are always excluded
					if (onlyAttr === undefined || onlyAttr.includes(key)) {
						httpParams = httpParams.append(key, this[key]);
					}
				}
			}, this); // using the thisArg? parameter to forEach, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach and https://stackoverflow.com/questions/29626729/how-to-function-call-using-this-inside-foreach-loop/29626762
		}
		
		return httpParams;
	}

	public confirmChangesSaved(form:TrolyFormGroup, payload:FormObject, result:FormObject): FormObject {
		form.doneSaving(payload);
		return this
	}

	protected toColor(hex:string):Color {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? new Color(
			parseInt(result[1], 16),
		  	parseInt(result[2], 16),
		  	parseInt(result[3], 16),
			1) : null;
	 }


	/**
	 * 
	 * @param key 
	 * @param value 
	 * @param baseObj
	 * @returns 
	 */
	protected formControl(key: string, value: any, baseObj: {}): AbstractControl {

		baseObj = baseObj || this;
		value = value || baseObj[key] || '';

		let validators: ValidatorFn[] = [];

		if (baseObj[`_${key}`] !== undefined) {
			validators = baseObj[`_${key}`];
		} else {

			// if we do not have any defined validation on the model, create a few very generic validation based on data type
			switch (typeof baseObj[key]) {
				case 'number':
					validators.push(Validators.pattern("^\-?[0-9]*(\.[0-9]*)?$")) // includes negative numbers and decimals
					break;
				case 'boolean':
					validators.push(Validators.pattern("^(yes|no|true|false|1|0)$"))
					break;
			}
		}

		if (value instanceof Date) { // this ensures that all dates can be understood and formated for the date picker. @see also TrolyComponent.onFieldUpdate
			value = moment(value).format('YYYY-MM-DD')
		}

		return new FormControl(value, validators);
	}
}

export interface ISortable {

}

export class TrolySearch<T extends TrolyObject> {

	declare meta: { 
		count: number,
		page: number,
		limit: number
	}

	declare results: T[];

	constructor(values?: Object, node_name?: string) {
		Object.assign(this, values)
		this.results = values[node_name]
	}

	public totalPages(size: number): number {
		return Math.ceil(this.meta.count / size);
	}
}

export class TrolyJob {

	channel: string
}

export type ITrolyFormField = string | {[key:string]:ITrolyFormField[]};
export type ITrolyLoadingStatus = undefined|'loading'|'loaded'|'loading-slow'|'loaded-demo'|'loading-error'|'refresh';

/**
 * 
 */
export interface ITrolySocketChannelJob {
	channel: string
}

/**
 * 
 */
export interface ITrolySocketJobUpdate {

	message: string // "normally an internationalised identifier indicating the type of message -- such as "sockets.transactions-processing"
	type: 'queued' | 'start' | 'update' | 'progress' | 'end' | 'error' | 'result';

	customised_message: string; // contains a custom english string to clarify any error encounteered

	completion: number; // value to reach overall completion of progress
	progress: number; // current progress

	step: number;
	fail: number; // number of records that were not processed.
	ok: number; // total number of successfully processed records
	total: number;

	result: any // contains an important result (eg document generated)
}

/**
 * 
 */
export interface ITrolySocketMessage {
	model: string,
	operation: 'new' | 'update' | 'deleted' | 'start' | 'progress' | 'end' | 'error' | 'result'
	data: ITrolySocketJobUpdate | TrolyObject
}
