import { Component, Input, inject } from '@angular/core';
import { BulkModal } from 'src/app/core/components/bulk.modal';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';
import { TrolyWidgetsModule } from "../../../widgets/widgets.module";
@Component({
    selector: 'create-email-modal',
    templateUrl: './create-email.modal.html',
    styleUrls: ['./create-email.modal.scss'],
    standalone: true,
    imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule, TrolyWidgetsModule]
})
export class CreateEmailModal extends BulkModal {
	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name: string = 'CreateEmailModal';

	@Input('record') record: Customer = new Customer();

	_formFields = ['body', 'subject', 'template'];

	/**
	 * Additional services used this this component.
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent.
	 */
	protected customerService: CustomerService = inject(CustomerService);

	constructor() {
		super();

		this.service = this.customerService;
		
		this.operation = 'message';

		this.log(`${this.__name}.constructor`, 'STACK');

		
		this.initForm() // this grabs the updated _formFields and recreate the form
	}


/**
	 * Submits the email form data to the backend.
	 * This method gathers the input from the form fields, constructs a payload with the message details,
	 * and invokes the parent class's onSubmit method to handle the actual submission process.
	 * The payload includes the email's subject, body, and template information. If the 'body' field is filled,
	 * 'custom' is used as the template; otherwise, it falls back to the value selected in the 'template' field.
	 * The method relies on the parent's implementation to manage response handling, form state updates, and user feedback.
	 */

	onSubmit() {
		
		let payload ={ subject: this.form.get('subject').value, body: this.form.get('body').value, channel: 'email', template: 'custom', };

		let obj = new TrolyObject('', payload)

		super.onSubmit(obj);
	
	}

}
