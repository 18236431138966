import { Membership } from 'src/app/core/models/troly/membership.model';

import { ValidatorFn, Validators } from '@angular/forms';
import { Order } from "src/app/core/models/troly/order.model";
import { TopbarNotification } from "../topbar.models";
import { TrolyObject } from '../troly_object';
import { uuid } from "../utils.models";
import { Address } from "./address.model";
import { CompanyCustomer } from './company_customer.model';
import { Interaction } from './interaction.model';
import { PaymentCard } from './payment_card.model';
import { DemographicStat } from './stats.model';
import { CustomerTag, Tag } from './tag.model';
import { Task } from './task.model';

export class Customer extends TrolyObject {

	override _trolyPropertyArray = { company_customers: Array, referrals: Array, tags: Array, orders: Array }

	declare salutation: string;
	declare fname: string;
	_fname: ValidatorFn[] = [Validators.required];

	declare lname: string;
	
	declare email: string;
	_email: ValidatorFn[] = [Validators.email, Validators.maxLength(50)]
	declare email_status: string;

	declare anonimo:string

	declare mobile: string;
	_mobile: ValidatorFn[] = [Validators.pattern('^\\\+?[0-9\(\)\\\s-]{7,17}$')]
	declare phone: string;
	_phone: ValidatorFn[] = [Validators.pattern('^\\\+?[0-9\(\)\\\s-]{7,17}$')]
	declare phone_alt: string;
	_phone_alt: ValidatorFn[] = [Validators.pattern('^\\\+?[0-9\(\)\\\s-]{7,17}$')]

	declare age: number;
	declare birth_date: Date;

	declare company_name: string;
	declare trade_account_enabled: boolean;

	declare gender: string;

	declare joint_id: uuid;
	declare partner: Customer;

	declare notifications: { [key:string]:string[] }

	declare memberships?: Membership[];

	declare profile_img?: {
		url: string;
		panel: { url: string; };
		thumbnail: { url: string; };
	};
	
	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string
	declare readonly:boolean;

	declare tags?: Tag[];
	declare customer_tags?: CustomerTag[];

	declare orders?: Order[];
	declare interactions?: Interaction[];
	declare tasks?: Task[];
	
	declare company_customers: CompanyCustomer[];

	declare cards:PaymentCard[] // there´s no need to initialise (or detect the attribute was or wasn't loaded) separately from the API, hence the null value can be initialised for convenience to a default value so as to simplify the code
	declare addresses:Address[] // ditto as per cards.
	
	declare stat?: DemographicStat;
	_statDefault = { 
		sales_units: 24, sales_value: 6507, 
		ltm_collected_sales_value:542	, ltm_shipped_sales_value:1128,  ltm_sales_percentile:74.3, ltm_membership_sales_value:1689, 

	};

	declare stats?: DemographicStat[];

	constructor(values?: Object) {

		super('customer', values);

		if (values) {

			let k:string='company_customers'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new CompanyCustomer(obj) });
			}
			
			k='customer_tags'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new CustomerTag(obj) });
			}
			k='tags'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Tag(obj) });
			}
			k='addresses'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Address(obj) });
			}
			k='cards'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new PaymentCard(obj) });
			}
			
			k='memberships'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Membership(obj) });
			}

			k='partner'
			if (values[k]) { this[k] = new Customer(values[k]); }
			// only attributes included in the "default" GET call should be transformed here
			// anything else is handled via the correct customerService.loadSomething call
		}

	}

	public toString() : string {
		return this.fullname;
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			link: '/customers/' + this.id,
		}, notification)) as TopbarNotification
	}

	public receives(channel:string|string[], type:string): boolean {
		if (Array.isArray(channel)) {
			return channel.every(_ => this.receives(_, type))
		}
		return (this.notifications && this.notifications[channel] && this.notifications[channel].includes(type)) || false
	}
	public patchableValues() {
		let result = super.patchableValues()
		
		this.notifications ||= {};
		['newsletters','orders','memberships','financial','others'].forEach(_ => {
			result[`${_}_notifications`] = 	this.notifications[_] || []
		})

		result['gender'] ||= '-' // ensure that null values from the api are not preventing the salutation and gender dropdowns from being displayed
		result['salutation'] ||= ''
		
		return result
	}

	public tagAsAssigned(tag: Tag): CustomerTag {
		return this.customer_tags?.find(ct => ct.tag_id == tag.id) || {} as CustomerTag;
	}

	/** used to filter which tags are displayed on the first / important row, versus the second one */
	public tagsForRow(no: number): Tag[] {

		const row0 = ['custom','marketing','config']

		if (!this.tags && this.customer_tags) {
			this.tags = this.customer_tags?.map(_ => _.tag)
		}
		
		const tags = this.tags?.filter(_ => _.category != 'config') || []

		if (no == 0) {
			return tags.filter(_ => row0.includes(_.category)) // just cleaner to include config in row0 and exclude it here
		} else if (no == 1) {
			return tags.filter(_ => !row0.includes(_.category) && ['customers', 'orders', 'companies'].includes(_.usage));
		} else if (no == 2) {
			return tags.filter(_ => !row0.includes(_.category) && ['products-wine', 'products-beer', 'products'].includes(_.usage));
		} else {
			return []
		}
	}

	public cc(company_id?: uuid): CompanyCustomer {
		return this.company_customers && this.company_customers.find((cc) => company_id == null || cc.company_id == company_id) || {} as CompanyCustomer
	}
	public isReadOnly(company_id?:uuid) {
		return this.readonly || this.cc(company_id)?.readonly
	}
	
	/**
	 * 
	 * @returns 
	 */
	public isTrashed(company_id:uuid): boolean {
		return (this.trashed_at != null && this.trashed_at < new Date()) || this.cc(company_id).trashed_at != null && this.cc(company_id).trashed_at < new Date()
	}

	/**
	 * 
	 * @param which_address 
	 * @returns 
	 */
	public captureAddressInParts(addr: string): boolean {

		const poBoxRegex = RegExp(/(PO BOX|GPO BOX|POST OFFICE BOX|P\.O\. BOX|LOCKED BAG|PRIVATE BAG)/, 'i');
		return addr && addr.match(poBoxRegex) != null;
	}

	get deliveryAddress(): null | Address {
		
		if (this.addresses && this.addresses.length > 0) {
			const target = this.company_customers && this.company_customers[0].delivery_address_id ? this.company_customers[0].delivery_address_id : this.addresses[0].id
			return this.addresses.find(_ => _.id == target)
		}

		return null
	}
	get billingAddress(): null | Address {
		
		if (this.addresses && this.addresses.length > 0) {
			const target = this.company_customers && this.company_customers[0].billing_address_id ? this.company_customers[0].billing_address_id : this.addresses[0].id
			return this.addresses.find(_ => _.id == target)
		}

		return null
	}
	get preferredCard(): null | PaymentCard {
		if (this.cards && this.cards.length > 0) {
			const target = this.company_customers && this.company_customers[0].primary_card_id ? this.company_customers[0].primary_card_id : this.cards[0].id
			return this.cards.find(_ => _.id == target)
		}
		return null
	}

	public toggleNotification(notification, mode) {

		let value: string[] = this.notifications[notification] || []
		if (!Array.isArray(value)) { value = (value != "" ? [value]: []) }

		let position: number = value.indexOf(mode);

		if (position < 0) {
			value.push(mode);
		} else {
			value.splice(position, 1);
		}

		this.notifications[notification] = value

		return this.notifications[notification]
	}

	get fullname():string {
		return (this.salutation ? `${this.salutation} ${this.lname || ''}, ${this.fname || ''}` : `${this.fname || ''} ${this.lname || ''}`).trim()
	}
	public membership(id): Membership | null {
		return this.memberships?.find(_ => _.id == id) || null
	}
	get currentMembership(): Membership|null { 
		return this.memberships?.filter((m) => m.status == 'current').at(-1)
	}
	get currentMemberships(): Membership[] { 
		return this.memberships?.filter((m) => m.status == 'current') || []
	}

	get pendingOrders(): Order[] {
		return [];
	}

	public genderBgClass(gender?): string {
		gender ||= this.gender
		if (this.trade_account_enabled) { return 'bg-light'; }
		if (gender == 'female') { return 'bg-pink'; }
		if (gender == 'male') { return 'bg-primary'; }
		if (gender == 'non-binary') { return 'bg-success'; }
		return 'bg-dark';
	}

	/**
	 * When retrieving Object.stat, allows to conditionally set the attribute if we have sufficient data retrieved, or set using demo data.
	 * @param value 
	 * @returns 
	 */
	assign_stat(value: DemographicStat):boolean {
		if (value.sales_value > 0) {
			this.stat = value;
		} else {
			this.stat = this._statDefault as DemographicStat;
		}
		return this.stat != this._statDefault;
	}

	public tagsByAdmin():Tag[] {
		return [];
	}
}