<div class="container">
    <div class="row justify-content-center mt-4">
        <div class="col-4 text-center">
            <img src="https://res.cloudinary.com/subscribility-p/image/upload/w_150/troly-logo-circle-0288d1-43a047.png"
                alt="Troly logo" />
        </div>
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 col-xl-5">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>