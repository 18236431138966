import { NgModule } from '@angular/core';

import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { TrolyCard } from '../core/components/troly.card';
import { AvatarDirective } from '../core/directives/avatar/avatar.directive';
import { linkWarningDirective } from '../core/directives/link-warning.directive';
import { LocalizedNumericInputDirective } from '../core/directives/localised-numeric-input.directive';
import { VarDirective } from '../core/directives/ng-var.directive';
import { preventDefaultDirective } from '../core/directives/prevent-default.directive';
import { CallbackFilterPipe } from '../core/pipes/arrays/callback-filter.pipe';
import { RoundPipe } from '../core/pipes/round.pipe';
import { SortByPipe } from '../core/pipes/sort-by.pipe';
import { CloudinaryPipe } from '../core/pipes/urls/cloudinary.pipe';
import { DocumentToUrlPipe } from '../core/pipes/urls/document.pipe';
import { TrolyAngularModule } from './angular.module';
import { TrolyMaterialModule } from './material.module';
import { AddressesOnFileComponent } from './ui/addresses-on-file/addresses-on-file.component';
import { CreateAddressModal } from './ui/addresses-on-file/create-address.modal';
import { DocumentViewerModal } from './ui/business/document-viewer/document-viewer.modal';
import { CardsOnFileComponent } from './ui/cards-on-file/cards-on-file.component';
import { CreatePaymentCardModal } from './ui/cards-on-file/create-card.modal';
import { HighlightableDirective } from './ui/highlightable.directive';
import { LocalizedNumericInputComponent } from './ui/localized-numeric-input/localized-numeric-input.component';
import { OrderableDirective } from './ui/orderable.directive';
import { SelectableDirective } from './ui/selectable.directive';
import { SortableDirective } from './ui/sortable.directive';
import { DebugComponent } from './widgets/debug/debug.component';
import { FormStatusComponent } from './widgets/forms-status/form-status.component';
import { GeoSearchComponent } from './widgets/geo-search/geo-search.component';
import { HeaderLoadingIndicatorComponent } from './widgets/header-loading-indicator/header-loading-indicator.component';
import { HelpPelletComponent } from './widgets/help-pellet/help-pellet.component';
import { MiniNavWidget } from './widgets/mini-nav/mini-nav.widget';
import { RecentRecordsCard } from './widgets/recent/recent.card';
import { SearchDropdownComponent } from './widgets/search-dropdown/search-dropdown.component';
import { SocketStatusComponent } from './widgets/sockets/socket-status.component';
import { StarRatingComponent } from './widgets/stars/star-rating.component';
import { StatusHintComponent } from './widgets/status-hint/status-hint.component';
import { UploaderComponent } from './widgets/upload/uploader.component';

const declaredSharedModules = [

	VarDirective,

	FormStatusComponent,                // helps display form error messages in a standard fashion
	GeoSearchComponent,                 // searches through google geosearch api for an address
	UploaderComponent,                  // uploads a file to a CDN
	SearchDropdownComponent,            // Searching through a SOLR index (although, quite Troly-specific in thie case.. :\ )
	SocketStatusComponent,              // attaches, displays and dispatches socket messages
	StarRatingComponent,                // displays a star rating ]
	StatusHintComponent,						// shows a generic hint on data loading and/or no data found or no data to be displayed

	LocalizedNumericInputDirective,     //

	HelpPelletComponent,

	CallbackFilterPipe,
	SortByPipe,
	RoundPipe,
	DocumentToUrlPipe,
	CloudinaryPipe,
	AvatarDirective,

	OrderableDirective,						// Used to order children elements in a list or (eg.) dropdown menus
	SortableDirective,						// Allows to make a table header "sortable" while interacting with the ListComponent
	HighlightableDirective,					// in a list of object (table or else) allows to highlight a row when it was recently changed or added
	SelectableDirective,						// Allows for an element (namely bootstrap cards) to be selected and highlighted (css)

	AddressesOnFileComponent,
	CardsOnFileComponent,
	CreatePaymentCardModal,
	CreateAddressModal,
	MiniNavWidget,
	HeaderLoadingIndicatorComponent,

	LocalizedNumericInputComponent,

	DocumentViewerModal,
	DebugComponent,

	TrolyCard,
	RecentRecordsCard,			// used by both customers and products -- either here or as standalone
	
];


//LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));

@NgModule({
	declarations: declaredSharedModules,
	imports: [
		
		linkWarningDirective, /// this is a standalone directive, not a module
		preventDefaultDirective,
		TrolyAngularModule,
		TrolyMaterialModule,

		NgxScannerQrcodeModule
	],
	exports: [
		linkWarningDirective, /// this is a standalone directive, not a module
		preventDefaultDirective,
		declaredSharedModules
	],
	providers: []
})
export class TrolySharedModule { }
