import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, TimeoutError } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';
import { environment } from 'src/environment/environment';

import { Company } from '../models/troly/company.model';
import { User } from '../models/troly/user.model';
import { uuid } from '../models/utils.models';
import { CompanyService } from './troly/company.service';
import { UserService } from './troly/user.service';
import { WindowService } from './window.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'AuthService';

	protected userService: UserService = inject(UserService);
	protected companyService: CompanyService = inject(CompanyService);

	protected windowService: WindowService = inject(WindowService);

	constructor(
		public cookieService: CookieService,
	) {

		// Ensure both userService and companyService are aware of the 
		// current user and company objects, if any. Both are 
		this.userService.record$.next(this.userService.storedUser());
		this.companyService.record$.next(this.userService.storedCompany());
	
		if (!this.userService.record$.value?.id || !this.companyService.record$.value?.id) {
			//this.logout() 
		}
		
	}

	private authenticatePromise: Promise<boolean>;
	public authenticate(id: uuid, default_co_id?:uuid): Promise<boolean> {

		if (!this.authenticatePromise) {
			
			this.authenticatePromise = new Promise<boolean>((resolve) => {
				
				let company = this.userService.storedCompany();
				if (!default_co_id && company.id) { default_co_id = company.id }
				if (!company.id && default_co_id) { this.userService.storedCompany(new Company({id:default_co_id})) }

				const params = {with_company_users:true,with_companies:true}
				this.userService._find(id, params).pipe(filter(_ => !!_)).subscribe({
					next: (user: User) => {

						if (company && company.id == default_co_id && company.legal_name) {

							this.login(user, company);
							// the server has confirmed this is authenticated and has access to the company already loaded.
							resolve(true); 

						} else if (!user.companies || user.companies.length == 0) {
							// this user is not an administrator of any companies
							resolve(false);

						} else {
							// here we are mypassing the normal url builder because the user service will only call 
							// endpoint/:id/method dynamically for the users endpoint
							// so it's either this mucking around or we need to call using the companyService -- but we don't have a confirmation of access at this point (?)
							this.companyService._find(default_co_id || user.companies[0].id).pipe(filter(_ => !!_)).subscribe({
								next: (_company) => {
									this.login(user, company);
									resolve(true);
								},
								
								error: (err) => {
									// note: when a user looses access to a company they have logger on before, we force using the first of the companies they DO have access to.
									this.companyService._find(user.companies[0].id).pipe(filter(_ => !!_)).subscribe({
										next: (_company) => { this.login(user, _company); resolve(true); },
										error: (err) => { resolve(false); }
									})

								}
							})
						}
					}, 
					error: (err) => { 
						debugger;
						// for any reaason, if the user.id is not found (usually in local dev after flushing the db 😭)
						window.localStorage.removeItem('us');
						resolve(false); 
					},
					complete: () => { 
						this.authenticatePromise = null; 
					}
				})
			})
		
			this.authenticatePromise.then((_) => {
				this.confirmAccessPromise = new Promise((resolve) => { resolve(_) });
			})
		}

		return this.authenticatePromise;

	}

	public isAuthenticatedClientSide(): boolean {

		let authenticated = false;

		const sessionCookie = this.cookieService.get('_troly_session')
		const csrf = this.userService.storedCsrf()
		const user = this.userService.storedUser();
		const company = this.userService.storedCompany();

		// How do we recognise a valid session:
		// session cookie must be set, has a csrf token stored
		// user store has ID and companies
		// company has legal name (if company object is missing ==>  will be loaded in .authenticate() above.
		if (sessionCookie != null &&
			csrf != null &&
			user?.id != null && user?.companies?.length >= 1 &&
			company?.legal_name != null) {
			authenticated = true;
		}

		return authenticated;
	}

	public login(u, c): void {

		u = this.userService.storedUser(u, 'login');
		c = this.userService.storedCompany(c, 'login');

		// set the users session data in the crisp chat
		if ((<any>window).dataLayer) {
			(<any>window).dataLayer.push({
				'user_id': u.id,
				'email': u.email,
				'fname': u.fname,
				'lname': u.lname,
				'created_at': u.created_at,
				'tracking_token': u.tracking_token,
				'company_name': c.business_name,
				'company_id': c.id,
				'company_created_at': c.created_at,
				'user_internal': environment.production ? (u.email.match('@troly.(co|org)') ? 'internal' : undefined) : 'developer' // when running in a non-production mode, we assume its developer traffic, for debugging tags.
			});
		}

		//if (this.layoutActionNotifier) {  this.layoutActionNotifier.complete() }
		//this.layoutActionNotifier = new BehaviorSubject(null)
	}

	
	public logout(fragment:string = '') {

		let u: User = this.userService.storedUser();
		let c: Company = this.userService.storedCompany();

		if (c.legal_name) { // is logged in
			this.userService.storedCompany(c, 'logout');
			this.userService.storedUser(u, 'logout');
			this.userService.flushLocalStorage();

			//let cookie = this.cookieService.get('_troly_session')
			//debugger;
			//if (this.cookieService.get('_troly_session')) {
			//	this.cookieService.delete('_troly_session', '/');
			//}
			this.userService.logout().subscribe(_ => {
				if (fragment && !document.location.pathname.match('/start')) { document.location = `/start/now#${fragment}` }
			});
		
		} else if (!document.location.pathname.match('/start')) { 
			document.location = `/start/now${fragment ? '#' + fragment : ''}`
		}

	}

	public changeCompany(company_id:uuid, region:'au'|'eu'|'us'): void {
		const previousRegion = localStorage.getItem('db');
		localStorage.setItem('db', region);
		this.companyService.flushLocalStorage();
		this.companyService._find(company_id).pipe(filter(_ => !!_)).subscribe((resp) => {
			//debugger;
			document.location = '/dashboard'
		}, (err) => { 
			localStorage.setItem('db', previousRegion);
		});
	}

	private confirmAccessPromise: Promise<boolean>;
	// fetch the currently logged in user based on their session against our back-end
	public confirmAccess(): Promise<boolean> {

		if (!this.confirmAccessPromise) {

			this.confirmAccessPromise = new Promise((resolve, reject) => {
				this.userService.find(null, {}, 'sign_in').pipe(timeout(3000)).subscribe({
					next: (_) => {
						if (!_ || !_.id) {
							this.logout();
							resolve(false)
						} else {
							if (this.isAuthenticatedClientSide()) { // we have the correct indicators the current session is fine locally also,
								resolve(true)
							} else if (_.id) {
								// local storage doesn't show a concistend session state with the server, let's reinitialize the local session for the user id returned by the server.
								this.authenticate(_.id).then((_ok) => {
									resolve(_ok);
								})
							} else {
								resolve(false)
							}
						}
					}, 
					error: (e) => { 
						this.logout(); 
						if (e instanceof TimeoutError) {
							reject(e); 
						} else {
							resolve(false); 
						}
					}
				});
			});

			this.confirmAccessPromise.finally(() => {
				//this.confirmAccessPromise = null;
			})
		}

		return this.confirmAccessPromise;
	}

	public layoutActionNotifier = new BehaviorSubject(null);

}
