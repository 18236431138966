import { ValidatorFn, Validators } from "@angular/forms";
import { TopbarNotification } from "../topbar.models";
import { TrolyFormGroup } from "../troly_form";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { PaymentMethod } from "./payment.model";
import { ShippingCarrier } from "./shipment.model";
import { SystemStat } from "./stats.model";

export class Integration extends TrolyObject {

	override _trolyPropertyArray = { params: {} }

	declare company_id: uuid;

	declare provider: string;
	declare processor: 'Shipping'|'Marketing'|'Base'|'Payment'|'Accounting';
	declare tags: string[];
	//
	// only populate for the (installed) integration model retrieved from the API.
	//
	declare status: 'pending'|'connected'|'ready'|'warning'|'error'|'uninstalled'|'requested';
	declare params: {}; // configuration saved and other details communication with 3rd party api
	declare data: {}; // data placed in there by the gateway for use or reference // is readonly
	declare provider_data: {}; // any data saved by 3rd parties

	declare last_event_time: Date;
	declare last_30days_usage: number;

	declare show_in_navigation: 'always' | '' | 'never';
	declare send_notifications_to: 'admin' | '';

	/**
	 * Attributes extrapolated from config.yml for the gateway
	 */
	//
	// only retrieved for an integration module from the marketplace (not installed)
	//
	declare name: string;
	declare info: string;
	declare description: string;
	declare benefits: string;
	declare instructions: string;
	declare price: any;
	declare price_min: any;

	declare ondemand: string;
	declare ondemand_options: string

	declare markets: string[];
	declare availability: string[];


	declare logo: string;
	declare hero: string;
	declare website: string;
	declare admin_url: string;
	declare icon: string;

	declare info_url: string;
	declare support_url: string;
	declare pricing_url: string;
	declare pricing: string;
	declare setup: string;

	declare supports: string[];  // list of permissions
	declare support: string; // additional support notes
	declare premium: string; // what makes this app a premium app

	declare env?: { // environment-based variables automatically assigned
		oauth2_url?: string,
		admin_url?: string
	}

	declare layout: {}; // info about the config form to generate
	declare labels: {}; // translations for the config form generated

	declare translations: {}; // translations for the integration module

	declare ratings: { count: number, value: number };

	declare stat?: SystemStat
	declare stats?: SystemStat[]

	constructor(values?: Object, currentLang:string=null) {
		super('integration', values);

		if (currentLang) { this.assignTranslation(currentLang) }
	}


	public toString() { return this.nameWithoutCompany() }
	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {

		return super.toNotification(Object.assign({
			layoutAction: {createIntegration:true, integration:{provider: this.provider }},
			status: this.status == 'uninstalled' ? 'info' : (this.status == 'ready' ? 'new' : this.status), // we're using the status field to indicate more, we're not displaying anything else but 'ready', 'error', 'warning','uninstalled'
		}, notification)) as TopbarNotification
	}

	assignTranslation(lang: string):Integration {
		// once we know the user language we can apply the correct translation based on what was received.
		let la = lang.split(/[-_]/)[0];
		let _installedname = this.name; // Once installed, the company name is appended, let's preserve
		if (this.translations && this.translations[lang]) {
			Object.assign(this, this['translations'][lang])
		} else if (this.translations && this['translations'][la]) {
			Object.assign(this, this['translations'][la])
		}
		// if a different name was available, let's restore it (as long as the integration is still installed - uninstalled remain in the db.)
		if (_installedname && this.isInstalled) { this.name = _installedname; }
		return this;
	}

	public isRequestOnly():boolean {
		return this.availability?.includes('request')
	}
	public isInstalled():boolean {
		return this.id != null && ['connected','ready','error','warning'].includes(this.status)
	}
	public isReadyForConfig():boolean {
		return this.id != null && this.status == 'connected'
	}

	public nameWithoutCompany():string {
		return this.name?.split(' ― ')[0]
	}

	/** Based on the integration price configured, returns how pricing is be expected to be charged. */
	public pricedAs(): 'range' | 'fixed' | 'percent' | 'free' {
		if (this.price_min) {
			return 'range';
		} else if (this.price && this.price[this.price.length - 1] == '%') {
			return 'percent';
		} else if (this.price == 0) {
			return 'free';
		} else {
			return 'fixed';
		}
	}

	public getIcon(provider?:string):string {
		return this.logo || (this.provider && `https://res.cloudinary.com/subscribility-p/image/upload/b_transparent/cdn/assets/app-static/addons/${provider || this.provider}-icon.png`.toLowerCase())
	}


	private _fieldsConfigs: IIntegrationFieldConfig[];
	public fieldConfigs(reset:boolean=false): IIntegrationFieldConfig[] {

		if (!this._fieldsConfigs || reset) {
			
			this._fieldsConfigs = [];

			if (this.layout && this.layout['fields']) {
				this.layout['fields'].forEach((f) => {
					const field = this.fieldConfig(f);
					if (field.name) { this._fieldsConfigs.push(field); }
				});
			}			
		}

		return this._fieldsConfigs;
	}

	public fieldConfig(fieldName: string): IIntegrationFieldConfig {

		let field: IIntegrationFieldConfig = {} as IIntegrationFieldConfig;
		let i18n = this.labels && this.labels[fieldName.trim()];

		if (i18n) {
			if (fieldName[0] == '_') {
				field = { name: fieldName, type: 'section', colclass: 'col-12', label: i18n.label, hint: i18n.hint };
			}
			else if (this.layout[fieldName]) {

				let layout = this.layout[fieldName];

				if (layout && i18n) {
					field.colclass = layout.size
					field.type = layout.type

					field.name = fieldName
					field.label = i18n.label
					field.hint = i18n.hint || ''
					field.error = i18n.error || ''

					field.validations = []

					if (layout.validate?.match("required")) {
						field.validations.push(Validators.required);
					}

					field.options = [];
					if (this.data) {
						field.options = this.data[layout.data] || this.data[fieldName];

						if (['select','radio'].includes(layout.type)) {

							if (layout.options) { // allowing for a pipe-delimited list of option, where the value and the labels are the same. if the label needs to be internationalised, see i18n.options below
								field.options = layout.options.split('|').map((_) => { return { id: _, name: _ } });
							}
							if (i18n.options) { // allowing for internationalised static options to be provided by the config file as a querystring-delimited string (key1=value1&key2=value2)
								field.options = i18n.options.split("&").map((_) => { let o = _.split("="); return { id: o[0], name: o[1] } })
							}
						}

						if (field.options) {

							if (['select','radio'].includes(layout.type) && field.options.length > 0 && (typeof field.options[0] == 'string')) {
								// if the data array only contains strings, we're transforming it so that we have 
								// an array of objects with .id and .name
								field.options = field.options.map((_) => { return { id: _, name: _ }; })
							}

							// custom validation for "in-list" allowing to validate a selected list value from what the integration.json is giving us
							if (layout.validate?.match("in-list")) {
								let validList = field.options.map(_ => _['id'] || _['key']);
								const form = new TrolyFormGroup({})
								field.validations.push(form.customValidators.valueInList(validList))
							}
						}

					}
				}
			}

		}

		return field
	}
	/**
	 * 
	 * @param obj 
	 * @returns 
	 */
	public sameAs(obj: Integration): boolean {

		// this is not technically accurate, however typescript seems to drop the 'class' 
		// objects are assigned to when collections are manipulated and this is just a pain (namely, list.component retrieving clubs and caching to localStorage)
		if (this.id)  {
			return super.sameAs(obj)
		} else {
			return this.provider == obj.provider;
		}
	}
}

export class TrolyPaymentsIntegration extends Integration {

	public readonly _PAYMENT_METHODS_STORAGE_KEY = 'payment_methods';

	constructor(values?: Object, currentLang:string=null) {
		super(values, currentLang)

		if (values) {
			if (values['params']) {
				// The payment methods integration is a bit special and allows managing 'payment_method' records which are not directly available through the API only through the integration parameters
				// In order to make this model as similar to other models, we create the configure objects here
				let k = this._PAYMENT_METHODS_STORAGE_KEY
				if (values['params'][k] && values['params'][k].length) {
					values['params'][k].map((obj, i) => { this['params'][k][i] = new PaymentMethod(obj) });
				}
			}
		}
	}

	get records(): PaymentMethod[] {
		return this.params[this._PAYMENT_METHODS_STORAGE_KEY] || []
	}
}


export class TrolyShippingIntegration extends Integration {

	public readonly _OFFLINE_CARRIERS_STORAGE_KEY = 'offline_carriers';

	constructor(values?: Object, currentLang:string=null) {
		super(values, currentLang)

		if (values) {
			if (values['params']) {
				
				// see TrolyPaymentsIntegration.constructor for more details
				// TLDR: carriers are special and not stored as 'records' and no GET/PUT/DELETE endpoints are available for them -- all managed in the frontend
				let k = this._OFFLINE_CARRIERS_STORAGE_KEY
				if (values['params'][k] && values['params'][k].length) {
					values['params'][k].map((obj, i) => {  this['params'][k][i] = new ShippingCarrier(obj) });
				}
			}
		}
	}

	get records(): ShippingCarrier[] {
		return this.params[this._OFFLINE_CARRIERS_STORAGE_KEY] || []
	}
}

export class IBillingIntegration extends Integration {

}

export interface IIntegrationFieldConfig {

	name: string;
	type: 'section' | 'date' | 'currency' | 'select' | 'text' | 'password';

	colclass: string;

	label: string;
	hint?: string;
	error?: string;

	options?: any[];

	validations?: ValidatorFn[];

}