
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { SalesStat } from "./stats.model";

export class CompanyCustomer extends TrolyObject {

	override _trolyPropertyArray = { since_date: Date }

	/** Customer for which we are capturing company-based attrbutes & notes */
	declare customer_id: uuid;

	/** Company who created+operates a customer */
	declare company_id: uuid;

	/** Current Sentiment Analysis details for this customer */
	declare sentiment: {}

	/**
	 * Payment and Shipping Preferences
	 */

	/** Preferred customer card for payments made to this company */
	declare primary_card_id: uuid
	/** Backup card for payments made to this company */
	declare backup_card_id: uuid

	/** Preferred address for deliveries for orders shipped by this company */
	declare delivery_address_id: uuid;
	/** Preferred address billing/invoices to be issued to by this company */
	declare billing_address_id: uuid;

	/** The preferred shipping CARRIER for this customer */
	declare shipping_carrier_pref: string;
	/** The preferred shipping SERVICE for this customer */
	declare shipping_service_pref: string

	/** Customer-preferred warehouse for orders collection -- overrides the compay defaults, not the membership. */
	declare orders_warehouse_id: uuid
	/** Customer-preferred warehouse for shipments -- overrides the compay defaults, not the membership. */
	declare shipments_warehouse_id: uuid

	/**
	 * Preferences and Notes
	 */

	/** Customer photo uploaded for this company. This is a mounted uploaded and includes multiple urls */
	declare profile_img: {}

	/** Default membership to apply extra sales onto, default selection */
	declare membership_id: uuid;

	/** Unique membership number for this company-member, often printed on membership card or QR */
	declare membership_num: string;

	/** At-large product preferences to apply to order confirmation steps, for membership orders */
	declare product_notes: string;

	/** At-large payment preferences to apply to order confirmation steps, for membership orders */
	declare payment_notes: string;

	/** QR image generated for this club member to be recognised as a club member. This is a mounted uploaded and includes multiple urls */
	declare member_qr_code?: { url: string, thumbnail: { url:string }; };
	declare member_card_url_override: string;

	declare loyalty_qr_code?: { url: string, thumbnail: { url:string }; };
	declare loyalty_card_url_override: string;

	/** Internal notes about this customer by this company */
	declare internal_notes: string

	/** Internal notes about this customer by this company */
	declare likes_notes: string
	declare dislikes_notes: string

	/**
	 * Referrals Tracking
	 */

	/** the unique code generated for this customer as an affiliate */
	declare referral_code: string;

	/** a list of affiliate orders yielding to points reward being accumulated, or used/reduced  */
	declare referrals_recorded: CustomerReferral[]

	/**  */
	declare referral_qr_code?: { url: string, thumbnail: { url:string }; };

	/** The current points balance for this customer */
	declare reward_points_balance: number;

	declare trade_account_manager_id: uuid;
	declare trade_account_limit: number;
	/**
	 * Source and Tracking
	 */

	/** Staff name who have created this customer record -- used as fallback if the user_id no longer exist */
	declare source_staff: string;
	/** Staff who created this customer record */
	declare source_staff_id: uuid;
	/** Date at which this customer has become a contact of this company */
	declare since_date: Date;

	/** System that originated/created this record from */
	declare track_source: string;
	/** The channel this customer came from to the company */
	declare track_channel: string;
	/** The partner who has signed up this customer to the company */
	declare track_partner: string;
	/** internal marketing campaign name */
	declare track_campaign: string;

	/**
	 * Basic Statistics and Record operations
	 */
	declare last_emailed_at: Date
	declare last_emailed_by_id: uuid
	declare last_called_at: Date
	declare last_called_by_id: uuid
	declare last_visited_at: Date
	declare last_visited_by_id: uuid
	declare last_purchase_at: Date
	declare last_purchase_by_id: uuid

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string
	declare readonly:boolean;


	/**  */
	declare lifestyle_partner_name: string;
	declare lifestyle_partner_bday_date: Date;
	declare lifestyle_partner_relationship:string;
	declare lifestyle_wedding_date: Date;
	declare lifestyle_pet_name: string;
	declare lifestyle_pet_bday_date: Date;
	declare lifestyle_pet_type: string;

	declare lifestyle_family_info: string;

	/**
	 * API Model Attributes
	 */
	declare stat?: SalesStat

	/**
	 * Additional (Virtual) Attributes
	 */
	/** Customer-prefered signature requirement preference, overrides the company setting */
	declare shipping_signature_pref: string;
	/** Customer-prefered insurance requirement preference, overrides the company setting */
	declare shipping_insurance_pref: string;

	/**
	 * 
	 */
	declare provider_data: {
		guest_customer_of_company: uuid
	}

	constructor(values?: Object) {
		super('company_customer', values);
	}
}

export class CustomerReferral extends TrolyObject { }