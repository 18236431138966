<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">
		<!-- Card on File -->
		<div class="{{ bsCssClassPrefix }}-header">
			<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!form.get('id')?.value">Add a new address</h4>
			<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="form.get('id')?.value">Update {{record.label}} address</h4>

			<button type="button" class="btn-close" [disabled]="form.seconds > 0"
				(click)="resolveModal('x')"></button>
		</div>
		<div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

			<form-status [formGroup]="form" successAction="redirect" (dismissCallback)="resolveModal($event)"
				[record_label]="record?.toString()">
			</form-status>


			<div class="row">
				<div class="col-8">
					<mat-form-field>
						<mat-label>{{ 'Models.Address.Attributes.line1' | translate }}</mat-label>
						<input matInput formControlName="line1" type="search" autoComplete="off" role="presentation" />
					</mat-form-field>
				</div>

				<div class="col-4">
					<mat-form-field>
						<mat-label>{{ 'Models.Address.Attributes.label' | translate }}</mat-label>
						<input matInput (focus)="standardOptions.open()" formControlName="label" type="text" />
						
						<mat-select #standardOptions matSuffix (selectionChange)="form.patchValue({label:$event.value})" [value]="form.get('label').value" class="remove-select-trigger">
							<mat-select-trigger></mat-select-trigger>
							<ng-container *ngIf="customer_id">
								<mat-option value="Home">Home</mat-option>
								<mat-option value="Holiday Home">Holiday Home</mat-option>
								<mat-option value="Work">Work</mat-option>
								<mat-option value="Partner">Partner</mat-option>
							</ng-container>
							<ng-container *ngIf="!customer_id">
								<mat-option value="Office">Office</mat-option>
								<mat-option value="Mailling">Mailling</mat-option>
								<mat-option value="Shop">Shop</mat-option>
								<mat-option value="Store">Store</mat-option>
								<mat-option value="Warehouse">Warehouse</mat-option>
							</ng-container>
							<mat-option value="Other">Other</mat-option>
						</mat-select>
						
						<mat-hint>Internal use only</mat-hint>
					</mat-form-field>
				</div>

				<div class="col-12" *ngIf="showLine2.checked || form.get('line2').value != ''">
					<mat-form-field>
						<mat-label>{{ 'Models.Address.Attributes.line2' | translate }}</mat-label>
						<input matInput formControlName="line2" type="search" autoComplete="off"/>
					</mat-form-field>
				</div>

				<ng-container *ngIf="manualOverride.checked || record.isPoBox(form.get('line1').value)">
					<div class="col-12 mt-1 mb-3" *ngIf="record.isPoBox(form.get('line1').value)">
						<div class="d-flex">
							<div class="avatar-xs me-2"><span
									class="avatar-title rounded-circle font-size-20 bg-soft bg-warning"><i
										class="bx bxs-error-circle text-warning"></i></span></div>
							<h6 class="font-size-10 mt-1 ms-2"><strong>PO Box detected</strong><br>Please
								enter the following details carefully to billing or delivery errors.</h6>
						</div>
					</div>
					<div class="col-5">
						<mat-form-field>
							<mat-label>{{ 'Models.Address.Attributes.suburb' | translate }}</mat-label>
							<input matInput formControlName="suburb" type="search" />
						</mat-form-field>
					</div>
					<div class="col-5">
						<mat-form-field>
							<mat-label>
								{{ 'Models.Address.Attributes.state' | translate }}
							</mat-label>
							<input matInput formControlName="state" type="search" />
						</mat-form-field>
					</div>
					<div class="col-4">
						<mat-form-field>
							<mat-label> {{ 'Models.Address.Attributes.postcode' | translate }} </mat-label>
							<input matInput formControlName="postcode" type="search" />
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field>
							<mat-label>
								{{ 'Models.Address.Attributes.country' | translate }}
							</mat-label>
							<input matInput formControlName="country" type="search" />
						</mat-form-field>
					</div>
				</ng-container>

				<div class="col-10" *ngIf="manualOverride.checked == false">
					<geo-search-component [fieldName]="'Models.Address.Attributes.search' | translate"
						[line1]="form.get('line1').value"
						[searchArea]="record.searchArea"
						[disabled]="form.disabled"
						(selectCallback)="patchDirty($event)">
					</geo-search-component>
				</div>

				<div class="col-10">
					<mat-form-field>
						<mat-label>Delivery Instructions</mat-label>
						<input matInput formControlName="delivery_instructions" type="text"/>
						<mat-hint>To be added to shipping labels</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-12">
					<mat-checkbox #showLine2
						[checked]="form.get('line2').value != ''"
						[disabled]="form.get('line2').value != ''"
						>
						Enter building details, addresse, suite, or floor number
					</mat-checkbox>
					<mat-checkbox #manualOverride formControlName="is_manual_override">
					Manually enter individual address fields</mat-checkbox>
				</div>
				<div class="col-12" *ngIf="!customer_id">
					<mat-checkbox
						formControlName="sync_warehouse"
						>
						Inventory is held at this address
					</mat-checkbox>
				</div>
			</div>
		</div>
		<div class="{{ bsCssClassPrefix }}-footer">
			<button type="submit" class="btn btn-primary {{ form.loading }}"
				[disabled]="form.cannotSubmit">
				<span *ngIf="!form.get('id')?.value">Add</span>
				<span *ngIf="form.get('id')?.value">Save</span>
			</button>
		</div>
	</form>
</div>