import { Component, Input } from '@angular/core';

@Component({
  selector: 'header-loading-indicator',
  templateUrl: './header-loading-indicator.component.html',
  styleUrls: ['./header-loading-indicator.component.scss']
})
export class HeaderLoadingIndicatorComponent {
	// todo: ready to test
	@Input('status') status!:string;
}