import { Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

export class CompanyTemplate extends TrolyObject {

	override _trolyPropertyArray: {} = { params: Object }

	declare company_id: uuid
	declare name: string;
	declare channel: 'sms'|'email'|'call'|'task';

	declare status: 'required'|'enabled'|'disabled';

	declare subject?: string;
	declare body: string;
	_body = [Validators.required]

	declare version_date: Date;
	/** last date at which this template was customised */
	declare customised_at: Date;


	/** _body and _subject are (5x) only used when pulling a 'reset template' via /templates/<template_name>.json */
	declare sms_body?: string;
	declare email_subject?: string;
	declare email_body?: string;
	declare call_subject?: string;
	declare call_body?: string;

	constructor(values?: Object) {
		super('company_template', values);
	}

	public get alwaysEnabled(): boolean {
		// this list is maintained by hand and also is in the backend -- unlikely to change much and never based on user interactions, so no need to "ask" the api for this info
		return 'info_wishlist direct_customer_summary direct_customer_summary_trade info_newsletter_unsubscribe member_cancellation staff_order_warning staff_disputed_charge custom'.split(' ').includes(this.name)
	}
	public get category(): string {
		return this.name?.split('_')[0]
	}

	/**
	 * function to compare (and sort) objects
	 * @param b 
	 * @param asc 
	 * @returns 
	 */
	public localeCompare(b:CompanyTemplate, asc:boolean=true): number {
		const channelWeights = {sms:1, email:-1, call:0}
		if (asc) { 
			return this.sortWeight == b.sortWeight ? channelWeights[this.channel] : this.sortWeight - b.sortWeight
		}
		else { 
			return this.sortWeight == b.sortWeight ? channelWeights[this.channel] * -1 : b.sortWeight - this.sortWeight
		}
	}

	getCategories(): string[] {
		let items = [];
		items.push('customer')

		if (this.name.match(/member/)) {
			items.push('member')
		}

		if (this.name.match(/order/)) {
			items.push('order')
		} else if (this.name.match(/shipment/)) {
			items.push('shipment')
		}

		if (this.name.endsWith('_payment')) {
			items.push('payment')
		}

		items.push('company')

		return items
	}
	
	getTags(category:string=''): string[] {

		const customer_tags = ['fname', 'lname', 'email', 'customers_company', 'card:number', 'deliveryaddress', 'deliverysuburb', 'deliveryinstructions', 'profile_url', 'card_url', 'password_reset_url'];
		const member_tags = ['membership', 'membership_num', 'membership_length', 'previous_membership', 'membership_hold_until']
		const payment_tags = ['confirmation_code', 'payment_details', 'payment:amount', 'receipt_url']
		const order_tags = ['order_number','order_value','payment_ref','payment_status', 'payment_details', 'product_sample','order_url','processing_date','order_url','billingaddress', 'billingsuburb', 'case_price'];
		const shipment_tags = ['shiping_provider','tracking_code','tracking_link','tracking_details','deliveryaddress', 'deliverysuburb', 'deliveryinstructions'];
		const company_tags = ['company:name','company:logo', 'company:mobile', 'company:phone','company:email','company_address','company_suburb', 'company_address_1line'];

		let tags = []
		if (['','company'].includes(category)) { tags = tags.concat(company_tags) }
		if (['','member'].includes(category) && this.name.match('member')) { tags = tags.concat(member_tags) }

		switch (this.name) {

			case 'order_paid':
			case 'member_order_paid':

			case 'upcoming_order':
			case 'member_upcoming_order':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order'].includes(category)) { tags = tags.concat(order_tags) }
				break;

			case 'upcoming_shipment':
			case 'member_upcoming_shipment':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order','shipment'].includes(category)) { tags = tags.concat(order_tags) }
				if (['','shipment'].includes(category)) { tags = tags.concat(shipment_tags) }
				break;

			case 'signature':
				break;

			case 'order_preview':
			case 'order_changed':
			case 'order_abandonned':
			case 'post_order_accomplished':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order','shipment'].includes(category)) { tags = tags.concat(order_tags) }
				break;

			case 'shipment_packed':
			case 'shipment_labelled':
			case 'shipment_dispatch':
			case 'shipment_in_transit':
			case 'shipment_out_for_delivery':
			case 'shipment_delivered':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order','shipment'].includes(category)) { tags = tags.concat(order_tags) }
				if (['','shipment'].includes(category)) { tags = tags.concat(shipment_tags) }
				break;

			case 'shipment_paid':
			case 'member_shipment_paid':
			case 'shipment_packed_without_tracking':
			case 'shipment_labelled_without_tracking':
			case 'shipment_delayed':
			case 'shipment_packed':
			case 'shipment_dispatch_without_tracking':
			case 'shipment_in_transit_without_tracking':
			case 'shipment_out_for_delivery_without_tracking':
			case 'shipment_delivered_without_tracking':
			case 'post_shipment_accomplished':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order','shipment'].includes(category)) { tags = tags.concat(order_tags) }
				if (['','shipment'].includes(category)) { tags = tags.concat(shipment_tags) }

				tags.forEach((current, index) => { // remove the tracking details for "without_tracking" templates
					if (['tracking_code', 'tracking_link', 'tracking_details'].includes(current)) {
						tags.splice(index, 1)
					}
				})
				break;

			case 'renew_member':
			case 'member_precancellation':
			case 'member_cancellation':
			case 'member_suspended':
			case 'new_member':
			case 'upgrade_member': case 'downgrade_member':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				break;

			case 'cc_expiry':
			case 'cc_invalid':
			case 'successful_payment':
			case 'declined_payment':
			case 'refunded_payment':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','order','shipment'].includes(category)) { tags = tags.concat(order_tags) }
				if (['','payment'].includes(category)) { tags = tags.concat(payment_tags) }
				break;

			case 'customer_summary': 	case 'customer_summary_trade':
			case 'customer_birthday': 	case 'customer_anniversary':
			case 'customer_subscribe': case 'customer_unsubscribe':
			case 'customer_reward_threshold_1': case 'customer_reward_threshold_2': 
			case 'upcoming_wedding_anniversary':
			case 'no_order_recorded':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				break;

			case 'custom':
			case 'bounced_email':
				if (['','customer'].includes(category)) { tags = tags.concat(customer_tags) }
				if (['','unique'].includes(category)) { tags = tags.concat(['reason']) }
				break;

			case 'joint_account_confirmation':
				tags = ['fname', 'lname', 'email', 'customers_company', 'joint_fname', 'joint_email', 'joint_mobile'];
				break;

			case 'staff_financial_summary':
				if (['','unique'].includes(category)) { tags = tags.concat(['start_date','end_date','financial_summary','stock_summary', 'total_sales', 'total_refunds', 'period_report_url']) }
				break;

			case 'staff_orders_need_packing':
				if (['','unique'].includes(category)) { tags = tags.concat(['order_count','location_name']) }
				break;

		}

		return tags;
	}

	/**
	 * This is just a static list to help define th order in which we want templates to show.
	 */
	get sortWeight(): number {

		return [

			'info_wishlist',
			'info_bounced_email',
			'info_reward_program_details',
			'info_reward_threshold_1',
			'info_reward_threshold_2',
			'info_joint_account_confirmation',
			'info_newsletter_subscribe',
			'info_newsletter_unsubscribe',
			'info_order_changed',
			'info_order_hold',
			
			'direct_cart_abandonned',
			'direct_customer_birthday', 
			'direct_partner_birthday',
			'direct_wedding_anniversary',
			'direct_pet_birthday',
			'direct_campaign_anniversary',
			'direct_first_purchase_anniversary',
			'direct_customer_inactive',
			'direct_quick_hello',
			'direct_customer_summary',
			'direct_customer_summary_trade',
			
			'drip_all_1','drip_all_2','drip_all_3','drip_all_4','drip_all_5','drip_all_6','drip_all_7','drip_all_8',
		
			'member_new','member_renew','member_upgrade','member_downgrade','member_suspended','member_precancellation','member_cancellation',
		
			'payment_cc_expiry','payment_cc_expired','payment_successful','payment_declined','payment_refunded','payment_new_accounts_payable',
		
			'pickup_new',
			'pickup_club_new',
			'pickup_bulk_new',
			'pickup_club_paid',
			'pickup_bulk_paid',
			'pickup_packed',
			'pickup_labelled',
			'pickup_completed',
			'pickup_feedback',
		
			'shipment_new',
			'shipment_club_new',
			'shipment_bulk_new',
			'shipment_delayed',
			'shipment_changed',
			'shipment_packed',
			'shipment_labelled',
			'shipment_labelled_without_tracking',
			'shipment_dispatch',
			'shipment_dispatch_without_tracking',
			'shipment_in_transit',
			'shipment_out_for_delivery',
			'shipment_delivered',
			'shipment_delivered_without_tracking',
			'shipment_feedback',
		
			'staff_orders_need_packing',
			'staff_order_warning',
			'staff_disputed_charge',
			'staff_sales_summary',
			'staff_financial_summary'

		].indexOf(this.name)
	}

	get settingsToCapture(): {[key:string]:string[] } | null {

		let settings = {};

		switch (this.name) {

			case 'post_order_accomplished':
			case 'post_shipment_accomplished':
				settings['timeframe'] = [0,3,5,7,14];
				break;

			case 'staff_orders_need_packing':
				settings['timeframe'] = ['instantly','daily'];
				break;
			case 'staff_new_member':
				settings['timeframe'] = ['instantly','daily','weekly'];
				break;
			case 'staff_sales_summary':
				settings['timeframes'] = ['daily','weekly','monthly','quarterly'];
				break;
			case 'staff_financial_summary':
				settings['timeframes'] = ['daily','weekly','monthly','quarterly'];
				break;

			case 'successful_payment':
			case 'declined_payment':
			case 'refunded_payment':
				settings['scope'] = ['customers','only_members']
				break;

			case 'no_order_recorded':
				settings['scope'] = ['all_customers','only_members']
				settings['timeframe'] = [90,180,365,540];
				break;

			case 'bounced_email':
				settings['scope'] = ['all_contacts', 'only_customers','only_members']
				settings['timeframes'] = ['instantly',7,14,21];
				break;

			case 'cc_expiry':
				settings['timeframes'] = [3,7,10,14,21];
				break;

			case 'post_order': case 'post_shipment':
			case 'drip_2': case 'drip_3': case 'drip_4': case 'drip_5': case 'drip_6': case 'drip_7': case 'drip_8':
				settings['timeframe'] = [3,7,10,14,21];
				break;

			case 'customer_birthday':
				settings['timeframe'] = [0,3,7,10,14,21];
				settings['scope'] = ['all_contacts', 'only_customers','only_members']
				break;
		}

		return Object.keys(settings).length == 0 ? null : settings;
	}


	public illegalVariablesFound(): string[] {

		// Due to us having changed the default variables over time
		// and not correctly updating all company comms templates to use
		// the new variables, we cannot currently check for illegal variables
		// because every old variable no longer available is considered
		// 'illegal' and stops companies from saving their templates
		const allowedTags = this.getTags()
		let illegalVars = [];
		let variables = this.body.match(/\*\|(.*?)\|\*/g) || []; // Regex to match all of our *|variables|*
		variables.forEach((v:string) => {
			if (!allowedTags.includes(v.slice(2, -2))) {
				illegalVars.push(v);
			}
		});

		return illegalVars;
  }
}
