import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environment/environment';
import { ITrolySocketChannelJob, ITrolySocketJobUpdate, ITrolySocketMessage } from '../../models/form_objects';

import * as ActionCable from '@rails/actioncable';
import * as CryptoJS from "crypto-js";
import { User } from '../../models/troly/user.model';
import { uuid } from '../../models/utils.models';
import { AuthService } from '../auth.service';
import { CompanyService } from './company.service';

@Injectable({
	providedIn: 'root'
})

export class WebsocketService implements OnDestroy {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'WebsocketService';

	private wsBaseUrl: string;
	private cable: ActionCable.Connection;

	protected notifiers: { [key: string]: Subject<ITrolySocketMessage> } = {}
	protected channels: { [key: string]: ActionCable.Subscriptions } = {}

	protected companyService:CompanyService = inject(CompanyService)
	protected authService: AuthService = inject(AuthService)

	constructor() {

		let user: User = JSON.parse(window.localStorage.getItem('us')) || {}

		let trolyApiBaseUrl = environment.TROLY_API_URL;

		this.wsBaseUrl = trolyApiBaseUrl.replace(/^http/, 'ws').replace(/\/$/, '') + `/cable/${user.authentication_token}` // supports https => wss, reading the suer auth token which is always stored after login. this needs to be adjusted for the service to work in a "unauthenticated" mode.

		this.status = 'pending';

	}


	public status: string;

	protected tokens = {};

	/**
	 * 
	 * @param company_id 
	 */
	public connectCompanyChannel(company_id: uuid): Subject<ITrolySocketMessage> {
		if (!company_id) { throw `${this.__name}.connectCompanyChannel invalid company_id (${company_id})` }
		const channel = CryptoJS.MD5(`troly:${company_id}:company`).toString();
		return this.connect(channel, 'CompanyChannel');
	}

	/**
	 * 
	 * @param email 
	 * @returns 
	 */
	public connectUserChannel(email: string): Subject<ITrolySocketMessage> {
		if (!email) { throw `${this.__name}.connectUserChannel invalid email (${email})` }
		const channel = CryptoJS.MD5(`troly:${email}:user`).toString();
		return this.connect(channel, 'UserChannel');
	}
	protected userChannel: ActionCable.Subscription;

	/**
	 * 
	 * @param channel 
	 * @param scope 
	 * @returns 
	 */
	public connect(channel: string, scope: string): Subject<ITrolySocketMessage> {

		if (!this.notifiers[channel] && this.init()) {

			this.notifiers[channel] = new Subject<ITrolySocketMessage>();

			if (scope == 'UserChannel') {
				if (this.userChannel) {
					throw "UserChannel already exists cannot connect multiple user channels"
				}
				this.userChannel = this.cable.subscriptions.create({ channel: scope, uuid: channel });
			} else {
				this.cable.subscriptions.create({ channel: scope, uuid: channel });
			}

		}
		return this.notifiers[channel];
	}

	public send(message, channel) {
		if (this.channels[channel] && this.cable.readyState == WebSocket.OPEN) {
			this.channels[channel].send(JSON.stringify(message));
		}
	}

	private init(): boolean {

		if (this.cable == null) {

			//ActionCable.startDebugging();
			this.cable = ActionCable.createConsumer(this.wsBaseUrl);

			this.cable.connection.open();

			this.cable.connection.webSocket.addEventListener('message', (_json) => { this.receiveMessage(_json) });
			this.cable.connection.webSocket.addEventListener('close', () => { this.close(); });

		} else if (this.cable.connection.disconnected) {
			this.cable.connection.open();
		}
		return this.cable != null;

	}

	/**
	 * Close any channel or all, and terminates the cable connection when no more channels are subscribed to.
	 * @param channel 
	 */
	close(channel?: string) {

		if (this.status == 'connecting') { return }

		if (!channel && Object.keys(this.channels).length) {

			// close all channels
			Object.keys(this.channels).forEach((_channel) => {
				this.close(_channel);
			});

		} else {

			// close a single channel
			if (channel && this.channels[channel]) {
				if (this.channels[channel].consumer.connection.isOpen()) {
					this.channels[channel].close();
				}
				this.channels[channel].subscriptions.remove();
				delete this.channels[channel]
			}

			// if this was the last close the connection
			if (Object.keys(this.channels).length == 0) {
				if (this.userChannel && this.userChannel.consumer.connection.isOpen()) {
					this.userChannel.close(); 
				}
				this.status = 'disconnected'
			}

		}
	}

	/**
	 * 
	 * @param _json 
	 */
	receiveMessage(_json: any) {
		const msg = JSON.parse(_json.data);
		//this.status.next(this.cable.getState());
		//https://docs.anycable.io/misc/action_cable_protocol
		switch (msg.type) {
			case 'welcome':
				this.status = 'connected';
				break;
			case 'disconnect':
				this.status = 'disconnected';
				if (msg.reconnect) { 
					console.log('api requested reconnect', msg)
					//window.location.reload(); 
				}
				if (!msg.reconnect) { this.authService.logout(msg.reason) }
				
				break;
			case 'confirm_subscription':
				break;
			case 'reject_subscription':
				break;
			case 'ping':
				this.status = 'ping'
				setTimeout(() => { this.status = 'connected'; }, 500);
				if (this.userChannel) {
					this.userChannel.send({ action: 'pong' });
					console.log('pong -- waiting 60 second for next ping')
					if (this._currentTimeoutTimer) { clearTimeout(this._currentTimeoutTimer); }
					this._currentTimeoutTimer = setTimeout(() => { 
						console.log('ping Timeout Reached -- reloading page')
						//window.location.reload(); 
					}, 60*1000)
				}
				break;

			default:
				let identifier = JSON.parse(msg.identifier)
				this.notifiers[identifier.uuid].next(msg.message); // this replaces the need to have each subscriptions.create to handle incoming messages
				break;
		}
	}
	private _currentTimeoutTimer;

	ngOnDestroy(): void {
		this.cable.close();
	}

	public subscribeSocketJob<T>(observable: Observable<ITrolySocketChannelJob>): Observable<ITrolySocketJobUpdate> {

		return new Observable<ITrolySocketJobUpdate>(jobObserver => {
			observable.subscribe((_: ITrolySocketChannelJob) => {

				jobObserver.next({ // sending out an indicator that the channel has been received and the job is now queued
					message: "sockets.queued", type: 'queued'
				} as ITrolySocketJobUpdate); 

				this.connect(_.channel, 'CompanyChannel').subscribe({
					next(msg:ITrolySocketMessage) {

						switch (msg.operation) {
							case 'end':
								jobObserver.next(msg.data as ITrolySocketJobUpdate);
								jobObserver.complete();
								break;
							case 'start':
							case 'progress':
							case 'result':
							case 'error':
								jobObserver.next(msg.data as ITrolySocketJobUpdate);
								break;
							default:
								console.error('Received socket message that was not handled:')
								console.error(_)
						}
					},

					error(err) {
						jobObserver.error(err)
					}

				});

			});
		});
	}
}
