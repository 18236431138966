import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";

/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class PaymentCard extends TrolyObject {

	/** When known, the name of the card provider (also known as "Issuing Bank") */
	declare provider: string;

	/** The last 4 digits for the card */
	declare last4: string;

	/** The affiliated network this card was issued under (Eg. visa, master, amex) */
	declare brand: string;

	/** Last day where this card can be used (last day of the expiry month) */
	declare expiry: Date;
	_expiry: ValidatorFn[] = [Validators.required];

	/** Two-digit number representing the card's expiration month */
	declare exp_month: string;

	/** Two- or four-digit number representing the card's expiration year. */
	declare exp_year: string;

	/** The card number, as a string without any separators. empty when card has been tokenized/encrypted */
	declare number: string;
	_number: ValidatorFn[] = [Validators.required];

	/** Full cardholder name  */
	declare name: string;
	_name: ValidatorFn[] = [Validators.required];

	/** internal notes about this card -- when used, will prevent from being used in bulk */
	declare notes: string;

	declare tokens: { [key: string]: string };

	/** The desired currency to convert all payments to, as 3-digit currency code */
	declare currency: string

	/** Card security code. Removed once card was tokenized, and can be manually entered to improve acceptance for over-the-phone transactions  */
	declare cvc: string;
	_cvc: ValidatorFn[] = [Validators.required];

	/** (optional) Billing Address line 1 (Street address / PO Box / Company name) */
	declare address_line1: string
	/** (optional) Billing Address line 2 (Apartment / Suite / Unit / Building) */
	declare address_line2: string
	/** (optional) Billing City / District / Suburb / Town / Village */
	declare address_city: string
	/** (optional) Billing State / County / Province / Region */
	declare address_state: string
	/** (optional) Billing ZIP or postal code */
	declare address_zip: string
	/** (optional) Billing address country, if provided. */
	declare address_country: string

	/** Date at which this card was last used to process a (successul) payment */
	declare last_used_at: Date;

	/** Total order value for the last order paid with this card */
	declare last_used_amount: number;

	constructor(values?: Object) {
		super('payment_card', values);
	}

	public before(other: PaymentCard, asc = true): number {
		return parseInt(this.exp_year) < parseInt(other.exp_year) || (parseInt(this.exp_year) == parseInt(other.exp_year) && parseInt(this.exp_month) < parseInt(other.exp_month)) ? (asc ? -1 : 1) : (asc ? 1 : -1)
	}

	get weeksToExpiry(): number {
		return 99;
	}

	get expired(): boolean {
		const now = new Date()
		return parseInt(this.exp_year) < now.getFullYear() || (parseInt(this.exp_year) == now.getFullYear() && parseInt(this.exp_month) < now.getMonth())
	}

	public toString() : string {
		return `${this.brand} •••• ${this.last4}`
	}

	public get tokenProviders(): string[] {
		return [];Object.keys(this.tokens || {}) || []
	}
}
