<div class="row">
    <div class="col-4">
        <h5>Due</h5>
        <ng-container *ngIf="rounding != 0">
            <h3 class="mb-0">{{ payable | currency }}</h3>
            <small class="d-block text-uppercase text-secondary text-center">rounding: {{ rounding | currency }}</small>
        </ng-container>
        <ng-container *ngIf="rounding == 0">
            <h3>{{ payable | currency }}</h3>
        </ng-container>
        
    </div>
    <div class="col-4">
        <h5>Received</h5>
        <h3>{{ tendered | currency }}</h3>
    </div>
    <div class="col-4">
        <h5>Change</h5>
        <h3 *ngIf="payable > 0 && (tendered > 0 && owed > 0)">{{ owed | currency }}</h3>
        <h3 *ngIf="payable == 0 || (tendered > 0 && owed == 0)"><i class="align-middle bx bxs-minus-circle text-light"></i></h3>
        <h3 *ngIf="payable > 0 && (tendered <= 0 || owed < 0)"><i class="align-middle bx bxs-error-circle text-danger"></i></h3>
    </div>
    <!--div class="col-12 mt-2">
        <mat-form-field>
            <input matInput type="number" placeholder="0" />
        </mat-form-field>
    </div-->
</div>
<div class="row">
    <div class="col-4" *ngFor="let i of acceptedKeys">
        <button [disabled]="payable < 0 || enteringDecimals > decimalsCount" type="button" (click)="addDigit(i)" class="btn btn-outline-secondary">{{ i }}</button>
    </div>
    <div class="col-4"><button [disabled]="payable <= 0 || tendered <= 0" type="button" (click)="removeDigit()" class="btn btn-success"><i class="mdi mdi-backspace"></i></button></div>
</div>