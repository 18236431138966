import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard  {

	protected authService:AuthService = inject(AuthService);
	constructor(private router: Router, private route: ActivatedRoute) {}

	// angular definition to lock routes that require authentication
	// returns either true or false depending on if they are authenticated
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

		if (this.authService.isAuthenticatedClientSide()) { 
			// AuthGuard is called when routing to a page that requires authentication, not when loading fragments and compoents, but really a **route**
			// this means between navigation within pages we really don't need to check for authentication AS LONG as we have the data required to talk the the server
			// when the server indicates that any call is unauthorized, we clear that and redirected to the login page
			return Promise.resolve(true); 
		}

		// see https://stackoverflow.com/questions/62995563/how-to-wait-for-server-authorization-in-an-angular-role-guard
		// check if the user is authenticated already
		return this.authService.confirmAccess().then((_)=> {
			if (!_) {
				this.recordDesiredRoute();
				this.authService.logout();
			} else {

					//this.authService.authenticatedUser$.pipe(filter(_ => !!_), take(1)).subscribe((_user) => {
						/*LogRocket.identify(_user.id.toString(), {
							name: _user.name,
							email: _user.email,
							userID: _user.id,
							// Add your own custom user variables here, ie:
							subscriptionType: 'pro'
							});*/
							//return true;
					//})

					return true;
			}
		}, (_) => {  return false;  }); 
	}

	private recordDesiredRoute() {

	}
}
// Compare this snippet from src/app/core/services/auth.service.ts: