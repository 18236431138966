import { NgModule } from '@angular/core';

import { TrolyMaterialModule } from '../shared/material.module';

import { TrolyAngularModule } from '../shared/angular.module';
import { TrolySharedModule } from '../shared/shared.module';
import { OrderEditModal } from '../shared/ui/orders/order-edit/order-edit.modal';
import { CreateCustomerModal } from '../shared/ui/quick-create/customer/create-customer.modal';
import { CreateEmailModal } from '../shared/ui/quick-create/email/create-email.modal';
import { CreateIntegrationModal } from '../shared/ui/quick-create/integration/create-integration.modal';
import { CreateInteractionModal } from '../shared/ui/quick-create/interaction/create-interaction.modal';
import { CreateProductModal } from '../shared/ui/quick-create/product/create-product.modal';
import { CreateSmsModal } from '../shared/ui/quick-create/sms/create-sms.modal';
import { CreateTaskModal } from '../shared/ui/quick-create/task/create-task.modal';
import { AdminLayout } from './admin.layout';
import { FooterComponent } from './footer/footer.component';
import { PublicLayout } from './public.layout';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';


@NgModule({

	declarations: [

		PublicLayout,
		AdminLayout,

		SidebarComponent,
		TopbarComponent,
		FooterComponent,
		
		//SearchDropdownComponent, 
		//CreateCustomerCard,
		//CreateProductComponent, // used in the topbar
	],
	imports: [

		//CommonModule, // required for ngSwitch in Layouts

		//RouterModule.forChild([]),

		TrolyAngularModule,
		TrolyMaterialModule,
		TrolySharedModule,

		CreateCustomerModal,
		CreateProductModal,
		CreateSmsModal,
		CreateEmailModal,
		CreateIntegrationModal,
		CreateInteractionModal,
		CreateTaskModal,
		OrderEditModal,
	],
	exports: [
		PublicLayout, 
		AdminLayout
	],
})
export class LayoutsModule { }
