<h4 class="p-3" style="font-weight: 800; color: var(--bs-gray-300)">{{ record?.business_name || 'Getting Started' }}&nbsp;</h4>

<div class="card auto-height-flash-card">
		<div class="{{ bsCssClassPrefix }}-header">
			<div
					class="header-background bg-secondary bg-soft"
					style="
						background-image: url('https://res.cloudinary.com/subscribility-p/image/upload/q_90/cdn/static/app.troly.io/welcome-widget/summer.jpg');
					"
			></div>
			<div class="header-background bg-secondary bg-soft" [style.background-image]="'url(' + record.hero_img?.url + ')'"></div>
		</div>
		<!-- card-header -->

		<div class="{{ bsCssClassPrefix }}-body pt-3" [ngClass]="{'contains-avatar':record.logo_img?.url}">
			<div class="row">
					<div class="col-12">
						<div class="avatar-lg">
							<img src="{{ record.logo_img?.thumbnail.url }}" alt="" class="rounded-circle" />
						</div>
					</div>
			</div>

			<div class="row">

				<div class="col-12">
					<h4>Not Found</h4>
					<p>The link followed is invalid.</p>
				</div>
				
				
			</div>
		</div>
</div>
<!-- card -->