
import { Club, IClubSegment } from 'src/app/core/models/troly/club.model';
import { CdnMountedImage, TrolyObject } from '../troly_object';
import { uuid } from "../utils.models";
import { Address } from "./address.model";
import { Carton } from "./carton.model";
import { CompanyTemplate } from "./company_template.model";
import { CompanyUser } from "./company_user.model";
import { Document } from "./document.model";
import { Integration, TrolyPaymentsIntegration, TrolyShippingIntegration } from "./integration.model";
import { PaymentCard } from './payment_card.model';
import { Product } from './product.model';
import { ShippingRule } from "./shipping_rule.model";
import { DemographicStat, PipelineStat, PipelineStatSample, SalesStat, StockStat, StockStatSample, SystemStat } from "./stats.model";
import { CompanyTag, Tag } from "./tag.model";
import { Task } from './task.model';
import { User } from "./user.model";
import { Warehouse } from "./warehouse.model";


/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Company extends TrolyObject {

	/** 
	 * Business Information 
	 */

	/** Full company name as registered legally in the country identified by `alpha2` and/or `state` */
	declare legal_name: string;
	/** Short compay name or "Brand name", or "doing business as", "trading as", etc.  */
	declare business_name: string;

	/** Short description for the company. Normally 2-3 sentences short paragraph */
	declare description: string;
	/** Legal company registration number in the country identified by `alpha2` or `state` */
	declare number: string;

	/** Country identifier, alpha2 as per ISO3166 */
	declare alpha2: string;
	/** Currency identifier, as a 3-letter code, uppercase */
	declare currency: string;

	declare lat: number
	declare lng: number
	declare timezone: number

	declare country: {}

	/** Company logo, as uploaded to CDN and with pre-transformations */
	declare logo_img?: CdnMountedImage // this is a mounted uploader and could be nil, or an object with urls and additional info
	/** Hero image is a larger company photo used as branding element */
	declare hero_img?: CdnMountedImage

	/** Contains a url to the company hero image, OR a seasonal/production specific system defined image */
	declare hero_img_url: string

	declare website_url: string
	declare phone: string
	declare phone_alt: string
	declare mobile: string
	declare email: string

	declare hero_qr_code?: CdnMountedImage;
	declare feedback_url_override: string

	/**
	 * A list of generated documents for this product
	 */
	declare documents: Document[];

	/**
	 * Addresses, Cards, Billing and Shipping
	 */
	declare cards: PaymentCard[]
	declare primary_card_id: uuid
	declare backup_card_id: uuid
	declare addresses: Address[]
	declare primary_address_id: uuid
	declare billing_address_id: uuid
	declare packaging: Carton[];
	declare orders_warehouse_id: uuid;
	declare shipments_warehouse_id: uuid;

	/** Current plan for this company */
	declare plan: 'starter' | 'pro' | 'grow'

	declare plan_cost_min_usage_fee: number
	declare plan_cost_max_usage_fee: number
	declare plan_cost_usage_threshold: number
	declare plan_cost_support_incident_fee_included: number
	declare plan_cost_support_incident_fee_over: number
	declare plan_cost_commitment_fee: number;
	
	declare term_renewal_date: Date
	declare term_renewal_plan: 'starter' | 'pro' | 'grow'
	declare term_commitment_level: '1.month'| '6.months' | '1.year'
	declare term_renewal_commitment_level: '1.month'| '6.months' | '1.year'

	/** A list of partners */
	declare affiliated_companies: Company[]
	declare subscription?: BillingPeriod;

	declare is_locked: boolean;
	declare is_test_mode: boolean;
	declare is_beta_mode: boolean

	declare is_integration_locked: boolean;
	declare is_unlocked_premium: boolean;

	declare unlocked_premium_at: Date;
	declare unlocked_premium_by_id: uuid;

	declare last_invoiced_at: Date;
	declare last_charge_attempted_by_id: uuid


	/**
	 * General Account Characteristics
	 */
	declare annual_revenue: string
	declare primary_variety: string
	declare problem_at_signup: string

	/** ID of the associated GUEST customer */
	declare guest_customer_id: uuid;
	declare referral_code: string;
	declare referral_qr_code?: { url: string }
	declare referrals_recorded: ForwardLedger[];
	declare account_adjustments: ForwardLedger[];
	declare referral_available: number;
	declare referral_by_code: string;
	declare referral_by_company_name: number;

	declare referral_pending_invites: [];
	declare referral_sent_invites: [];

	declare payment_last_payment_date:Date;
	declare payment_last_payment_status:string;
	declare payment_total_payments_made:number;

	/** 
	 * Company Virtual Attributes 
	 */

	declare merchant_linkedin_url: string
	declare merchant_pinterest_url: string
	declare merchant_facebook_url: string
	declare merchant_twitter_url: string
	declare merchant_instagram_url: string
	declare merchant_tiktok_url: string
	declare merchant_youtube_url: string
	declare merchant_reddit_url: string
	declare merchant_snapchat_url: string
	declare merchant_googlemaps_url: string
	declare merchant_liquor_licence_no: string
	declare merchant_privacy_policy_url: string
	declare merchant_terms_and_conditions_url: string
	declare merchant_volume_pricing_allows_mixed_product:boolean;
	
	declare refund_order_limit: number;
	declare refund_timeframe: number;
	declare refund_lock_timeframe: number;
	declare refund_daily_limit: number;
	
	declare payment_scheduling: string
	/** Currency in which this company is transacting */
	declare payment_currency: string
	declare payment_payout_notifications: string
	declare payment_preferred_gateway: uuid
	declare payment_club_gateway: uuid
	
	declare strategy_dtc_ecommerce: number
	declare strategy_dtc_onpremise: number
	declare strategy_dtc_subscription_sales: number
	declare strategy_dtc_events: number
	declare strategy_dtc_influencer: number
	declare strategy_dtc_packages: number
	declare strategy_dtc_third_party: number
	declare strategy_dtc_auctions: number
	declare strategy_dtc_bulk: number
	declare strategy_retail_specialty: number
	declare strategy_retail_small: number
	declare strategy_retail_large: number
	declare strategy_retail_restaurants: number
	declare strategy_retail_restaurants_luxury: number
	declare strategy_trade_auctions: number
	declare strategy_trade_bulk: number
	declare strategy_trade_distributors: number
	declare strategy_trade_contracts: number
	declare strategy_trade_association: number
	declare strategy_export: number
	declare strategy_national: number
	
	declare shipping_carrier_pref: string
	declare shipping_service_pref: string
	declare shipping_signature_pref: string
	declare shipping_insurance_pref: string
	declare shipping_policy_url:string
	
	declare tax_inclusive: boolean
	declare is_metric_units: boolean

	declare track_source: string
	declare track_partner: string
	declare track_channel: string
	declare track_campaign: string

	/**
	 * Troly Branding Addon
	 */
	declare branding_marketing_messages: string[]
	declare branding_email_signature: string
	declare branding_email_template_url: string
	declare branding_colour_one: string;
	declare branding_colour_two: string;
	declare branding_colour_three: string;
	declare branding_media_contact_name: string;
	declare branding_media_contact_email: string
	declare branding_media_contact_phone: string;
	declare branding_media_kit_url: string;

	/**
	 * Troly Referrals Addon
	 */
	declare referrals_reward_amount: number;
	declare referrals_reward_unit: string;
	declare referrals_reward_expiry: number;
	declare referrals_affiliate_amount: number;
	declare referrals_affiliate_unit: string;
	declare referrals_affiliate_expiry: number;

	/**
	 * Model Relationships
	 */
	declare shipping_rules?: ShippingRule[];
	declare integrations?: Integration[]; // not having a default value allows detecting the data was not loaded..
	declare warehouses?: Warehouse[];
	declare clubs?: Club[];
	declare tags?: Tag[];
	declare tasks?: Task[];
	declare opportunities?: Task[];
	declare company_tags?: CompanyTag[];
	declare users?: User[];
	declare company_users?: CompanyUser[];

	declare templates?: CompanyTemplate[];


	/**
	 * The overall sales stats for the whole company, across all memberships types, all warehouses, all integrations, etc. Should be 18 months max
	 */
	declare orders_stats: SalesStat[];
	declare orders_stat: SalesStat;
	declare warehouses_stats: StockStat[]
	declare warehouses_stat: StockStat
	declare customers_stats: DemographicStat[]
	declare customers_stat: DemographicStat
	declare integrations_stats: SystemStat[]
	declare integrations_stat: SystemStat[]

	declare stat: PipelineStat;
	declare stats: PipelineStat[];

	/**
	 * Contains products associated with this Company. In most cases, we handle products through the product Service (which maps it's own endpoint)
	 * however in the case of signup/onboarding, we leverage the ability for the signup/:id/company endpoint to save and return a company object WITH products.
	 * If this becomes a hassle or overtly inconsistent -- we should consider injecting the product service in the signup service and handle products through that.
	 */
	declare products: Product[];


	/** 
	 * DEMO DATA Management
	 */
	_orders_statsDefault: SalesStat[] = [
		new SalesStat({ starts_at: "2023-04-01T00:00:00.000Z", collect_count: 22, collect_total_value: 3520, customers_sales_count: 34, customers_sales_value: 5928, members_sales: 13, members_sales_value: 2925, shipments_count: 43, shipments_total_value: 8271, orders_direct_value: 10943, orders_indirect_value: 28976, completed_avg_value: 99.22, orders_offline_value: 10943, orders_online_value: 78943 }),
		new SalesStat({ starts_at: "2023-01-01T00:00:00.000Z", collect_count: 20, collect_total_value: 4183, customers_sales_count: 24, customers_sales_value: 8374, members_sales: 18, members_sales_value: 4594, shipments_count: 22, shipments_total_value: 2773, orders_direct_value: 23765, orders_indirect_value: 30513, completed_avg_value: 101.69, orders_offline_value: 23765, orders_online_value: 81765 }),
		new SalesStat({ starts_at: "2022-10-01T00:00:00.000Z", collect_count: 18, collect_total_value: 3928, customers_sales_count: 21, customers_sales_value: 7882, members_sales: 26, members_sales_value: 6483, shipments_count: 29, shipments_total_value: 12881, orders_direct_value: 17645, orders_indirect_value: 26455, completed_avg_value: 92.83, orders_offline_value: 17645, orders_online_value: 58645 }),
		new SalesStat({ starts_at: "2022-07-01T00:00:00.000Z", collect_count: 29, collect_total_value: 5629, customers_sales_count: 46, customers_sales_value: 9283, members_sales: 21, members_sales_value: 9473, shipments_count: 38, shipments_total_value: 10272, orders_direct_value: 16302, orders_indirect_value: 32919, completed_avg_value: 81.85, orders_offline_value: 16302, orders_online_value: 78302 })
	]

	_customers_statDefault: DemographicStat = {
		ltm_collected_sales_value: 827,
		ltm_shipped_sales_value: 728,
		ltm_sales_percentile: 50,
		ltm_membership_sales_value: 1120,

		sales_units: 48,
		sales_value: 1820
	} as DemographicStat

	_statDefault = new PipelineStatSample(0, 1120, 25, 43);
	_statsDefault: PipelineStat[] = [
		new PipelineStatSample(1, 847, 5, 30),
		new PipelineStatSample(2, 724, 10, 25),
		new PipelineStatSample(3, 584, 2, 30),
		new PipelineStatSample(4, 442, 1, 10),
		new PipelineStatSample(5, 624, 3, 30),
		new PipelineStatSample(6, 847, 30, 35),
		new PipelineStatSample(7, 986, 12, 13),
		new PipelineStatSample(8, 1178, 5, 15),
		new PipelineStatSample(9, 1023, 10, 25),
	]

	_warehouses_statsDefault: StockStat[] = [
		new StockStatSample(1, 847, 5, 30),
		new StockStatSample(2, 724, 10, 25)
	]

	constructor(values: Object={}) {

		super('company', values);

		if (values) {
			let k = 'packaging'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Carton(obj) });
			}

			k = 'users'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new User(obj) });
			}

			k = 'clubs'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Club(obj) });
			}

			k = 'integrations'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { 
					// arch: see arch: note in IntegrationService.make
					if (obj['provider'] == 'TrolyShipping') { this[k][i] = new TrolyShippingIntegration(obj) }
					else if (obj['provider'] == 'TrolyPayments') { this[k][i] = new TrolyPaymentsIntegration(obj) }
					else { this[k][i] = new Integration(obj) }
				});
			}

			k = 'warehouses' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Warehouse(obj) });
			}

			k = 'opportunities' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Task(obj) });
			}

			k = 'products' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Product(obj) });
			}

			k = 'templates' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new CompanyTemplate(obj) });
			}

			k = 'shipping_rules' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ShippingRule(obj) });
			}

			k = 'affiliated_companies' 
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Company(obj) });
			}

			k='subscription'
			if (values[k]) { this[k] = new BillingPeriod(values[k]); }

			k='account_adjustments'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ForwardLedger(obj) });
			}
		}
	}

	public toString(): string {
		return this.business_name ? this.business_name : this.legal_name;
	}

	public get initials(): string {
		return this.business_name?.toUpperCase().split(" ").map(word => word[0]).join("");
	}
	
	/**
	 * Convenience method to locate a User in the list of CompanyUsers loaded in `.users`
	 * @param id 
	 * @returns 
	 */
	public user(id:uuid): User | null {
		return this.users?.find(_ => _.id == id) || null
	}

	public get confirmedUsers(): User[] {
		return this.users?.filter(_ => _.current_sign_in_ip) || []; // any users who has never signed in is excluded -- this include the connected platforms too.
	}

	/**
	 * Used to merge together the current users active with this company, along with any old user that were involved with a record (eg. order processing)
	 * @param previous: User[] array of users attached to a record 
	 * @returns 
	 */
	public currentOrPreviousUsers(previous:User[]) {
		return (this.users || []).concat(previous)
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public club(id:uuid): Club | null {
		return this.clubs?.find(_ => _.id == id)
	}

	public get club_ids(): uuid[] { return this.clubs?.map(_ => _.id) || [] }

	public getClubSegments(club_id?: uuid): IClubSegment[] {
		if (this.club(club_id)) {
			return this.club(club_id).segments || []
		}
		return []
	}

	public template(id:uuid|string, channel:string='email'): CompanyTemplate {
		if (id.match(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)) {
			return this.templates?.find(_ => _.id == id) || null
		} else {
			return this.templates?.find(_ => _.name == id && _.channel == channel) || null
		}
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public addonInstalled(provider_or_id: string): Integration | null {
		return this.addonsInstalled([provider_or_id])[0] || null;
	}
	/**
	 * 
	 * @param providers 
	 * @returns 
	 */
	public addonsInstalled(providers_or_ids?: string[]): Integration[] {
		return this.integrations?.filter(_ => (!providers_or_ids || providers_or_ids.length == 0 || providers_or_ids.includes(_.provider) || providers_or_ids.includes(_.id?.toString())) && _.id && _.status && _.status != 'uninstalled') || [];
	}

	/**
	 * Retrieves a unique list of integration tags.
	 * This method iterates over the integrations and collects their associated tags,
	 * ensuring that each tag is included only once in the returned array.
	 *
	 * @returns {string[]} An array of unique integration tags.
	 */
	public getIntegrationTags(): string[] {
		//return this.addonsInstalled().map(integration => integration.name)
		return this.addonsInstalled().reduce((tags, integration) => {
			return tags.concat(integration.tags.filter(tag => !tags.includes(tag)));
		}, []);
	}
	/**
	 * 
	 * @param provider_or_id 
	 * @returns 
	 */
	public addon(provider_or_id: string): Integration | null {
		let addons = this.addonsInstalled([provider_or_id]);
		return addons.length > 0 ? addons[0] : null;
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public warehouse(id): Warehouse | null {
		return this.warehouses?.find(_ => _.id == id) || null;
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public addonStatus(provider_or_id: string): string {
		let addons = this.addonsInstalled([provider_or_id]);
		return addons.length > 0 ? addons[0].status : 'uninstalled';
	}

	get offlinePaymentMethods(): string [] {
		return []
	}
	get onlinePaymentMethods(): string [] {
		return []
	}

	/**
	 * 
	 */
	public shippingIntegrations(provider?:string, statuses:string[]=['ready','warning']): Integration[] {
		return this.integrations ? this.integrations.filter(o => o.processor == 'Shipping' && (provider ? o.provider == provider : o.provider != 'TrolyShippingDocuments') && statuses.includes(o.status)) : [];
	}
	/**
	 * 
	 */
	installedIntegration(provider: string): Integration {
		if (this.integrations) {
			return this.integrations.find(_ => _.provider == provider)
		}
		return null;
	}

	/**
	 * 
	 */
	public paymentIntegrations(): Integration[] {
		return this.integrations ? this.integrations.filter(o => o.processor == 'Payment' && o.provider != 'TrolyOffline' && ['ready','warning'].includes(o.status)) : [];
	}

	/*private _formatter;
	currencyFormat(value: string, min: number = 0, max: number = 0): string {
		if (!this._formatter) {
			this._formatter = new Intl.NumberFormat(this.language_codes[0], {
				style: 'currency',
				currency: this.currency,
				minimumFractionDigits: min,
				maximumFractionDigits: max,
			})
		}

		return this._formatter.format(value);
	}*/

	get visit_feedback(): Document | null { return this.getDocument('visit_feedback','application/pdf') }
	
	protected getDocument(tag: string, media_type?: 'application/pdf'|'text/csv'): Document | null {
		if (this.documents) { return this.getDocuments(tag, media_type)[0] }
		return null;
	}

	protected getDocuments(tag: string, media_type?: 'application/pdf'|'text/csv'): Document[] | null {
		if (this.documents) {
			return this.documents
				//.sort((a, b) => b.created_at.getTime() - a.created_at.getTime()) // sort descending by date (latest first)
				.filter((_) => { return (_.tags.includes(tag)) && (!media_type || _.media_type == media_type) }) // filter on the doc type
		}
		return null;
	}

	/**
	 * Returns a list of shipping services for the given provider, or all if none given.
	 * @param provider
	 * @returns
	 */
	public getShippingServicesForProvider(provider:string): string[] {
		return this.integrations.find(_ => _.provider == provider)?.data['service_types'] || [];
	}

	assign_stats(value: DemographicStat): boolean {
		if (value.sales_value > 0) {
			this.customers_stat = value;
		} else {
			this.customers_stat = this._customers_statDefault;
		}
		return this.customers_stat != this._customers_statDefault;
	}

	toLocalStorage(action?: string): Company {
		action = action || 'logout';

		if (action == 'login') {
			//return this.cleanObjectProperties(new Company(this)) as Company;
			// !!! We have a problem with cleaning properties when storing in local storage, due to how variables are passed as references.
			// !!! this means the return object is also cleaned up, and the service._next object also is.
			// !!! ultimately, while a user is logged on, all underscore attributes should be maintained in order to have a consistent object state (eg isStale is maintained.)
			// !!! this was more obvious with company.integrations.translations.fields which uses _ to separate sections.
			return this;
		} else {
			return new Company({
				id: this.id,
				business_name: this.business_name,
				logo_img: this.logo_img,
				hero_img_url: this.hero_img_url,
				branding_colour_one: this.branding_colour_one
			});
		}
	}

	get referralDiscount(): number {
		const discounts = {
			AUS: 30,
			CAN: 30,
			NZL: 30,
			USA: 20,
			ITA: 20,
			ESP: 20,
			ZAF: 350,
		}
		return discounts[this.alpha2] * 12;
	}


	public isPickupWarehouse(location: Warehouse): boolean {
		return this.orders_warehouse_id && this.orders_warehouse_id == location.id
	}
	public isShippingWarehouse(location: Warehouse): boolean {
		return this.shipments_warehouse_id && this.shipments_warehouse_id == location.id
	}


	public get partnerName(): string | null {
		if (Object.keys(this.affiliated_companies || {}).length == 1) {
			return Object.keys(this.affiliated_companies || {})[0]
		}
	}
	public get partnerLogoUrl():string {
		if (Object.keys(this.affiliated_companies || {}).length == 1) {
			const partner = Object.keys(this.affiliated_companies)[0]
			switch (partner) {
				case 'Outshinery':
					//return 'https://assets-global.website-files.com/61fa355c7ae67c2c0d81a4ec/61fd08492e9386eab9277cfd_Outshinery-logo-original-inline.svg';
					return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSck9OyI9yhryihC4ea-JYu5rwEOgBw1cL6l_ScoEvYK289OVLjGCHODZBWeXf0wqqK-Hg&usqp=CAU';
				case 'Vivino':
					return 'https://images.vivino.com/web/press/logo_white_on_red.png';
			}
		}
		return 'https://res.cloudinary.com/subscribility-p/image/upload/a_2,w_100,h_100/troly-logo-circle-transparent-bg.png'
	}

	public hasCurrentAdjustmentByCode(code:string): ForwardLedger {
		const now = new Date()
		return this.account_adjustments.find(_ => _.code == code && _.starts_at <= now && now <= _.ends_at)
	}

	public patchableValues() {
		let result = super.patchableValues()
		
		// when patching a value to a form, transform the string stored (hex) into a Color object ready for the colour picker
		//
		if (result['branding_colour_one']) { result['branding_colour_one'] = this.toColor(result['branding_colour_one']) }
		if (result['branding_colour_two']) { result['branding_colour_two'] = this.toColor(result['branding_colour_two']) }
		
		return result
	}

	public beforeSave(originalObject:Company): boolean {
		
		// ensure we have a string (not a Colour object coming back from the picker)
		if (this.branding_colour_one) { this.branding_colour_one = this.branding_colour_one.toString() }
		if (this.branding_colour_two) { this.branding_colour_two = this.branding_colour_two.toString() }
		
		return super.beforeSave(originalObject);
	}
}

export class ForwardLedger extends TrolyObject { 
	
	declare id: uuid;
	declare code:string
	declare description:string
	
	declare starts_at:Date 
	declare ends_at:Date
				
	declare funds_monthly:number 
	declare funds_total:number

	declare fees_monthly:number
	declare fees_yearly:number

	declare status: string;

	constructor(values?:{}) {
		super('forward_ledger', values);
	}
}

export interface IReferral {
	id: uuid
	fname: string
	email: string
	mobile: string
}

export class BillingPeriod extends TrolyObject {
	
	declare starts_at:Date
	declare starts_at_plan:string
	declare ends_at:Date
	declare ends_at_plan:string

	declare previous_bp_id:uuid
	declare next_bp_id:uuid
	
	declare status: string;
	declare invoice_id: uuid;
	
	declare integrations?: Integration[]

	declare plan_value:number
	declare plan_id:uuid

	declare usage_fees_ledger_ids: uuid[] // Troly platform usage fees
	declare usage_fees_ol_id: uuid;
	declare usage_fees_value:number

	declare sales_comissions_ledger_ids:uuid[] // These are sales commissions for new sales generated
	declare sales_commisions_ol_id:uuid
	declare sales_commisions_value:number

	declare adjustments_details:ForwardLedger[] // Any adjustments on this account, positive or negative
	declare adjustments_ol_id:uuid
	declare adjustments_value:number

	declare services_fees_ledger_ids:uuid[] // These are service sees based on marketing assets conducted
	declare services_fees_ol_id:uuid;
	declare services_fees_value:number;

	declare support_fees_ledger_ids:uuid[] // These are variable fees based on support, requested from Troly // any providers cost is passed via integration_fees or ser
	declare support_fees_ol_id:uuid;
	declare support_fees_value:number

	declare integrations_fees_ledger_ids:uuid[] // These are fixed fees based on each addon (premiume) and options
	declare integrations_fees_ol_id:uuid;
	declare integrations_fees_value:number

	
	constructor(values?:{}) {
		super('billing_period', values);

		if (values) {
			let k = 'integrations'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Integration(obj) });
			}
		}
	}
	public freeIntegrations(): Integration[] {
		return this.integrations?.filter(_ => !_['price']) || []
	}
	public paidIntegrations(): Integration[] {
		// integrations are loaded dynamically not automatically so could be unset
		return this.integrations?.filter(_ => _['price']) || []
	}
	public accountAdjustments(): ForwardLedger[] {
		return this.adjustments_details.filter(_ => _.fees_monthly != 0 )
	}
	public referalRewards(): ForwardLedger[] {
		return this.adjustments_details.filter(_ => _.funds_monthly != 0 )
	}
}