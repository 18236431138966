import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/core/components/base.component';
import { TrolyFormGroup } from 'src/app/core/models/troly_form';
import { TrolyObject } from 'src/app/core/models/troly_object';

@Component({
  selector: 'debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent extends BaseComponent {
	
	@Input('color') color:string = 'orange';
	@Input('object') record:TrolyObject;
	@Input('form') form:TrolyFormGroup;

	@Input('mode') mode:'simple'='simple';

	@Input('class') class:string = '';

	@Input('url') url:string = '';

	@Input('attributes') attributes:string[] = []; 

	showFormDetails:boolean = false;

	isGroup(value:any):boolean {
		return value instanceof FormGroup || value instanceof TrolyFormGroup;
	}
	isArray(value:any):boolean {
		return value instanceof FormArray;
	}
}