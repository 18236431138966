
import { Component } from "@angular/core";
import { TrolyComponent } from "./troly.component";

@Component({
	template: ''
})
export class TrolyCard extends TrolyComponent {

	override readonly bsCssClassPrefix:string = 'card'


	private previousWindowTitle:string;
	/**
	 * When a card has 'focus' we can set the window title to reflect the card's title. this is not automatically reverted.
	 * see also `TrolyModal.setTemporaryWindowTitle`.
	 */
	protected setTemporaryWindowTitle(replacements:{}, translationKey?:string) {
		this.previousWindowTitle ||= this.titleService.getTitle();
		translationKey = `Pages.Titles.${translationKey || this.__name}`;
		this.titleService.setTitle(this.translateService.instant(translationKey, replacements) + ' ― Troly');
	}

	protected revertWindowTitle() {
		this.titleService.setTitle(this.previousWindowTitle);
		this.previousWindowTitle = null;
	}

}