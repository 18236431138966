import { Component, EventEmitter, Input, Output, SimpleChanges, inject } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { environment } from '../../../../environment/environment';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { ITrolyLoadingStatus } from 'src/app/core/models/form_objects';
import { Address } from 'src/app/core/models/troly/address.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { SweetAlertOptions } from 'sweetalert2';


// intersection fields we check against to ensure we aren't displaying
// 2 of the same geocoded response
const possibleIntersectionFields = [
	'political',
	'postal_code',
	'establishment',
	'store',
	'premise',
	'subpremise',
	'locality',
	'sublocality',
	'street_address',
	'route', // For newer or more rural areas, a formatted address may return a "route" from Google
];

@Component({
	selector: 'geo-search-component',
	templateUrl: './geo-search.component.html',
	styleUrls: ['./geo-search.component.scss']
})
export class GeoSearchComponent extends TrolyComponent {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'GeoSearchComponent';

	public bsCssClassPrefix: string = 'card';

	_formFields = ['area'];
	_formDefauls = { area: '' }

	@Output() selectCallback = new EventEmitter();

	@Input() fieldName;
	@Input() fieldHint;
	@Input() disabled: boolean = false;
	@Input() line1;
	@Input() searchArea:string;

	@Input() fieldSize: string = '';

	searchApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
	searchApiKey = environment.GOOGLE_API_KEY;

	records: Address[] = null;
	totalRecords = 0;
	noResults = false;

	pageSize = 10;

	/**
	 * Holds the various loading statuses for the various parts needed to be loaded by the component.
	 * @see TrolyComponent.loading (Override)
	 */
	override loading: { troly: ITrolyLoadingStatus, record: ITrolyLoadingStatus, records: ITrolyLoadingStatus } = {troly:undefined, record:undefined, records:undefined};

	protected httpClient: HttpClient = inject(HttpClient);
	
	constructor() {
		super();

		this.record = new TrolyObject()
		this.initForm(true, true, 'change');

	}

	/// subscribe change notifiers and react to changes
	protected attachChangeNotifiers(): void {
		this.log(`${this.__name}.attachChangeNotifiers()`, 'STACK');

		this.form.get('area').valueChanges.pipe(
			filter((searchValue: String) => searchValue && searchValue.length > 2 && searchValue != this.line1),
			debounceTime(300),
			distinctUntilChanged(),
			takeUntil(this.observablesDestroy$)
		)
			.subscribe((searchValue) => {
				// if we were given street address then add that to the search
				if (this.line1) {
					this.searchLocation(this.line1 + ', ' + searchValue);
				} else {
					this.searchLocation(searchValue);
				}
			});

		this.form.get('area').valueChanges.pipe(filter(_ => _ == ''), debounceTime(500), takeUntil(this.observablesDestroy$)).subscribe((_) => {
			//this.selectCallback.emit({ searchArea: '' });
		})

	}

	receiveInputParams(changes: SimpleChanges) {

		if (this.form) {
			if (changes.disabled && changes.disabled.previousValue !== changes.disabled.currentValue) {
				if (changes.disabled.currentValue) {
					this.form.get('area').disable();
				} else {
					this.form.get('area').enable();
				}
			}

			if (changes.searchArea && changes.searchArea.previousValue !== changes.searchArea.currentValue) {
				this.form.get('area').setValue(changes.searchArea.currentValue);
			}
		}

		super.receiveInputParams(changes)
	}

	// Handles the searching of a given location (from users input)
	private searchLocation(searchValue) {
		// reset all our search fields to let the user know we are searching
		this.records = null;
		this.noResults = false;
		this.form.resetCodes();

		this.markAsLoading(this.form)

		let payload = {
			address: searchValue,
			region: this.selectedCompany.alpha2 || '',
			key: this.searchApiKey,
			language: navigator.language,
		};

		let options = {
			observe: 'response' as 'response',
			params: payload
		};

		this.httpClient.get(this.searchApiUrl, options).subscribe({
			next: (_resp:HttpResponse<any>) => {
				this.records = [];
				// if we received 0 results try to search again with country appended
				if (_resp.body.status == 'ZERO_RESULTS') {
					// we've tried both a regular search and search + country
					// so return no results found at this point
					this.noResults = true;
					this.totalRecords = 0;
					this.markAsLoaded(this.form)
				} else if (_resp.body.status == 'OK') {
					// we have results so process them
					this.processGeocodeResponse(_resp.body.results);
					this.markAsLoaded(this.form)

				} else {
					this.form.resetCodes({error: _resp.body.status}, _resp.body.error_message);
					this.markAsLoadingError(this.form);
				}
			},
			error: (_err) => { 
				this.form.resetCodes({error: _err.name})
				this.markAsLoadingError(this.form, _err.message); 
			}
		});
	}

	// handles all the processing of the geocoded response and
	// converts them into a form we can use for front-end display
	// and later back-end storage
	private processGeocodeResponse(results) {
		// set up a regex match for the street address so we
		// don't bother displaying that in the search result
		//const regex = new RegExp(`/^${this.address}, /`, 'i');

		let finalResults:Address[] = [];

		results.forEach((address) => {
			// Take the first 10 "political" results (filtering out things like natural features etc)
			// Sometimes google returns us repeated addresses with different region type, we only want to display the unique result
			if (
				finalResults.length < 10 &&
				address.types.some((addressType) => {
					return possibleIntersectionFields.includes(addressType);
				}) &&
				!finalResults.some((res) => res.formatted_address == address.formatted_address)
			) {
				let result:Address = new Address();

				result.setGoogleResponse = address

				// add our result to the final set to display to the user
				finalResults.push(result);
			}
		});

		this.records = finalResults;
		this.totalRecords = finalResults.length;
	}

	// handles returning the selected search option
	// this will reset our search status (back to no search done)
	// and set the value of the input to the selected result
	// as well as emit the response to the parent component for saving
	private returnSearchOption(result: Address) {
		// reset our searches state
		this.records = null;
		this.totalRecords = 0;

		// now that an option is set the box
		// we set our local area first to avoid triggering another search
		this.searchArea = `${result.line3}, ${result.line4}`;
		this.form.get('area').setValue(this.searchArea);
		//this.searchForm.get('area').markAsPristine();

		// this is how we actually return the response,
		// emitting it to the parent component
		this.selectCallback.emit(result);
	}

	public searchOptionSelected(result: Address) {
		if (result.line1 && this.line1 !== result.line1) {
			let options: SweetAlertOptions = this.alertService.alertOptions('question', 'yes', 'no');

			options.title = this.translateService.instant(`Dialogs.${this.__name}.title`);
			options.html = "<p>" + this.translateService.instant(`Dialogs.${this.__name}.message`, { entered: this.line1, validated:result.line1  }).replaceAll("\n\n","</p><p class=\"text-start ms-4\">") + "</p>"

			this.alertService.checkConfirmation(options).then((confirmed) => {
				if (confirmed) { this.line1 = result.line1; } 
				else { result.line1 = this.line1; }
				this.returnSearchOption(result)
			});
		} else {
			this.returnSearchOption(result)
		}
	}
}
