<span class="align-top badge" *ngIf="showBadge" [ngClass]="{
    'bg-secondary': status == 'connecting',
    'bg-success': status == 'connected',
    'bg-warning': status == 'errors',
    'bg-light': status == 'ping',
	 'text-white': status == 'ping',
    'bg-danger': status == 'disconnected'
}" ngbTooltip="{{ 'Sockets.StatusBadge.status.' + status | translate }}">
    <span *ngIf="status != 'disconnected'">{{ 'Sockets.StatusBadge.status.connected' | translate }}</span>
    <span *ngIf="status == 'disconnected'">{{ 'Sockets.StatusBadge.status.disconnected' | translate }}</span>
</span>