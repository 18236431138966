<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<div class="{{ bsCssClassPrefix }}-header">
		<h4 class="{{ bsCssClassPrefix }}-title" [innerHTML]="'Models.Document.Values.doc_type.' + record.doc_type | translate : { name:record.filename }"></h4>

		<button type="button" class="btn-close"
			(click)="resolveModal('x')"></button>
	</div>
	<div class="{{ bsCssClassPrefix }}-body pt-0">

		<div class="row">
			<div class="col-12">
				<iframe *ngIf="safeDocumentUrl" [src]="safeDocumentUrl" width="100%" [height]="iframeHeight"></iframe>
			</div>
			<div class="col-4">
				<p class="font-size-11">
					Created at <a href (click)="!!toggleFromNow();" class="text-primary font-size-11">
						<span *ngIf="!__fromNow">{{ moment(record.created_at).format(datetimeFull) }}</span>
						<span *ngIf="__fromNow">{{ moment(record.created_at).fromNow() }}</span>
					</a> <br/>
					Last updated at <a href (click)="!!toggleFromNow();" class="text-primary font-size-11">
						<span *ngIf="!__fromNow">{{ moment(record.updated_at).format(datetimeFull) }}</span>
						<span *ngIf="__fromNow">{{ moment(record.updated_at).fromNow() }}</span>
					</a>
				</p>
			</div>
			<div class="col-4">
				<p class="font-size-11 text-center" *ngVar="selectedCompany.user(record.user_id) as user">
					{{ user }}
				</p>
			</div>
			<div class="col-4 font-size-11 text-end">
				<a href class="ms-2" [ngClass]="{ 'fw-bolder' : iframeHeight == '400px' }" (click)="setHeight('400px')">small</a>
				<a href class="ms-2" [ngClass]="{ 'fw-bolder' : iframeHeight == '600px' }" (click)="setHeight('600px')">medium</a>
				<a href class="ms-2" [ngClass]="{ 'fw-bolder' : iframeHeight == '900px' }" (click)="setHeight('900px')">large</a>
			</div>
		</div>
	</div>
</div>