import { Component, ViewChildren, inject } from "@angular/core";
import { BehaviorSubject, filter, takeUntil } from "rxjs";
import { SortEvent, SortableDirective } from "../../shared/ui/sortable.directive";
import { ITrolyLoadingStatus, ITrolySocketMessage, TrolySearch } from "../models/form_objects";
import { TrolyObject } from '../models/troly_object';
import { uuid } from "../models/utils.models";
import { TrolyService } from "../services/troly/troly.service";
import { WebsocketService } from "../services/troly/websocket.service";
import { TrolyCard } from "./troly.card";

@Component({
	template: '',
	styleUrls: ['./list.component.scss']

})
export abstract class TrolyListComponent<T extends TrolyObject> extends TrolyCard {

	override readonly __name:string = 'TrolyListComponent'

	/**
	 * The list of loaded records. This can be a paginated list or a full list (depending on autoLoadAllPages settings.
	 */
	declare records: T[];

	/**
	 * This is a list of records loaded with any Frontend-filtering applied.
	 * The component MUST subscribe to this._notifier$ to receive the records to filter on.
	 */
	declare filteredRecords: T[]; // just a convenience definition -- we know most lists will need to be filtered, usually via setQuery()

	/**
	 * Attribute to match when updating a record in a list. Defaults to 'id'. (integrations use 'provider')
	 */
	protected compareAttr:string='id';

	/**
	 * Defines whether new records received are added at the begining (start) of the list, or the end. Defaults to 'start'.
	 */
	protected insertAt:'end'|'start'='start'

	private readonly DEFAULT_SEARCH_ATTRIBUTES = {search_params:{}, currentPage:1, pageSize:10, advSearch:false}

	/**
	 * define the search attributes to reset when the resetList() function is called, and the default values to set.
	 */
	protected attributesToResetSearch = this.DEFAULT_SEARCH_ATTRIBUTES

	/**
	 * The current page number for the resultset. Use gotoPage() to change.
	 */
	public currentPage: number = this.DEFAULT_SEARCH_ATTRIBUTES.currentPage;

	/**
	 * The desired pagination size required from the server. Use setPageSize() or changePageSize() to maintain internal integrity.
	 */
	public pageSize = this.DEFAULT_SEARCH_ATTRIBUTES.pageSize;

	/**
	 * The current range of record being rendered.
	 */
	public recordRange: string = '?';

	/**
	 * The current total number of pages retrieved by the latest search, and based on record counts and page size.
	 */
	public totalPages: number = 0; // 0 indicates we do not know.

	/**
	 * The current total number of records being displayed by the latest search.
	 */
	public totalRecords: number = 0;

	/**
	 * The latest search terms used. This is persisted across pagination.
	 */
	protected search_terms: string = '';

	/** */
	protected dataColumns: { [key:string]:boolean } = {};

	/**
	 * Search parameters persisted across and (optionally) changed between searches, but maintain across pagination.
	 * As opposed to record_restrictions, which are enforced with ALL searches, search_params are user-selected filters.
	 */
	protected search_params: {} = {};
	get searchFilterApplied(): boolean {
		return Object.keys(this.search_params).length > 1;
	}

	/* 'user-driven' list filters automatically applied, routed, and saved in user localCache to be re-applied on return */
	protected auto_search_params:string[] = [];

	/**
	 * Set of restrictions forced to every search, and intended to limit which records are 
	 * retrieved in the component. Readonly needs to be initialised in constructor.
	 */
	public record_restrictions: {} = {};

	public advSearch:boolean=false; // whether or not the advanced search is shown
	
	/**
	 * 
	 */
	@ViewChildren(SortableDirective) sortableColumns: SortableDirective[];

	viewLoaded(): void {
		super.viewLoaded();
		const currentSort = this.search_params['order'] || this.record_restrictions['order'];
		if (currentSort) { this.sortableColumns.forEach((header) => { header.setSort(currentSort, this.record_restrictions['order']) }); };
   }


	/**
	 * 
	 */
	override loading: { 
		record: ITrolyLoadingStatus,  // defined in by TrolyComponent -- stores the status of the record being loaded for the component
		records: ITrolyLoadingStatus, // the list of records retrieved -- note this can be 'refreshed' when loaded from cache
		troly: ITrolyLoadingStatus }={ record:undefined, records:undefined, troly:undefined }/* holds the loading class indicators, slow internet is indicated by loading-slow by */

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected ws: WebsocketService = inject(WebsocketService);

	constructor() {
		super();

		this.attachRecordsSocket();
	}

	public resetList(reload:boolean=true) {

		Object.keys(this.attributesToResetSearch).forEach(_attr => {
			this[_attr] = this.attributesToResetSearch[_attr];
		}, this);

		if (this.form) { this.form.reset(); }
		
		if (reload) { this.loadRecords(); }

		this.pageSize = super.service.storeUiKey(`${this.__name}.pageSize`, this.attributesToResetSearch.pageSize);
		this.currentPage = super.service.storeUiKey(`${this.__name}.currentPage`, this.attributesToResetSearch.currentPage);
		this.advSearch = super.service.storeUiKey(`${this.__name}.advSearch`, this.attributesToResetSearch.advSearch);

		this.toggleSelectRecords(false);
	}

	public canReset():boolean {
		return this.attributesToResetSearch.currentPage != this.currentPage || this.attributesToResetSearch.pageSize != this.pageSize || 
				Object.keys(this.attributesToResetSearch.search_params).length > 0 || Object.keys(this.attributesToResetSearch).length > 4 || 
				this.selectedRecords.length > 0 || this.selectingRecords;
	}

	public toggleAdvSearch(value?:boolean) {
		if (value != undefined) { this.advSearch = value; }
		else { this.advSearch = !this.advSearch; }
		this.service.storeUiKey(`${this.__name}.advSearch`, this.advSearch);
	}

	/**
	 * The records notifier to attach in order to retrieve records and apply any filtering. it generally defaults to this.service.records, but can be overriden via `protected LoadData`
	 */
	public get listNotifier(): BehaviorSubject<T[]> { return this._notifier$; }
	private _notifier$: BehaviorSubject<T[]>;
	protected set listNotifier(value:BehaviorSubject<T[]>) {
		if (this._notifier$) { this._notifier$.unsubscribe(); } // just in case this function is called multiple times.
		this._notifier$ = value;
		this._notifier$.pipe(filter(_ => !!_), takeUntil(this.observablesDestroy$)).subscribe({
			next: (_records) => {
				this.records = _records;
				if (this._autoLoadAllPages && this.records.length < this.totalRecords) { 
					// the first when auto-loading the first page is either coming from the db
					// or the service has found records in the cache. In either cases, we can consider the records as loaded, and the UI updates the loading status to 'refreshing'
					this.markAs('records','refresh'); 
				}
				//this.setQuery(); -- I think that this was ONCE called for the application of 'filters' on the angular side -- if that's confirmed as true, we should conder a call to 'filterRecords' instead?
			}
		})
	}

	protected set service(value: TrolyService<any>) {
		super.service = value;
		
		this.pageSize = super.service.cachedUiKey(`${this.__name}.pageSize`, this.pageSize);
		this.currentPage = super.service.cachedUiKey(`${this.__name}.currentPage`, this.currentPage);
		this.advSearch = super.service.cachedUiKey(`${this.__name}.advSearch`, this.advSearch);
	}

	protected get service(): TrolyService<any> {
		// for some reason, typescript doesn't like having a getter without a setter, when both are defined in the classes extended..?
		return super.service
	}

	public attachRecordsSocket() {
		this.ws.connectCompanyChannel(this.selectedCompany.id)
		.subscribe((_:ITrolySocketMessage) => {
			this.addFromSocket(_);
		//	this.setQuery();
		});
	}

	/**
	 * Ensure that any search_params are applied and url is set to matched (if specified in auto_search_params)
	 * @param value 
	 * @param supported_auto_filters 
	 * @returns 
	 */
	public setQuery(values_to_set?: {}, targeted_filters?:string[]): boolean {
		
		values_to_set ||= {}
		let reload = false;
		this.selectedRecords = [];

		// different scenarios to handle
		// A. filter=valueA => (applies the filter) calling API.json?filter=valueA
		// A. filter=valueB => (applies the filter) calling API.json?filter=valueB
		// B. filter=nil => (removes the filter) calling API.json

		// C. filter[]=valueA,valueB,valueC as array VERSUS
		// C. filter[]=valueD,valueE,valueF as string, and replace 
		
		// C. filter[]=valueA ==> add valueA if not present in the 'current' filter array
		// C. filter[]=valueA ==> REMOVE valueA if present in the 'current' filter array
		// C. filter[]=valueA,valueB ==> add valueA if not present in the 'current' filter array

		// handled correctly by FormObject.toHttpParams
		// API.json?usage[]=valueA&usage[]=valueB


		if (Object.keys(values_to_set).length > 0) {
			
			// when value is null/empty, this means new records might have been retrieved
			// no need in that case to call the super() logic which ensures routing, caching and reloading of records.
			// as this notification records received is only to apply filters logical filters, NOT change-route+reload-records
			(targeted_filters || this.auto_search_params).forEach(filter => {
				let [filter_name, default_filter_value] = filter.split('=');

				let array_mode:boolean = false;
				if (filter_name.endsWith('[]') ){
					array_mode = true
				}

				if (Object.keys(values_to_set).includes(filter_name)) {
					
					reload = true; //triggers reloading of records
					
					if (array_mode) {
					//if(Array.isArray(values_to_set[filter])){ // no needed

						if (!Array.isArray(this.search_params[filter_name])) {
							this.search_params[filter_name] = [];
						}

						values_to_set[filter_name].forEach(v => {
							if (this.search_params[filter_name].includes(v)) {
								const index = this.search_params[filter_name].indexOf(v);
								// Remove the element if it exists
								if (index > -1) {
										this.search_params[filter_name].splice(index, 1);
								}
							} else {
								this.search_params[filter_name].push(v);
							}
						});
					}
					else if (values_to_set[filter_name] == null || values_to_set[filter_name] == this.search_params[filter_name]) { delete this.search_params[filter_name]; } // removal case
					else { this.search_params[filter_name] = values_to_set[filter_name]; } // replacement case

				}
			}, this);

			super.setRoutingParams(Object.assign(values_to_set, this.search_params)); // ensure url-rewrite are taking place, merging newly set values to 'user-requested' values (including null values to delete the rrouting param)
			this.service.storeUiKey(`${this.__name}.search_params`, this.search_params);
		}

		return reload && this.loadRecords();
	}

	/**
	 * Handles setting a query parameter with a specific value. eg. ?filter=123, then ?filter=456
	 * 
	 * @remarks
	 * This function is specific to the list component given querystring filtering is used in lists only. Uses the ListComponent/TrolyComponent.setQuery() function.
	 * 
	 * @param filter - string or object with a 'filter' key. both string and object.<filter> can contain '=' to specify a value to set. 
	 * @param value - object with a 'filter' key. if 'filter' contains '=', the value will be set to the right of the '='.
	 * 
	 * @returns true if the records/data will is reloaded after applying this filter.
	 * 
	 * Accepts a 'filter' string (key name, can include '=') and a 'value' object with a 'filter' key. 
	 * Processes the 'value' to extract or modify the query parameter, then applies changes using setQuery().
	 * Special handling if 'value.filter' contains '=', or matches the 'filter' string.
	 */
	//public setQueryValue(filter:string|{[key:string]:string}, value:{}): boolean {
	public setQueryValue(filter:string, value:{}): boolean {
		if (filter.indexOf('=') >= 0) { filter = filter.split('=')[0]; }
		let params = {}; params[filter] = value['filter'];
		// we check only if value is not null
		if (value['filter'] && value['filter'].indexOf('=') >= 0) { params[filter] = value['filter'].split('=')[1]; }
		if (value['filter'] == filter) { params[filter] = true; }
		
		return this.setQuery(params)
	}

	public resetQuery(only_specific_filters?:string[]):boolean {
		
		(only_specific_filters || Object.keys(this.search_params)).map(filter => {
			this.search_params[filter] = null; // this will make the super.setQuery() to delete the filter and update the url
		});

		if (this.form) { this.form.reset() }

		this.setQuery({...this.search_params}, only_specific_filters);
		this.service.deleteUiKey(`${this.__name}.search_params`);
		//this.service.storeUiKey(`${this.__name}.search_params`, this.search_params);
		//this.loadRecords();
		return false
	}

	// different scenarios to handle
	// A. filter=valueA => (applies the filter) calling API.json?filter=valueA
	// A. filter=valueB => (applies the filter) calling API.json?filter=valueB
	// B. filter=nil => (removes the filter) calling API.json

	// C. filter[]=valueA,valueB,valueC as array VERSUS
	// C. filter[]=valueD,valueE,valueF as string, and replace 
	
	// C. filter[]=valueA ==> add valueA if not present in the 'current' filter array
	// C. filter[]=valueA ==> REMOVE valueA if present in the 'current' filter array
	// C. filter[]=valueA,valueB ==> add valueA if not present in the 'current' filter array
	public setFilter(name: string, value:string): boolean {  /// same as setQuery(values_to_set?: {}, targeted_filters?:string[]): boolean {
		return true;
	}

	public toggleFilter(name: string, value:string): boolean {  /// same as setQuery(values_to_set?: {}, targeted_filters?:string[]): boolean {
		
		if (this.queryParamsListener.includes(name)) {
			// if this filter is meant to be routed, then we don't apply unless the routing is right
			debugger
			//[routerLink]="['/apps/dashboard']" [queryParams]="{status:f}" queryParamsHandling="merge"
		} 

		return true;
	}

	/**
	 * Check whether a user-defined filter is applied. Checks against all defined, or one/many as specicied.
	 * @param param can be 'warehouse_id', or can be 'warehouse_id=value', or can be ['warehouse_id=value','otherfilter=othervalue']
	 * @returns 
	 */
	public userFiltersApplied(params?:string|string[]):number {
		
		params ||= this.auto_search_params;
		params = params instanceof Array ? params : [params];

		return params.reduce((total:number, key:string) => {
			if (['order'].includes(key)) { return total; } // we never count 'ordering' as a filter.
			if (key.indexOf('=') >= 0) { 
				if (Array.isArray(this.search_params[key.split('=')[0]])) { /// warehouse_ids
					return total + (this.search_params[key.split('=')[0]].includes(key.split('=')[1]) ? 1 : 0) // in the case of a key=value, let's match the whole thing, 
				} else {
					return total + (this.search_params[key.split('=')[0]] == key.split('=')[1] ? 1 : 0) // in the case of a key=value, let's match the whole thing, 
				}
			} else { 
				return total + (this.search_params[key] != undefined ? 1 : 0) } // else just see if it's present.
		}, 0)
	}

	public userFilterSelected(params?:string|string[]): boolean { return this.userFiltersApplied(params) > 0; }

	addFromSocket(event:ITrolySocketMessage) {

		let modelName = (this.service.make() as TrolyObject)._trolyModelName;

		if (event.model == modelName) {

			let index = -1;
			if (event.operation == 'new') {
				if (this.currentPage == 1) {
					this.pushOrUpdateRecord(this.records, this.service.make(event.data));
					this._notifier$.next(this.records); // required to ensured cached records are updated see `listCacheKey`
					this.socketUpdateNotifier$.next(event);
				}
			} else if (event.operation == 'update') {
				index = this.records?.findIndex((_:T) => _[this.compareAttr] == event.data[this.compareAttr])
				if (index >= 0) {
					this.records[index] = this.service.make({...this.records[index], ...event.data});
					this._notifier$.next(this.records); // required to ensured cached records are updated see `listCacheKey`
					this.socketUpdateNotifier$.next(event);
				}
			}
		}
	}

	public socketUpdateNotifier$: BehaviorSubject<ITrolySocketMessage> = new BehaviorSubject<ITrolySocketMessage>(null);

	/**
	 * 
	 * @param records Dynamically inserts a record in a list of records
	 * @param record 
	 * @returns the index where this record was inserted, if it was inserted
	 */
	protected pushOrUpdateRecord<T extends TrolyObject>(collection: T[], element: T | T[], attr: string = 'id', insertAt:'end'|'start'='end'): T[] {
		const sizeBefore = collection.length
		collection = super.pushOrUpdateRecord(collection, element, this.compareAttr, this.insertAt)
		
		if (sizeBefore < collection.length) {
			this.totalRecords += 1;
			this.setRecordRange(); // if this is the first record, range was set to 0 from initialization
		}
		if (collection.length > this.pageSize) { 
			collection.pop(); 
		}
		
		return collection;
	}
	
	private _autoLoadAllPages: boolean = false;
	/**
	 * Defines the caching key to use in order to prevent records being reloaded with every page load.
	 * NOTE: the cache key NAME used here is important as some name patterns will be see the associated data flushed
	 * upon certain events -- see admin.layout.ts around L173 ( or search `flushLocalStorage`)
	 * // causes records to be stored to localStorage, and refresh on retrieval -- needs a matching .service for the objects to be correctly recreated
	 * @param key 
	 */
	protected enableListCache(key: string, notifier$?: BehaviorSubject<T[]>) {

		this._autoLoadAllPages = (key && key != '');

		if (!this._notifier$) {
			// when setting the caching key -- we want to ensure that the records loaded from cache are assigned immediately -- to override the notifier, set it before.
			this.listNotifier = notifier$ || this.service.records$
		}
		
		if (this._autoLoadAllPages) {
			this.service.recordsCacheKey = key;
		}
	}

	/**
	 * 
	 * @param separator 
	 * @returns 
	 */
	public setRecordRange() {

		if (this.totalRecords <= 0) { return this.recordRange = "0"; }
		let separator = " - ";

		if (this.currentPage == 0) { this.currentPage = 1; }

		let from = this.currentPage <= 1 ? 1 : ((this.currentPage - 1) * this.pageSize) + 1;
		let to = this.pageSize * this.currentPage;

		if (to > this.totalRecords) { to = this.totalRecords }

		return this.recordRange = `${from}${separator}${to}`;
	}

	/**
	 * 
	 * @param value 
	 */
	protected setTotalRecords(value: number) {
		this.totalRecords = value;
		this.totalPages = Math.ceil(this.totalRecords / this.pageSize);

		if (this.currentPage > this.totalPages) { this.lastPage(); }
	}

	/**
	 * 
	 * @param event 
	 */
	public changePageSize(event): boolean {

		if (this.pageSize != event.target.value) {
			this.pageSize = event.target.value;
			this.loadRecords();
			this.service.storeUiKey(`${this.__name}.pageSize`, this.pageSize);

			return true;
		}
		return false;
	}
	/**
	 * 
	 * @param value 
	 */
	protected setPageSize(value: number) {
		this.pageSize = value;
		this.totalPages = Math.ceil(this.totalRecords / this.pageSize);

		if (this.currentPage > this.totalPages) { this.lastPage(); }
	}

	/**
	 * 
	 */
	public nextPage(): boolean {
		if (this.isLoaded('records')) { // on init
			if (this.currentPage < this.totalPages) {
				this.currentPage = this.currentPage + 1;
				this.service.storeUiKey(`${this.__name}.currentPage`, this.currentPage);
				this.log(`nextPage loading page ${this.currentPage} of ${this.totalPages}`, 'DEEP');
				this.markAsLoading('records');
				this.loadRecords();
				return true;
			} else {
				this.log(`nextPage failed, already at page ${this.currentPage} of ${this.totalPages}`, 'DEEP');
			}
		}
		return false;
	}

	/**
	 * 
	 * @param page 
	 */
	public gotoPage(page: any) {
		if (this.isLoaded('records')) { // on init
			if (page <= this.totalPages) {
				//if (page != this.currentPage) {
				this.currentPage = page;
				this.service.storeUiKey(`${this.__name}.currentPage`, this.currentPage);
				this.markAsLoading('records');
				this.loadRecords();
				//}
			} else {
				this.log(`gotoPage failed, page ${page} should be between 1 and ${this.totalPages} inclusively`);
			}
		} else {
			this.err(`advancing from ${this.currentPage} to ${page} (total: ${this.totalPages})`);
		}
	}

	public onSort( sortEvent? : SortEvent) {
		if (!sortEvent || sortEvent.column == '' || sortEvent.direction == '') {
			delete this.search_params['order'];
		} else if (sortEvent?.column && sortEvent?.direction) {
			this.search_params['order'] = `${sortEvent.column},${sortEvent.direction}`;
		}

		if (this.loading.records == 'loaded') { this.loadRecords(); }
	}

	/**
	 * 
	 */
	public lastPage() {
		if (this.isLoaded('records') && this.currentPage < this.totalPages) { // on init
			this.currentPage = this.totalPages;
			this.markAsLoading('records');
			this.loadRecords();
		}
	}
	public isLastPage() {
		return this.isLoaded('records') && this.currentPage == this.totalPages;
	}
	/**
	 * 
	 */
	public prevPage() {
		if (this.isLoaded('records')) { // on init
			if (this.currentPage > 1) {
				this.currentPage = this.currentPage - 1;
				this.markAsLoading('records');
				this.loadRecords();
			} else {
				this.log(`prevPage failed, already at page ${this.totalPages}`);
			}
		}
	}

	/**
	 * 
	 */
	public firstPage() {
		if (this.isLoaded('records') && this.currentPage > 1) { // on init
			this.currentPage = 1;
			this.markAsLoading('records');
			this.loadRecords();
		}
	}

	/**
	 * 
	 * @param terms 
	 * @param params 
	 */
	public async loadRecords(): Promise<void> {// Observable<T[]> {

		this.log(`${this.__name}.loadRecords via TrolyListComponent.loadRecords [auto:${this._autoLoadAllPages}]`, 'STACK');

		if (this.loading.records == 'loaded') { this.markAs('records','refresh'); } else { this.markAsLoading('records'); }

		// commenting out function parameters -- never used.
		//if (terms && terms != this.search_terms) { this.search_terms = terms; this.currentPage = 1; }
		//if (params && params != this.search_params) { this.search_params = params; this.currentPage = 1; }

		let params = { ...this.search_params, ...this.record_restrictions }
		if (this.search_params['order']) { params['order'] = this.search_params['order'] } // only the order param can override the record_restrictions configured
		params['query'] = this.search_terms

		this.service.list(params, this.currentPage, this.pageSize).pipe(filter(_ => !!_))
			.subscribe({
				next: (_:TrolySearch<T>) => {

					this.log(`${this.__name} via TrolyListComponent.loadRecords: retrieved ${_.results.length} of ${_.meta.count} records (p:${this.currentPage})`, 'STACK');

					if (this._autoLoadAllPages) {

						const startList = this.currentPage == 1 ? [] : this._notifier$.value;
						
						// then add any new objects to the list
						this._notifier$.next(startList.concat(_.results));
					} else {
						this._notifier$.next(_.results);
					}

					if (this.totalRecords != _.meta.count) {
						this.setTotalRecords(_.meta.count);
					}

					this.setRecordRange();

					if (this._autoLoadAllPages && this.currentPage < this.totalPages) { 
						this.currentPage = this.currentPage + 1;
						this.loadRecords();
					} else if (!this._autoLoadAllPages || this._notifier$.value.length == _.meta.count) {
						this.markAsLoaded('records');
					}

				},
				error: (err) => {
					this.markAsLoadingError('records', err);
				},
				complete: () => {
					if (params['order'] && this.sortableColumns) {
						this.sortableColumns.forEach((header) => { header.setSort(params['order'], this.record_restrictions['order']) });
					}
				}
			});

		//return this.service.records$
	}

	/// subscribe to data notifiers and update forms
	protected loadData(): void {

		super.loadData();

		if (!this._notifier$) {
			// arch: it's possible that it would be better to set a fresh notifier for all lists. given the same service can load different data for diffrent components...?
			// see CustomerInteractionsCard.constructor
			this.listNotifier = this.service.records$
		}

		this.log(`${this.__name} via TrolyListComponent.loadData: loading`, 'STACK');
		this.loadRecords();
	}


	/**
	 * Allows to listen to changes in routing and automatically call setQuery when a parameter that is watched is set/changed
	 * @param watch 
	 */
	set queryParamsListener(watchedParams: string[]) {
		
		this.auto_search_params = watchedParams
		
		this.search_params = this.service.cachedUiKey(`${this.__name}.search_params`, this.search_params);
			
		if (this.route.snapshot.queryParams['order']) { 
			this.search_params['order'] = this.route.snapshot.queryParams['order']; 
		}

		watchedParams.push('order'); // we always want to watch for sorting changes

		this.route.queryParams.subscribe((params) => {

			let routingParams = watchedParams.reduce((result, param) => {
				// filter queryParams to only those supported
				if (this.search_params && this.search_params[param] == null) { delete this.search_params[param]; } // we don't want to keep null values in the search_params, routingParams are handled by the router merge strategy
				if (Object.keys(params).includes(param)) { result[param] = params[param]; } 
				return result;
			}, {});

			// remove all routing params which are unchanged from the current search params
			for (let key in this.search_params) {
				if (routingParams[key] == this.search_params[key]) { delete routingParams[key]; }
			}

			if (Object.keys(routingParams).length > 0) { 
				// only call setQuery once we have confirmed the params 'intersection' exists (held in auto_params)
				
				// given routeParams are really more of a convenience thing, we shouldn't always update based on routing changes.
				// arch: we do not use routig as Master because 
				// A -- we have multiple 'cards' and multiple lists and data retrieved in a single page, which could cause routing "conflicts"
				// B - we need to enfoce MORE filtering than just the routing in many cases, so (could be worked-around)
				//this.setQuery(routingParams);
			}
		});
	}



	//  .d8888b.           888                   888    d8b                        888    888                        888 888 d8b                   
	// d88P  Y88b          888                   888    Y8P                        888    888                        888 888 Y8P                   
	// Y88b.               888                   888                               888    888                        888 888                       
	//  "Y888b.    .d88b.  888  .d88b.   .d8888b 888888 888  .d88b.  88888b.       8888888888  8888b.  88888b.   .d88888 888 888 88888b.   .d88b.  
	//     "Y88b. d8P  Y8b 888 d8P  Y8b d88P"    888    888 d88""88b 888 "88b      888    888     "88b 888 "88b d88" 888 888 888 888 "88b d88P"88b 
	//       "888 88888888 888 88888888 888      888    888 888  888 888  888      888    888 .d888888 888  888 888  888 888 888 888  888 888  888 
	// Y88b  d88P Y8b.     888 Y8b.     Y88b.    Y88b.  888 Y88..88P 888  888      888    888 888  888 888  888 Y88b 888 888 888 888  888 Y88b 888 
	//  "Y8888P"   "Y8888  888  "Y8888   "Y8888P  "Y888 888  "Y88P"  888  888      888    888 "Y888888 888  888  "Y88888 888 888 888  888  "Y88888 
	//                                                                                                                                         888 
	//                                                                                                                                    Y8b d88P 
	//                                                                                                                                     "Y88P"  
	/**
	 * This section contains the functions and logic to allow selecting items in the list of items retrieved
	 * functions to handle the selection and unselection of records
	 */
	
	// todo: ready to test
	/** the list of currently selected ercords */
	public selectedRecords: uuid[] = [];
	/** Select a specific record . if already selected, does nothing */
	public selectRecord(r:TrolyObject, event?, max:number=-1) {
		
		if (!this.selectingRecords) { return; }

		if (max > 0 && this.selectedRecords.length >= max) { this.selectedRecords=[] }

		if (event?.shiftKey) {
			if (this.selectedRecords.length == 0) {
				// nothing checked before, we just start the selection
				this.selectRecord(r);
			} else if (this.selectedRecords.includes(r.id)) {
				// this box was checked before in the selection, so we uncheck it and all the ones after it -- doesn't handle partial selection, only ranges
				const index = this.selectedRecords.indexOf(r.id);
				this.selectedRecords.splice(index, this.selectedRecords.length - index);
			} else {
				let add:boolean=false
				this.records.map((_r) => {
					// loop through all the records, and select everything between the first and the current one
					if (!add && _r.id == this.selectedRecords[this.selectedRecords.length-1]) { add = true; }
					else if (add) {
						this.selectRecord(_r); if (_r.id == r.id) { add = false; }
					}
				})
			}
		} else if (!this.selectedRecords.includes(r.id)) {
			this.selectedRecords.push(r.id);
		}
	}
	// todo: ready to test
	public selectingRecords:boolean = false; // whether or not we are operating in bulk
	toggleSelectRecords(toValue?:boolean): void {
		this.selectedRecords = [];
		if (toValue != undefined) { this.selectingRecords = toValue; }
		else { this.selectingRecords = !this.selectingRecords; }
	}
	// todo: ready to test
	toggleRecordSelection(r:TrolyObject, event?, max:number=-1): void {
		if (this.selectedRecords.includes(r.id)) {
			this.selectedRecords = this.selectedRecords.filter((id) => id !== r.id);
		} else {
			this.selectRecord(r, event, max);
		}
	}

}