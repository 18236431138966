<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

        <div class="{{ bsCssClassPrefix }}-header">
                <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="operatingModeSingle" [innerHTML]="'Customers.Actions.'+__name+'.header-single' | translate: { count: record_ids?.length, name: (record?.fname + ' ' + record?.lname) }"></h4>
                <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="operatingModeSelectedOne" [innerHTML]="'Customers.Actions.'+__name+'.header-bulk-one'"></h4>
                <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="operatingModeSelectedMany" [innerHTML]="'Customers.Actions.'+__name+'.header-bulk-some' | translate: { count: record_ids?.length }"></h4>
                <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="operatingModeAll" [innerHTML]="'Customers.Actions.'+__name+'.header-bulk-all'"></h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>
        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

            <form-status [formGroup]="form" successAction="close" (dismissCallback)="resolveModal($event)">
				<span #status code="ONE_FAILED">{{ 'Customers.Actions.'+__name+'.FormStatuses.ONE_FAILED' | translate }}</span>
				<span #status code="SOME_SUCCESS">{{ 'Customers.Actions.'+__name+'.FormStatuses.SOME_SUCCESS' | translate }}</span>
				<span #status code="ALL_FAILED">{{ 'Customers.Actions.'+__name+'.FormStatuses.ALL_FAILED' | translate }}</span>
				<span #status code="ONE_SUCCESS">{{ 'Customers.Actions.'+__name+'.FormStatuses.ONE_SUCCESS' | translate }}</span>
				<span #status code="ALL_SUCCESS">{{ 'Customers.Actions.'+__name+'.FormStatuses.ALL_SUCCESS' | translate }}</span>
			</form-status>

            <div class="row">

                <div class="col-12">
						<mat-form-field class="disabled">
							<mat-label>To</mat-label>
							<input matInput type="text" [readonly]="true" *ngIf="operatingModeSingle" value="{{record?.fname}} &lt;{{record?.email}}&gt;" />
                            <input matInput type="text" [readonly]="true" *ngIf="operatingModeSelectedMany" value="{{ record_ids.length }} Customers" />
						</mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field>
                        <mat-label>Subject</mat-label>
                        <input matInput formControlName="subject" type="text" />
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <editor [init]="tinyMCEConfig('full')" formControlName="body" tinymce="tinymce"></editor>
                </div>
					 <div class="col-12 pt-2">
						<span ngbDropdown>
							<i class="bx bx-joystick-button font-size-20 text-orange" ngbDropdownToggle></i>
							<div ngbDropdownMenu>
								<h6>My goal is</h6>
								<button ngbDropdownItem type="button">Generate a sale</button>
								<button ngbDropdownItem type="button">Invite to visit</button>
								<button ngbDropdownItem type="button">Warm and fuzzy</button>
								<button ngbDropdownItem type="button">Other <span class="fs-smaller text-secondary">(specify)</span></button>
							</div>
						  </span>
					 </div>
					<socket-progress-widget (onProcessingError)="form.errorDetails.push($event)" (onProcessingResult)="form.errorDetails.push($event)"></socket-progress-widget>
            </div>
        </div>
        <div class="{{ bsCssClassPrefix }}-footer">
            <button type="submit" class="btn btn-primary {{ form.loading }}"
                [disabled]="form.cannotSubmit">
                Save
            </button>
        </div>

    </form>
</div>