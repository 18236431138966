import { TrolyObject } from '../troly_object';
import { Company } from './company.model';
import { Product } from './product.model';
import { User } from './user.model';


export class Signup extends TrolyObject {

	override _trolyPropertyArray = { user: User, company:Company, products: Array }

	declare company: Company;
	declare products: Product[];
	declare user: User;

	declare partner:Company;
	
	constructor(values?: Object) {

		super('signup',values);

	}

}