import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';

import { TrolyComponent } from 'src/app/core/components/troly.component';
import { I18nLoader } from 'src/app/core/i18n_loader';


@Component({
	selector: 'socket-status',
	templateUrl: './socket-status.component.html',
	styleUrls: ['./socket-status.component.scss'],
})
export class SocketStatusComponent extends TrolyComponent {

	@Input() showBadge: boolean = true;

	@Input() messageFilterPattern: RegExp|string = '';
	@Output() messageCallback: EventEmitter<any> = new EventEmitter();

	@Input() status: string;


	constructor() {
		super();
		(this.translateService.currentLoader as I18nLoader).getTranslation(this.translateService.currentLang, 'shared/widgets/sockets', 'Sockets').pipe(take(1)).subscribe(_ => {
			this.translateService.setTranslation(this.translateService.currentLang, _);
		});

	}

	protected receiveInputParams(changes: SimpleChanges): void {
		if (changes.status) { delete changes['status'] }
		if (Object.keys(changes).length > 0) { super.receiveInputParams(changes); }
	}
}
