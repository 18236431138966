<form [formGroup]="form" (ngSubmit)="onSubmit()" class="no-live-loading">
	<mat-form-field class="{{ matFieldClass }}">
		<mat-label>{{ searchLabel }}</mat-label>
		<i matPrefix role="icon" class="bx {{icon}}" [ngClass]="{ 'text-secondary': disabled }"></i>
		
		<input #searchInput matInput type="text" formControlName="search" (click)="searchInput.select()"  [matAutocomplete]="topbarSearch" [required]="inputRequired" />
		
		<i *ngIf="records !== null" matSuffix class="text-danger cursor-pointer bx font-size-16 bxs-x-circle" (click)="resetSearch()" [ngClass]="{ 'text-secondary': disabled }"></i>

		<mat-autocomplete #topbarSearch="matAutocomplete" panelWidth="420px" style="overflow:unset" (closed)="action.stop()"
			class="search rounded-dropdown autocomplete-{{ fieldSize }} {{ form.loading }}"
			(optionSelected)="onSelect($event.option.value)">

			<div *ngIf="searchScope.length >=2 && (userScope.length > 0 || totalRecords > pageSize)" class="text-center">
				<button class="btn btn-sm btn-{{ userScope.indexOf('c') < 0 ? 'outline-dark' : 'dark'}} m-2" [class.text-linethrough]="userScope.length > 0 && userScope.indexOf('c')<0" (click)="toggleUserScope('c')" *ngIf="searchScope.indexOf('c')>=0">Contacts</button>
				<button class="btn btn-sm btn-{{ userScope.indexOf('o') < 0 ? 'outline-dark' : 'dark'}} m-2" [class.text-linethrough]="userScope.length > 0 && userScope.indexOf('o')<0" (click)="toggleUserScope('o')" *ngIf="searchScope.indexOf('o')>=0">Orders</button>
				<button class="btn btn-sm btn-{{ userScope.indexOf('t') < 0 ? 'outline-dark' : 'dark'}} m-2" [class.text-linethrough]="userScope.length > 0 && userScope.indexOf('t')<0" (click)="toggleUserScope('t')" *ngIf="searchScope.indexOf('t')>=0">Tasks</button>
				<button class="btn btn-sm btn-{{ userScope.indexOf('p') < 0 ? 'outline-dark' : 'dark'}} m-2" [class.text-linethrough]="userScope.length > 0 && userScope.indexOf('p')<0" (click)="toggleUserScope('p')" *ngIf="searchScope.indexOf('p')>=0">Products</button>
			</div>
			<!--div style="max-height:355px;overflow-y:scroll"-->
			<ng-scrollbar visibility="hover" style="min-height:125px;max-height:450px;">
				
				<div *ngIf="records === null" class="search-intro">
					<div class="d-flex justify-content-center">
						<div *ngFor="let scope of searchScope.split('')" class="avatar-title h5 bg-secondary bg-soft rounded-circle">
							<i class="bx text-secondary" [ngClass]="{
								'bx-user': scope == 'c',
								'bx-dollar': scope == 'o',
								'bx-shopping-bag': scope == 'p' || scope == 'w',
							}"></i>
						</div>
					</div>
					<h6>{{ 'SharedI18n.Widgets.' + __name + '.Scope.' + searchScope | translate }}</h6>
				</div>

				<div *ngIf="form.loading == 'loaded' && records?.length === 0" class="search-intro text-danger">
					<div class="d-flex justify-content-center">
						<div class="avatar-title h5 rounded-circle bg-danger bg-soft text-dark">
							<i class="bx bx-x" style="font-size:24px;"></i>
						</div>
					</div>
					<h6>No results found, try again.</h6>
				</div>
				<mat-option *ngFor="let result of records" [value]="result" class="search-result two-lines-option" [ngClass]="{ 'icon-vertical-center' : result.class_name != 'Customer' }">
					<ng-container *ngIf="result.class_name == 'Customer'">
						<div class="avatar-xs my-auto">
							<span class="avatar-title rounded-circle {{ result.genderBgClass() }}" avatar [cid]="result.primary_key"
								[email]="result.metadata['email']" [size]="144" [avatar-class]="result.genderBgClass()">
								{{ (result.metadata['fname'] || ' ')[0] + (result.metadata['lname'] || ' ')[0] }}
							</span>
						</div>
						<div>
							<h6>{{ result.heading }} <span class="badge bg-soft bg-primary text-primary">{{result.info1}}</span>
								<span *ngIf="result.metadata['membership_num']"><i class="bx bxs-star text-warning"></i> {{ '#' + result.metadata['membership_num'] }}</span></h6>
							<p>{{ result.subheading }}{{ result.info2 ? ', ' + result.info2 : '' }}</p>
						</div>
					</ng-container>
					
					<ng-container *ngIf="result.class_name == 'Order'">
						<div class="avatar-xs my-auto">
							<span class="avatar-title bg-secondary bg-soft text-dark rounded-circle">{{ result.metadata['initials'] }}</span>
						</div>
						<div>
							<h6>Order #{{ result.subheading }} <span class="badge bg-secondary text-dark bg-soft">{{ 'Models.Order.Values.status.' + result.info1 | translate }}</span> <i class="bx bxs-star text-warning" *ngIf="result.metadata['membership_id']"></i></h6>
							<p>
								{{ result.heading }}, {{ result.info2 | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }}
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="result.class_name == 'Product'">
						<div class="avatar-xs text-center bg-secondary bg-soft my-auto">
							<i class="text-dark mdi {{ result.getIcon() }} font-size-22"></i>
						</div>
						<div>
							<h6>{{ result.heading }}
								<span class="badge bg-secondary bg-soft align-top">{{ 'Models.Product.Values.variety.'+result.metadata['variety'] | translate }}</span>
							</h6>
							<p>
								<span *ngIf="result.subheading">{{ result.subheading }}, </span>
								<span *ngIf="result.info1 && asFloat(result.info1) != 0">{{ result.info1 }} units, </span>
								<span *ngIf="result.info2">{{ result.info2 | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }}</span>
								<span *ngIf="result.metadata['bundle_content_product_count']">, contains {{ result.metadata['bundle_content_product_count'] }} units of {{ result.metadata['bundle_content_distinct_products'] }} products</span>
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="result.class_name == 'Task'">

					</ng-container>

					<ng-container *ngIf="result.class_name == 'User'">

					</ng-container>

				</mat-option>
			<!--/div-->
			</ng-scrollbar>

			<div class="{{ bsCssClassPrefix }}-footer pt-2 pe-1">

				<p *ngIf="form.loading == 'loaded' && !records"> Start typing to search.. </p>
				<p *ngIf="form.loading == 'loaded' && records?.length == 0"> Start typing to search.. </p>
				<p *ngIf="form.loading == 'loaded' && records?.length > 0">Showing {{ records.length > pageSize ? pageSize : records.length }} of {{ totalRecords }} results</p>
				<p *ngIf="form.loading == 'loading'">Searching..</p>

				<div>
				<ng-container *ngIf="records?.length == 0">
					<button *ngIf="searchScope.length == 1" (click)="createRecord(searchInput.value, searchScope);" class="btn mt-1 text-white btn-sm bg-primary">Create</button>
					<div *ngIf="searchScope.length > 1" ngbDropdown class="d-inline-block me-2">
						<button class="mt-1 btn btn-sm btn-primary" ngbDropdownToggle><span>Create</span><i class="ms-2 bx bx-chevron-down"></i></button>
						<div ngbDropdownMenu>
							<button *ngFor="let scope of this.searchScope.split('')" ngbDropdownItem (click)="createRecord(searchInput.value, scope)" [ngClass]="{'d-none':['o'].includes(scope)}">
								<span>{{ 'SharedI18n.Widgets.' + __name + '.Create.' + scope | translate }}</span>
							</button>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="allowScanner">
					<button type="button" (click)="toggleCamera()" class="mt-1 ms-1 btn btn-sm" [ngClass]="{ 'btn-success' : action.isStart, 'btn-primary' : !action.isStart, 'btn-secondary': action.isLoading }">
						<i *ngIf="!action.isLoading" class="bx bxs-camera align-middle me-2 font-size-14"></i>
						<i *ngIf="action.isLoading" class="bx bx-loader-alt bx-spin align-middle me-2 font-size-14"></i>
						<span *ngIf="action.isStart">Disable <strong>scanner</strong></span>
						<span *ngIf="!action.isStart">Enable <strong>scanner</strong></span>
					</button>
				</ng-container>
				</div>
			</div>
			<div class="{{ bsCssClassPrefix }}-footer border-0 mt-0 pb-0" [ngClass]="{ 'd-none': !action.isStart || !allowScanner }">
				<!-- For camera -->
				<ngx-scanner-qrcode [constraints]="{audio:true,video:false}" #action="scanner"></ngx-scanner-qrcode>
			</div>
			<mat-option class="d-none"><!-- without an option present, the panel doesn't show up (instructions and footer are not clickable options) --></mat-option>
		</mat-autocomplete>
		<mat-hint>{{ searchHint }}</mat-hint>
	</mat-form-field>
</form>