import { AfterContentInit, Directive, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';

@Directive({
    selector: 'div[selectable]',
    host: {
        '[class.selected-card]': 'selectedCard == thisCardId',
		  '[class.selected-field]': 'selectedField == thisFormField',
    },
})
export class SelectableDirective implements AfterContentInit {

	protected selectedCard:string;
   protected selectedField:string;
   protected thisCardId:string;
	protected thisFormField:string;

   constructor(protected element: ElementRef, protected route: ActivatedRoute) {

		this.thisCardId = element.nativeElement.id;
		this.thisFormField = element.nativeElement.id;

		this.route.fragment.pipe(filter(_ => !!_)).subscribe((_) => {
			const parts = _.split('-');
			this.selectedCard = parts[0];
			if (parts.length > 1) {
				this.selectedField = parts[1];
			} else {
				this.selectedField = null;
			}

		});
	}

	ngAfterContentInit(): void {
		if (this.element.nativeElement.children[0].tagName == 'MAT-FORM-FIELD') {
			const inputElement = this.element.nativeElement.children[0].children[0].children[0].children[0].children[0]
			if (inputElement.getAttribute('formcontrolname') == this.selectedField) {
				if (!this.element.nativeElement.children[0].classList.contains('bx-pulsating')) {
					this.element.nativeElement.children[0].classList.add('bx-pulsating')
				}
			}
		}
	}
}
