import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Order } from "./order.model";
import { User } from "./user.model";


/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Payment extends TrolyObject {

	declare company_id: uuid
	declare customer_id: uuid
	declare user_id: uuid
	declare order_id: uuid
	
	declare order: Order;
	
	declare references_id: uuid
	
	/** The gateway which has processed this payment */
	declare integration_id: uuid
	
	declare trx: 'capture' | 'refund' | 'auth' | 'release'
	_trx: ValidatorFn[] = [Validators.required]

	declare method: string
	_method: ValidatorFn[] = [Validators.required]

	declare amount: number

	declare status: 'pending' | 'declined' | 'error' | 'success'
	declare rrn: string//"ch_eClfMy1G4j7HYh4vNHFtEA",
	
	declare gateway_messages: {
		timestamp:Date,
		operation:'request'|'response',
		message:JSON
	}[]

	declare processing_cost: number // what the gateway charges
	declare processing_price: number // what we have charged the customer
	

	/**
	 * Virtual attributes
	 */
	declare status_details:string
	declare exchange_rate:number
	declare currency:string

	declare provider_data: {
		users: User[]
	}

	constructor(values?: Object) {
		super('payment', values);
	}

	public user(id:uuid): User | null {
		const u = super._user(id, this.provider_data?.users || [])
		return u ? new User(u) : null
	}
	
	public get processedBy(): User | null  { return this.user(this.user_id) }

}


export class PaymentMethod extends TrolyObject {

	/**
	 * Visible label chosen by the user to identify the payment method
	 */
	declare label:string
	_label: ValidatorFn[] = [Validators.required]

	/**
	 * The method is generated based on the name, and never shown, but used by the System to identify the payment method. This is to improve reporting, where a deleted payment method can be re-created and reporting will remain consistent.
	 */
	declare method: string

	/** details of expected payment terms. */
	declare terms:number
	_terms: ValidatorFn[] = [Validators.required]
	declare terms_details:string;

	declare connected_hardware:boolean;
	/**
	 * to be used on invoices -- when payment is due, could include bank account details for instanc
	 */
	declare instructions:string

	declare status: 'enabled' | 'disabled' | 'restricted'

	/**
	 * Show the 1-click direct access button or not
	 */
	declare use_as_quick_payment:boolean

	/**
	 * Payment method that can only used against trade account/orders only
	 */
	declare trade_account_only:boolean

	constructor(values?: Object) {
		super('payment_method', values);
	}
}