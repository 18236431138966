import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

@Component({
    selector:'calculator-widget',
    templateUrl: './calculator.widget.html',
    styleUrls: ['./calculator.widget.scss'],
})
export class CalculatorWidget implements OnInit {
    
    acceptedKeys:string[] = ['1','2','3','4','5','6','7','8','9','0','.']

    @Input() payable: number;
    @Input() pennyRounding:boolean = true;

    @Input() decimalsCount:number = 2;

	 @Output() canProcessPayment:EventEmitter<boolean> = new EventEmitter<boolean>()

    tendered:number=0;
    owed:number;
    enteringDecimals:number=0;
    rounding:number=0;

    ngOnInit(): void {
        
        this.payable = parseFloat(this.payable.toString());
		  this.canProcessPayment.emit(false);

        if (this.pennyRounding) {
            this.rounding = this.calculateRounding(this.payable, 0.05);
            this.payable += this.rounding;
        }
    }

    calculateRounding(fromValue:number, toNearest:number): number {

        let valueInCents = (fromValue * 10 * 10) // `* 100` doesn't work -- https://nisal-pubudu.medium.com/how-to-deal-with-floating-point-rounding-error-5f77347a9549
        toNearest = toNearest * 10 * 10          // `* 100` doesn't work -- https://nisal-pubudu.medium.com/how-to-deal-with-floating-point-rounding-error-5f77347a9549

        // divide by boundary, then round, then multiply -- the result is the nearest (up or down) boundary, then diff.
        // as per https://bobbyhadz.com/blog/javascript-round-number-to-nearest-five#:~:text=To%20round%20a%20number%20to,multiply%20the%20result%20by%205%20.
        return Math.round((Math.round(valueInCents / toNearest) * toNearest) - valueInCents) / 100;
    }
    addDigit(digit:string) {
        if (digit == '.') {
            this.enteringDecimals = 1;
        } else {
            if (this.enteringDecimals <= this.decimalsCount) {
                if (this.enteringDecimals > 0) {
                    let tenteredInCents:string = (this.tendered * 10 * 10).toString() // `* 100` doesn't work -- https://nisal-pubudu.medium.com/how-to-deal-with-floating-point-rounding-error-5f77347a9549
                    let replaceAt = tenteredInCents.length - (this.decimalsCount - this.enteringDecimals)// there's only 2 decimals, and we need the character index starting from the end
                    replaceAt -= 1; // length is absolute (starts at 1), and we want a 0-based index instead
                    tenteredInCents = tenteredInCents.substring(0, replaceAt) + digit + tenteredInCents.substring(replaceAt + digit.length); // digit is only ever 1 in length, but hypothetically we could support '00'
                    this.tendered = parseInt(tenteredInCents) / 100;
                    this.enteringDecimals += 1;
                } else {
                    this.tendered = parseFloat(this.tendered.toString() + digit)
                }
                
                this.owed = this.tendered - this.payable
					 this.canProcessPayment.emit(this.owed > 0)
            }
        }
    }

    removeDigit() {
        
        if (this.enteringDecimals > 0) {
            this.enteringDecimals -= 1  // winding the decimal position back
            this.addDigit('0')          // update the digit -- which increments the decimal position
            this.enteringDecimals -= 1  // pushing the decimal position back ready for user input

            if (this.enteringDecimals == 1) { // `> 1`, not `> 0` because if we're currently entering decimal 1, then backspace should skip "the period" to go back to deleting the first digit, instead skip and remove the first digit -- this is the opposite of `digit == '.'` in addDigit.
                this.enteringDecimals = 0
            }

        } else {
            let tenderedAsString = this.tendered.toString()
            if (tenderedAsString.length == 1) {
                this.tendered = 0
            } else {
                this.tendered = parseInt(tenderedAsString.substring(0, tenderedAsString.length - 1 )) // length is absolute (starts at 1) and we need a 0-based index, AND we want to remove 1 character
            }
            
            this.owed = this.tendered - this.payable;
				this.canProcessPayment.emit(this.owed > 0)
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.acceptedKeys.includes(event.key)) {
            this.addDigit(event.key)
        } else if (event.key == 'Backspace') {
            this.removeDigit()
        }
    }
}