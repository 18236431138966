import { Injectable } from '@angular/core';

import { User } from '../../models/troly/user.model';
import { ITrolyService, TrolyService } from './troly.service';

import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Company } from '../../models/troly/company.model';

@Injectable({
	providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a user's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current user to the user in question
*/
export class UserService extends TrolyService<User> implements ITrolyService<User> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'UserService';

	constructor() { super('users'); }

	/**
	 * RECORD Notified: the UserService notifier is attached to the currently authenticated user
	 */


	public _next(obj: User, flush_attr?: string|string[], force:boolean=false): User {
		this.storedUser(obj, 'login');
		return super._next(obj, flush_attr, force);
	}

	public make(payload: {} = {}): User { return payload instanceof User ? payload : new User(payload); }


// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/passwords_controller.rb

//     new_user_password GET                          /users/password/new(.:format)                                                                     devise/passwords#new
// 	edit_user_password GET                          /users/password/edit(.:format)                                                                    devise/passwords#edit
// 		  user_password PATCH                        /users/password(.:format)                                                                         devise/passwords#update
// 							 PUT                          /users/password(.:format)                                                                         devise/passwords#update
// 							 POST                         /users/password(.:format)                                                                         devise/passwords#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/confirmations_controller.rb

// new_user_confirmation GET                          /users/confirmation/new(.:format)                                                                 confirmations#new
// 	 user_confirmation GET                          /users/confirmation(.:format)                                                                     confirmations#show
// 							 POST                         /users/confirmation(.:format)                                                                     confirmations#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/unlocks_controller.rb

// 		new_user_unlock GET                          /users/unlock/new(.:format)                                                                       devise/unlocks#new
// 			 user_unlock GET                          /users/unlock(.:format)                                                                           devise/unlocks#show
// 							 POST                         /users/unlock(.:format)                                                                           devise/unlocks#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/sessions_controller.rb

// 	  new_user_session GET                          /users/sign_in(.:format)                                                                          sessions#new
// 			user_session POST                         /users/sign_in(.:format)                                                                          sessions#create
//  destroy_user_session DELETE                       /users/sign_out(.:format)                                                                         sessions#destroy

	/**
	 * Handles the deletion of a user, which really means logging from the API. (record deletion from the API goes through "archival" process)
	 * @param payload 
	 * @param method 
	 * @returns 
	 */
	public logout(params?: {}): Observable<User> {
		return this.delete(null, 'sign_out').pipe(
			map(_ => this.make(_.body)) // the API response (Devise session) returns a {} object, not a User object
		);
	}

	public login(payload:User, params?: {}): Observable<User> {

		// Add this point, we know a user is trying to login, 
		// we add their browser timezone offset to save if we didn't have one already
		// this is important in order to retrieve records from the correct timeframe (the API is based on UTC+0)
		// also, see TrolyObject.constructor -- all dates from the API are converted to local time for display.
		payload.pref_utc_offset = (new Date().getTimezoneOffset() * -60) // getTimezoneOffset is number of minutes to UTC, we're converting to seconds FROM utc
		payload.pref_language = navigator.language

		delete payload['id']; // we should never post a /users/2/sign_in url..

		params ||= {}
		params['with_companies']=true

		return this.create(payload, params, 'sign_in')
	}

	public sessionGET(method: string, params?: {}): Observable<User> {
		return this.get(null, method, params).pipe(map(_ => this.make(_.body)));
	}

	public sessionPOST(method: string, obj:User, params?: {}): Observable<User> {
		return this.create(obj, params, method)
	}

	public sessionPUT(method: string, obj:User, params?: {}): Observable<User> {
		return this.save(obj, params, method)
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadCompanies(params?: {}, record?: User): Observable<Company[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['customer_id'] || record.id })
		delete params['customer_id']

		const force = record && record.isStale('companies')

		if (!force && record.companies) { return of(record.companies) }
	
		return this.getList<Company>(obj, 'companies', params, force).pipe(filter(_ => !!_), map(result => result['companies'].map(o => new Company(o))))
	}

}