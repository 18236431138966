import { Component } from '@angular/core';

import { TrolyCard } from 'src/app/core/components/troly.card';
import { Company } from 'src/app/core/models/troly/company.model';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.page.html',
    styleUrls: ['./redirect.page.scss'],
})

/**
 * Signup component
 */

export class RedirectPage extends TrolyCard {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */

	override readonly __name: string = 'RedirectPage';

	override record:Company = new Company();

	/**
	 * Loads initial data for the component, specifically handling referral codes.
	 * If a referral code is present in the route parameters, it attempts to validate
	 * the code and update the form state based on the validation response.
	 */
	protected loadData(): void {

		this.log(`${this.__name}.loadData()`, 'STACK');

		if (this.routeParams.code) {
			
			const params = {scan:true}; // this will ask the API to properly record this as a public scan

			this.companyService.searchTokens(this.routeParams.code, params).subscribe({
				next: (response) => {

					if (response.redirect) { debugger; window.location.assign(response.redirect); }
					
					if (response.company) {
						this.record = response.company;
					}
				}

			});
		}
	}

}
