import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

export class CompanyUser extends TrolyObject {

	override _trolyPropertyArray = { permissions: Object }

	/** The user this grants access to this company */
	declare user_id: uuid;

	/** The company this grants access to */
	declare company_id: uuid;

	declare permissions:IPermissions; // This is a placeholder, replace 'any' with more specific types as needed	;
	_permissions: ValidatorFn[] = [atLeastOnePermissionSet];

	/** The date at which the last / current valid session was initiated at */
	declare current_sign_in_at: Date;
	/** The ip address from where the last / current session was initiated from */
	declare current_sign_in_ip: string;
	/** The date at which the previous session was initiated at */
	declare last_sign_in_at: Date;
	/** The ip from which the previous session was initiated from */
	declare last_sign_in_ip: string;

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string
	declare readonly: boolean

	declare personalisations: {} // stores user preferences

	constructor(values?: Object) {
		super('company_user', values);

		if (!this.permissions) {
			this.permissions = this.allRoles().reduce((c, v) => { c[v]=null; return c; } ,{} as IPermissions)
		}
	}

	/**
	 * Where this company data is located (and indirectly, what API end-point to use.)
	 * @param values 
	 */
	declare db_region:'au'|'us'|'eu';

	public allRoles():IRole[] { return ['owner', 'admin', 'finances', 'hr', 'sales', 'marketing', 'channels', 'operations', 'technology']; }

	/**
	 * Applies certain transformation the the object before patching it to a form
	 * @param baseObj 
	 * @returns 
	 */
	public patchableValues() {
		let result = super.patchableValues()
		
		if (result.permissions) { 
			// There is a question as to whether this should be done here;
			// This implies we are using a slider capturing a numerical value from 0 to 4 on the UI (or any mean to capturig a numerical value 
			// mat-slider doesn't handle string.
			const mappingToValue = [null,'read','write','create','grant'];
			result.permissions = this.allRoles().reduce((c,v) => {
				c[v] = mappingToValue.indexOf(result.permissions[v] || -1)
				return c
			},{})
		}

		return result
	}

	/**
	 * Applies certain transformations to an object about to be sent to the API
	 * @param toBeSaved 
	 * @returns 
	 */
	public beforeSave(originalObject:CompanyUser): boolean {
		
		if (this.company_id) {
			// The API doesn't accept a company_id -- only the 'ActiveCompany' will be set as default.
			// HOWEVER if editing permissions for a DIFFERENT company, then passing an ID should be sufficient.
			delete this.company_id;
		}
		if (this.permissions) { 
			const mappingToValue = [null,'read','write','create','grant'];
			this.allRoles().forEach((k) => {
				this.permissions[k] = mappingToValue[parseInt(this.permissions[k])] || mappingToValue[parseInt(originalObject.permissions[k])]
			})
		}

		return super.beforeSave(originalObject);
	}

}

export function atLeastOnePermissionSet() : ValidatorFn {
	return (group: FormGroup): ValidationErrors => {
		let hasOneSet = false;
		['owner', 'admin', 'finances', 'hr', 'sales', 'marketing', 'channels', 'operations', 'technology'].forEach((k) => {
			group.controls[k].value >= 0 ? hasOneSet = true : null;
		});
		
		return hasOneSet ? null : { unset:true };
	};
}

export type IPermission = 'read'|'write'|'create'|'grant';
export type IRole = 'owner'|'admin'|'finances'|'hr'|'sales'|'marketing'|'channels'|'operations'|'technology'
export interface IPermissions {
	sales: IPermission
	billing: IPermission
	marketing: IPermission
	products: IPermission
	operations:IPermission
	integrations: IPermission
	admin: IPermission
	owner: IPermission
}

 