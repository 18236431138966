export const environment = {
    
	production: true,
   
	TROLY_APP_URL: "https://api-eu.troly.co",
	TROLY_API_URL: "https://api-eu.troly.co",
	CLOUDINARY_URL: "cloudinary://147496331441477:uMHyknH7KTfKVtHWDrIiJTG6b6g@troly",
	
	GOOGLE_API_KEY_NOTES: "the GOOGLE_API_KEY needs access to Geocoding API, MAPS Embed and Google Drive (Troly API)",
	GOOGLE_API_KEY: "AIzaSyBiW5hiFMtBjLn8g93ofyvICR0_LHMF5GM",

	SENTRY_APP_DNS_NOTES: "every developer should have their own dev key if that's even needed in dev -- https://c3db16cdf1364dc0bd56fa2c40e96701@o4505255104086016.ingest.sentry.io/4505255106052096",
	SENTRY_APP_DSN: ""

};
