<div id="apps" selectable class="{{ containerCss }} {{ loading.records }}">
    <help *ngIf="helpCollection" [collection]="helpCollection"></help>
    <div class="{{ bsCssClassPrefix }}-header" *ngIf="cardTitle">
		<h4 class="{{ bsCssClassPrefix }}-title">
			<span [innerHtml]="cardTitle"></span>
			<socket-status [status]="ws.status"></socket-status>
		</h4>
		<p class="card-subtitle">Data communications between {{ selectedCompany.business_name }} and your <a routerLink="/apps/dashboard" [queryParams]="{status:'installed'}">connected systems</a>.</p>
    </div>
    <div class="{{ containerCss == 'card' ? 'card-body' : '' }}">
        <div class="row" *ngIf="toolbar">
            <div class="col-12 toolbar">
					<div class="toolbar-left">
                <span ngbDropdown *ngIf="toolbar == '_all' || toolbar.includes('status')" container="body">
                    <button
                        class="btn btn-sm btn-x-sm btn-primary btn-width-sm"
                        ngbDropdownToggle
                    >
                        <span>{{ __name + '.Filter.BySeverity.' + severityFilter | translate }}</span>
                        <i matSuffix class="bx bx-chevron-down"></i>
                    </button>
                    <div ngbDropdownMenu orderable class="dropdown-menu-sm">
							<ng-container *ngFor="let f of severityFilters">
								<h6 *ngIf="f[0] == '_'"
								>{{ __name + '.Filter.BySeverity.' + f | translate }}</h6>
                        <button *ngIf="f[0] != '_'" 
                            (click)="setQuery({severity:f})"
                            class="dropdown-item"
                            [ngClass]="{'selected': f == severityFilter}"
                            [disabled]="f == severityFilter"
                            >{{ __name + '.Filter.BySeverity.' + f | translate }}
                        </button>
							</ng-container>
                    </div>
                </span>
                <span ngbDropdown *ngIf="toolbar == '_all' || toolbar.includes('apps')">
                        <button
                            class="btn btn-sm btn-x-sm btn-primary btn-width-md"
                            ngbDropdownToggle
                            [disabled]="appsFilters.length <= 1"
                        >
                        
                            <span *ngIf="appsFilter.match('^_') || appsFilter.match('all$')">{{ __name + '.Filter.ByProcessor.' + appsFilter | translate }}</span>
                            <span *ngIf="!appsFilter.match('^_') && !appsFilter.match('all$')">{{ __name + '.Filter.ByProcessor.' + appsFilter | translate }}</span>
                            <i matSuffix class="bx bx-chevron-down"></i>
                        </button>
						  <div ngbDropdownMenu orderable class="dropdown-menu-sm">
                    <ng-container *ngFor="let f of appsFilters">
                        <h6 *ngIf="f[0] == '_'">{{ __name + '.Filter.ByProcessor.' + f | translate }}</h6>
                        <button 
                            *ngIf="f[0] != '_'" 
                            (click)="setQuery({app:f})"
                            class="dropdown-item"
                            [ngClass]="{'selected': f == appsFilter}"
                            [disabled]="f == appsFilter"
                            >

                            <span *ngIf="f.match('^_') || f.match('all$')">{{ __name + '.Filter.ByProcessor.' + f | translate }}</span>
                            <span *ngIf="!f.match('^_') && !f.match('all$')">{{ __name + '.Filter.ByProcessor.' + f | translate }}</span>
                        </button>
                    </ng-container>
                    </div>
                </span>
					</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
        
                <table class="table table-striped table-hover sortable">
                    <thead><tr>
                        <th></th>
								<th style="width: 130px;cursor:unset;" class="asc" sortable="created_at" (sort)="onSort($event)">
									<span *ngIf="applicationCol">{{ 'Models.IntegrationLog.Attributes.provider' | translate }}</span>
									<span *ngIf="!applicationCol">{{ 'Models.IntegrationLog.Attributes.created_at' | translate }}</span>
								</th>
                        <th [style.width]="'calc(100% - 130px)'">{{ 'Models.IntegrationLog.Attributes.message' | translate }}</th>
                        <th></th>
							</tr></thead>
                    <tbody>
                        <tr *ngFor="let log of records" [highlightable]="log">
                            <td>
										<div class="avatar-xs"><span class="avatar-title rounded-circle font-size-20 bg-soft" [ngClass]="{
											'bg-secondary' : log.severity == 'debug',
                                    'bg-success' : log.severity == 'info',
                                    'bg-warning' : log.severity == 'warning',
                                    'bg-danger' : log.severity == 'error'
										}">
											<i class="bx" [ngClass]="{
                                    'bxs-chevron-right-circle' : log.severity == 'debug',
                                    'bxs-check-circle' : log.severity == 'info',
                                    'bxs-info-circle' : log.severity == 'warning',
                                    'bxs-x-circle' : log.severity == 'error',

                                    'text-secondary' : log.severity == 'debug',
                                    'text-success' : log.severity == 'info',
                                    'text-warning' : log.severity == 'warning',
                                    'text-danger' : log.severity == 'error'
                                }"></i></span></div>
                            </td>
                            <td>
                                <h6 *ngIf="applicationCol">
                                    {{ 'Models.Integration.Values.provider.' + log.provider | translate }}
                                </h6>
										  	<p><a href (click)="!!toggleFromNow();" class="text-primary">
												<span *ngIf="!__fromNow">{{ moment(log.created_at).format(datetimeShort) }}</span>
												<span *ngIf="__fromNow">{{ moment(log.created_at).fromNow()  }}</span>
									  		</a></p>
                            </td>
                            <td>
										<ng-container *ngVar="selectedCompany.user(log.user_id) as user">
											<p *ngIf="!log.operation.match(' ')"><strong>{{ __name + '.Processor.Operations.' + log.operation | translate }}</strong>
												<span *ngIf="user && !user.isReadOnly(selectedCompany.id)">&nbsp;by {{ user.toString(false) }}</span>
											</p>
											<p *ngIf="log.operation.match(' ')"><strong>{{ log.operation }}</strong>
												<span *ngIf="user && !user.isReadOnly(selectedCompany.id)">&nbsp;by {{ user.toString(false) }}</span>
											</p>
										</ng-container>
											<p [innerHTML]="log.message"></p>
										  	<code *ngIf="selectedRecords.includes(log.id)">{{ log.data }}</code>
                            </td>
                            <td style="width: 20px;" class="text-center">
                                <span ngbDropdown container="body">
											<button ngbDropdownToggle>
													<i class="bx bx-dots-vertical-rounded"></i>
											</button>
											<div ngbDropdownMenu>
													<button *ngIf="advancedCol" ngbDropdownItem (click)="layoutAction({createIntegration:true, integration:{provider:log.provider}})">
														<i class="bx bx-cog"></i>
														<span>Configure {{ 'Models.Integration.Values.provider.'+log.provider | translate }}</span>
													</button>
													<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Order' && this.loggable_type != 'Order'" [routerLink]="['/orders',log.loggable_id]">
														<i class="bx bx-dollar-circle"></i>
														<span>Review Order</span>
													</button>
													<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Customer' && this.loggable_type != 'Customer'" [routerLink]="['/customers',log.loggable_id]">
														<i class="bx bx-user-circle"></i>
														<span>Review Customer</span>
													</button>
													<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Product' && this.loggable_type != 'Product'" [routerLink]="['/products',log.loggable_id]">
														<i class="bx bx-package"></i>
														<span>Review Product</span>
													</button>
													<button *ngIf="log.data" ngbDropdownItem (click)="toggleRecordSelection(log, $event, 1)">
														<i class="bx bx-bug"></i>
														<span>Technical Details</span>
													</button>
											</div>
                                </span>
                            </td>
                        </tr>
                    </tbody>

                    <caption *ngIf="isLoading('records') || records.length == 0" class="col-12 p-5">
                        <status-hint  
									icon="bx-tachometer"
                            [loading]="isLoading('records')"
                            class="text-secondary"
                            size="small"
                        >
								<p class="font-size-12" *ngIf="!loggable_id">No records were synchronized so far. Visit the <a routerLink="/apps/dashboard">Marketplace</a> to enable 3rd party systems and better access existing and new sales.</p>
                    		<p class="font-size-12" *ngIf="loggable_id">This record has not yet been synchronized. Visit the <a routerLink="/apps/dashboard">Marketplace</a> to enable 3rd party systems and better access existing and new sales.</p>
							</status-hint>
                    </caption>

                    <caption class="table-pagination" *ngIf="isLoaded('records') && totalRecords > pageSize">
                        <p>{{ recordRange }} of {{ totalRecords }} items</p>
                        <ul class="pagination justify-content-center">
                        <ngb-pagination [collectionSize]="totalRecords" [pageSize]="pageSize" [(page)]="currentPage"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                            (pageChange)="gotoPage($event)" class="pg-item"
                            [disabled]="loading.records != 'loaded'"></ngb-pagination>
                        </ul>
                        <p>
                            <select class="custom-select" [value]="pageSize" (change)="changePageSize($event)">
                                <option value="10">10 per page</option>
                                <option value="25">25 per page</option>
                                <option value="50">50 per page</option>
                                <option value="100">100 per page</option>
                            </select>
                        </p>
                    </caption>

                </table>
        
            </div>
        </div>
        
    </div>
</div>