import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { PaymentCard } from 'src/app/core/models/troly/payment_card.model';
import { uuid } from 'src/app/core/models/utils.models';
import { CompanyService } from 'src/app/core/services/troly/company.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';


export const EXPMMYYY_FORMAT = {
	parse: {
	  dateInput: 'MM/YYYY',
	},
	display: {
	  dateInput: 'MM/YYYY',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
 };

@Component({
  selector: 'create-credit-card-modal',
  templateUrl: './create-card.modal.html',
  styleUrls: ['./create-card.modal.scss'],
  providers: [
	// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
	// application's root module. We provide it at the component level here, due to limitations of
	// our example generation script.
	{
	  provide: DateAdapter,
	  useClass: MomentDateAdapter,
	  deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
	},

	{provide: MAT_DATE_FORMATS, useValue: EXPMMYYY_FORMAT},
 ]
})
export class CreatePaymentCardModal extends TrolyModal<PaymentCard> {

	__name:string='CreatePaymentCardModal'

	@Input() customer_id?: uuid;
	@Input() defaultName?: string;
	@Input() record:PaymentCard = new PaymentCard();

	_formFields: string[] = ['id', 'name', 'internal_notes', 'number', 'expiry', 'exp_month', 'exp_year', 'cvc', 'currency' ];

	constructor(
		protected customerService: CustomerService) {

		super();

		this.record = new PaymentCard(); // we doing this manually given the primary service here doesn't support making 'cards'
		this.initForm();
	}

	onSubmit(): void {
		if (this.customer_id) {
			this.onSubmitForm(this.customerService, this.customer_id)
		} else {
			this.onSubmitForm(this.companyService, this.selectedCompany.id)
		}
	}
	onSubmitForm(service:CustomerService|CompanyService, record_id:uuid): void {
		
		this.markAsLoading(this.form);
		
		let changes = this.form.getChanges()

		Object.assign(this.record, this.form.getRawValue());

		this.form.disable();
		service.createCard(new PaymentCard(changes), <any>service.make({id: record_id})).subscribe((_:PaymentCard) => {
			
			if (this.form.checkObjectErrors(_)) { // checkObjectError sets errors and reenables the form.
				this.markAsLoaded(this.form);
				this.form.enable();
			} else {
				this.form.resetCodes({success: (this.record.id ? 'SAVED':'CREATED') });
			}

			this.record = _;
			
			// delay redirection by a second so the user can see the success message
			this.form._timer = setTimeout(() => {
				// See routerNaviate: https://www.tektutorialshub.com/angular/angular-navigating-between-routes/
				this.resolveModal('timeout');
			}, this.form.countdown());
		
		}, (err:HttpErrorResponse) => {

			if (this.form.checkObjectErrors(err.error)) {
				this.markAsLoaded(this.form);
			} else {
				this.form.setApiError('4297-SERUM-yearling-dibble',this,'form');
			}
		});
  }

	protected receiveInputParams(changes: SimpleChanges): void {
		
		
		if (changes.record?.currentValue) {
			
			this.updateForm(); // super does this, but we need the form to be updated before we can disable fields. 
			
			if (this.record.id) {
				this.form.get('cvc').setValidators(null)
			} else {
				this.form.get('cvc').setValidators(this.record._cvc)
			}
		}
		
		if (changes.defaultName?.currentValue) {
			this.form.get('name').setValue(changes.defaultName.currentValue.toUpperCase());
		}
		
		super.receiveInputParams(changes);
		this.setTemporaryWindowTitle({ name: this.record.brand + ' ' + this.record.last4 });  // needs to be after the call to super to ensure nowEditingRecord is set correctly.
	}

	setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.form.get('expiry').value ? this.moment(this.form.get('expiry').value) : this.moment()
		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());
		this.form.get('expiry').setValue(ctrlValue);
		this.form.get('exp_month').setValue(ctrlValue.month(normalizedMonthAndYear.month()));
		this.form.get('exp_year').setValue(ctrlValue.year(normalizedMonthAndYear.year()));
		this.form.get('cvc').setValidators(this.record._cvc);
		this.form.updateValueAndValidity();
		datepicker.close();
	 }

}
