import { Component, Input, SimpleChanges } from '@angular/core';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { ITrolyLoadingStatus } from 'src/app/core/models/form_objects';
import { Company } from 'src/app/core/models/troly/company.model';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { PaymentCard } from 'src/app/core/models/troly/payment_card.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { uuid } from 'src/app/core/models/utils.models';
import { CompanyService } from 'src/app/core/services/troly/company.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyService } from 'src/app/core/services/troly/troly.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
	selector: 'cards-on-file',
	templateUrl: './cards-on-file.component.html',
	styleUrls: ['./cards-on-file.component.scss'],
})
export class CardsOnFileComponent extends TrolyComponent {

	@Input() mode: 'select-to-pay' | 'maintain' = 'maintain';
	
	@Input() company_id!: uuid;
	@Input() customer_id?: uuid;
	@Input() order_id?: uuid;

	@Input() cards:PaymentCard[]=[];
	@Input() preferredCard:uuid;
	@Input() backupCard:uuid;

	override selectedRecord:PaymentCard = new PaymentCard();

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected customerService: CustomerService = new CustomerService();

	
	override loading:{records:ITrolyLoadingStatus,record:ITrolyLoadingStatus,troly:ITrolyLoadingStatus}={records:undefined,record:undefined,troly:undefined};

	public operations:{[key:string]:string}={};
	public setPreferredCard(card:PaymentCard, attr:string) {
		this.operations[card.id.toString()] = "SAVING"

		let payload:Company|Customer;
		let service:CompanyService|CustomerService;
		if (this.customer_id) {
			payload = new Customer({id:this.customer_id})
			service = this.customerService
		} else {
			payload = new Company({id:this.selectedCompany.id})
			service = this.companyService
		}

		payload[attr] = card.id;
		
		(service as TrolyService<TrolyObject>).save(payload).subscribe((_) => {
			this.operations = {}
			this.operations[card.id.toString()] = "SAVED"
		})

	}

	public isPreferredCard(card):boolean {
		return this.preferredCard && card.id == this.preferredCard
	}
	public isBackupCard(card):boolean {
		return this.backupCard && card.id == this.backupCard
	}

	protected receiveInputParams(changes:SimpleChanges):void {

		if (changes.cards && changes.cards.currentValue) {
			this.cards = changes.cards.currentValue.map(_ => new PaymentCard(_)).sort( (c1,c2)=> c1.before(c2, false) );
			this.markAsLoaded('records');
		}
		super.receiveInputParams(changes);

		super.receiveInputParams(changes);
	}

	public publishCard(card:PaymentCard) {
		if (this.customer_id && this.customerService.record$.value.id == this.customer_id) {
			let customer = this.customerService.record$.value
			this.pushOrUpdateRecord(customer.cards, card)
			this.customerService.record$.next(customer)

		} else if (this.selectedCompany.id == this.company_id) {
			let company = this.companyService.record$.value
			this.pushOrUpdateRecord(company.cards, card)
			this.companyService.record$.next(company)
		}
	}


	public confirmRemove(card:PaymentCard, modalTitle, modalBody) {
		this.log(`${this.__name}.removeTag`, 'STACK');

		let options: SweetAlertOptions = this.alertService.alertOptions('warning', 'continue', 'cancel');
		options.title = modalTitle;
		options.html = modalBody;
		//options.footer = this.crispHelpdeskLink('Learn about scheduled membership orders','hkzlue');

		this.alertService.confirm(options).then((_) => {
			this.removeCard(card)
		})
	}

	public removeCard(card:PaymentCard) {
		if (this.customer_id) {
			this.remove(this.customerService, card.id, this.customer_id)
		} else {
			this.remove(this.companyService, card.id, this.company_id)
		}
	}

	protected remove(service:CustomerService|CompanyService, card_id:uuid, record_id:uuid): void {

		service.removeCard(card_id, <any>service.make({id:record_id})).subscribe({
			next: (_: PaymentCard) => {
				this.removeRecord(this.cards, _)
				this.service.snack('Card deleted', 'success');
			}, 
			error: (_) => {
				this.service.snack('Card could not be removed', 'error');
			}
		})
	}
}