<h4 class="p-3" style="font-weight: 800; color: var(--bs-gray-300)">{{ partner?.business_name || 'Getting Started' }}&nbsp;</h4>

<div class="card auto-height-flash-card">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">
		<div class="{{ bsCssClassPrefix }}-header">
			<div
					class="header-background bg-secondary bg-soft"
					*ngIf="!partner"
					style="
						background-image: url('https://res.cloudinary.com/subscribility-p/image/upload/q_90/cdn/static/app.troly.io/welcome-widget/summer.jpg');
					"
			></div>
			<div class="header-background bg-secondary bg-soft" *ngIf="partner" [style.background-image]="'url(' + partner.hero_img.url + ')'"></div>
		</div>
		<!-- card-header -->

		<div class="{{ bsCssClassPrefix }}-body pt-3" [ngClass]="{'contains-avatar':partner?.logo_img?.url}">
			<div class="row">
					<div class="col-12" *ngIf="partner">
						<div class="avatar-lg">
							<img src="{{ partner.logo_img.thumbnail.url }}" alt="" class="rounded-circle" />
						</div>
					</div>

					<div class="col-12 mt-3">
						<form-status [formGroup]="form" successAction="redirect">
							
							<span #status code="STEP1_RETRIEVING">Retrieving your account details from {{ partner?.business_name }}</span>
							<span #status code="COMPANY_CONFLICT">Hi! Have we seen you here before? Perhaps you're looking to <a href="/start/now">login</a> instead? In doubt, contact your account owner to regain access.</span>
							<span #status code="UNKNOWN_ERROR">Unexpected Error</span>

						</form-status>
					</div>
			</div>

			<div class="row" *ngIf="step == 1">

				<div class="col-12">
					<p>
						<strong>Welcome to Troly<span *ngIf="form.get('user.fname')?.value">, {{ form.get('user.fname').value }} 👋</span></strong><br />
						<span *ngIf="partner">You have been invited by <strong>{{ partner.business_name }}</strong>. </span>Tell us about you and your company.
					</p>
				</div>
				
				<ng-container formGroupName="user">
					<div class="col-6">
						<mat-form-field class="no-live-loading">
							<mat-label>First Name</mat-label>
							<input matInput formControlName="fname" type="text" [required]="true" />
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field class="no-live-loading">
							<mat-label>Last Name</mat-label>
							<input matInput formControlName="lname" type="text" />
						</mat-form-field>
					</div>
				</ng-container>

				<ng-container formGroupName="company">

					<div class="col-7">
						<mat-form-field>
							<mat-label>Your Website</mat-label>
							<input matInput type="url" formControlName="website_url" [required]="true" />
						</mat-form-field>
					</div>

					<div class="col-5">
						<mat-form-field class="no-live-loading">
							<mat-label>Your Specialty</mat-label>
							<mat-select formControlName="primary_variety" [required]="true">
								<mat-optgroup>{{ 'Components.SignupPage.ProductVarieties._foods' | translate }}
									<mat-option *ngFor="let item of foodVarieties | keyvalue" [value]="item.value">{{ 'Components.SignupPage.ProductVarieties.' + item.key | translate }}</mat-option>
								</mat-optgroup>
								<mat-optgroup>{{ 'Components.SignupPage.ProductVarieties._drinks' | translate }}
									<mat-option *ngFor="let item of drinkVarieties | keyvalue" [value]="item.value">{{ 'Components.SignupPage.ProductVarieties.' + item.key | translate }}</mat-option>
								</mat-optgroup>
								<mat-optgroup>{{ 'Components.SignupPage.ProductVarieties._others' | translate }}
									<mat-option *ngFor="let item of otherVarieties | keyvalue" [value]="item.value">{{ 'Components.SignupPage.ProductVarieties.' + item.key | translate }}</mat-option>
								</mat-optgroup>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-7">
						<mat-form-field class="no-live-loading">
							<mat-label>Company Name</mat-label>
							<input matInput type="text" formControlName="business_name" [required]="true" />
						</mat-form-field>
					</div>

					<ng-container *ngIf="allowChangingReferralCode">
					
						<div class="col-12">
							<mat-checkbox class="ps-1 mb-1" [checked]="referalCodeChecked$.value" (change)="referalCodeChecked$.next($event.checked)">
								I have a referral code
							</mat-checkbox>
						</div>
					
						<div class="col-5 offset-7 height-none" [ngClass]="{ 'height-auto': referalCodeChecked$.value }">
							<mat-form-field>
								<mat-label>Referral Code</mat-label>
								<input matInput type="text" formControlName="referral_by_code" />
								<mat-error *ngIf="form.get('company.referral_by_code').hasError('invalid_code')">This code apears to be invalid.</mat-error>
							</mat-form-field>
						</div>

					</ng-container>
					<div class="col-12" *ngIf="showExhaustedCodeWarning">
						<ngb-alert type="warning" class="ms-2 me-2" [dismissible]="false">
							<h6>Referral code is valid, but temporarily unavailable</h6>
							<p>The code you have received <strong>has now been exhausted</strong>. You may continue and you will be added to the waiting list. <span *ngIf="!allowChangingReferralCode">Alternatively you can <a href="" (click)="!! (allowChangingReferralCode = true) && false">enter a new code</a>.</span></p>
						</ngb-alert>
					</div>
				</ng-container>
			</div>

			<div class="row" *ngIf="step == 2">
					<div class="col-12">
						<p>
							<strong>Great work!</strong><br />
							<span *ngIf="partner">You have been invited by <strong>{{ partner.business_name }}</strong>. </span>
							Now let's take a minute to confirm a few products or services you have on offer.
						</p>
					</div>

					<ng-container *ngFor="let product of form.getControls('products'); let i = index" formArrayName="products">
						<ng-container [formArrayName]="i">
							<div class="col-8">
								<mat-form-field class="no-live-loading">
									<mat-label>Name</mat-label>
									<input matInput type="text" [required]="i < form.array('products').length-1" formControlName="name" (input)="conditionallyAddArrayElement(product.value, i, 'products')" (blur)="conditionallyRemoveArrayElement($event, i, 'products')" />
								</mat-form-field>
							</div>
							<div class="col-4">
								<mat-form-field class="no-live-loading">
									<mat-label>Retail Price</mat-label>
									<input matInput type="number" [required]="i < form.array('products').length-1" formControlName="price" (input)="conditionallyAddArrayElement(product.value, i, 'product')" (blur)="conditionallyRemoveArrayElement($event, i, 'products')" />
								</mat-form-field>
							</div>
						</ng-container>
					</ng-container>

					<p class="font-size-11 ms-1" *ngIf="form.getControls('products').length >= 4">You can add more and manage your products after sign up.</p>

					<div class="col-12 pt-3">
						<h6>Your greatest challenge</h6>
						<p>To better organise how Troly works for you, choose which of the following statement is most accurate for {{ form.get('company.business_name').value }}.</p></div>
					<div class="col-10 offset-1" formGroupName="user">
						<button *ngFor="let problem of problemsAtSignup"
							type="button" class="btn btn-outline-primary w-100 mb-3" 
							[class.active]="form.get('company.problem_at_signup').value === problem" 	
							(click)="form.get('company').patchValue({'problem_at_signup':problem})" 
							[innerHTML]="'Components.SignupPage.ProblemAtSignup.'+problem | translate">
						</button>
					</div>
			</div>

			<div class="row" *ngIf="step == 3">
				<ng-container formGroupName="user">
					<div class="col-12">
						<p>
							<strong>We're done!</strong><br />
							<span *ngIf="newUserSignup$.value">Let's secure access to your account, {{ form.get('user.fname').value }}. Enter your email and phone number where a password can be sent to you.</span>
							<span *ngIf="!newUserSignup$.value">Welcome back {{ form.get('user.fname').value }}, please confirm your account password to continue.</span>
							<span *ngIf="partner">Thanks to <strong>{{ partner.business_name }}</strong>, you are skipping the waiting list and get immediate, complete access to Troly.</span>
						</p>
					</div>

					<div class="col-12">
						<mat-form-field>
							<mat-label>Your Work Email</mat-label>
							<input matInput type="text" formControlName="email" placeholder="eg. you@work.com"/>
						</mat-form-field>
					</div>

					<div class="col-12" *ngIf="newUserSignup$.value">
						<mat-form-field class="no-live-loading">
							<mat-label>Your Phone number</mat-label>
							<input matInput formControlName="mobile" type="text" placeholder="eg. 0412 345 678" />
							<mat-hint>Must be able to receive SMS text messages</mat-hint>
						</mat-form-field>
					</div>
					<div class="col-12" *ngIf="newUserSignup$.value">
						<mat-form-field class="no-live-loading">
							<mat-label>Sales & Marketing experience</mat-label>
							<mat-select formControlName="expertise_level">
								<mat-option class="pt-2 pb-2" *ngFor="let level of ['beginner', 'intermediate', 'expert','guru']" [value]="level">
									<span class="badge" [ngClass]="{
										'bg-orange': level == 'beginner',
										'bg-purple': level == 'intermediate',
										'bg-primary': level == 'expert',
										'bg-pink': 	level == 'guru'
									}">{{ 'Components.SignupPage.ExpertiseLevel.Badges.' + level | translate }}: </span>
									<div [innerHTML]="'Components.SignupPage.ExpertiseLevel.Descriptions.' + level | translate"></div>
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-12" *ngIf="!newUserSignup$.value">
						<mat-form-field>
							<mat-label>Password</mat-label>
							<input *ngIf="!showPwdAsText" matInput formControlName="password" type="password" style="font-family:'Courier New', Courier, monospace;"
								[required]="form.isFieldRequired('password')" />
								<input *ngIf="showPwdAsText" matInput formControlName="password" type="text" style="font-family:'Courier New', Courier, monospace;"
								[required]="form.isFieldRequired('password')" />
							<i matSuffix class="bx cursor-pointer" [ngClass]="{
								'bx-show-alt':!showPwdAsText, 
								'bx-low-vision':showPwdAsText
							}" (click)="showPwdAsText=!showPwdAsText"></i>
							<mat-error>Confirm the password associated with this account</mat-error>
						</mat-form-field>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="{{ bsCssClassPrefix }}-footer p-3">
			<div>
				<button *ngIf="step > 1" type="button" (click)="goTo(-1)" class="btn btn-outline-secondary me-2">Back</button>
				<button type="submit" class="btn btn-primary {{ form.loading }}" [disabled]="form.cannotSubmit">Continue</button>
			</div>

			<p class="font-size-10 text-secondary text-start float-start mt-1">
					When submitting your information, you <br />
					agree to our
					<a href="https://troly.co/legal/terms" class="text-primary" target="_blank">Terms and Conditions</a>
			</p>
		</div>
	</form>
</div>
<!-- card -->

<div class="text-center">
	<p>Already have an account? <a [routerLink]="['/start/now']" class="fw-medium text-primary">Sign in</a></p>
</div>

<debug [form]="form"></debug>