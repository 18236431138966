import { Component, OnDestroy, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { RecentService } from 'src/app/shared/widgets/recent/recent.service';
import { Company } from '../models/troly/company.model';
import { User } from '../models/troly/user.model';
import { TrolyObject } from '../models/troly_object';
import { TrolyService } from '../services/troly/troly.service';
import { WindowService } from '../services/window.service';

// Even though this is called 'Component' we declare as a directive
// as that is the requirement for base class components in Angular 9+
@Component({
	selector: 'base-component',
	template: '',
})

// Base component that enables a single method of destroying observable
// subscriptions when a component is destroyed
export abstract class BaseComponent implements OnDestroy {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 * @see TrolyComponent.log
	 * @see IntrojsService.loadPage
	 */
	public readonly __name:string = 'BaseComponent';
	public readonly __path:string;

	// Setting the authenticatedUser/selectedCompany here reduces our code duplication
	// additionally, ALL components MAY (or may NOT -- see pages w/ public.layout) have a user+company record.
	// see https://www.codemag.com/article/1805021/Security-in-Angular-Part-1
	public authenticatedUser: User; 	 /* contains the currently authenticated user. */
	public selectedCompany: Company; /* contains the currently selected / active company */

	// when subscribing to an observable the child component
	// will use this in a `takeUntil()` to ensure the
	// subscription is destroyed when the child component is
	//
	// See: https://medium.com/@benlesh/rxjs-dont-unsubscribe-6753ed4fda87 (creator of RxJS)
	public observablesDestroy$ = new Subject();

	public windowService: WindowService = inject(WindowService);

	// We need to force the authService as a constructor to enable its use
	constructor() {
		this._debug = window.localStorage.getItem('de')?.split('|').filter(_ => _) || [];
	}


	// This is where the magic of unsubscribing all observables happens
	// if the child component ever needs to override `ngOnDestroy()`
	// it MUST MUST call `super.ngOnDestroy()` or else this BaseComponent
	// ngOnDestroy will not run and any observables will stay subscribed
	// causing memory leaks every single time that component is initialised
	// as more and more subscriptions will pile up that never get destroyed
	ngOnDestroy() {
		this.log(`${this.__name}.ngOnDestroy() via BaseComponent`, 'STACK');
		// unsubscribe when component is destroyed to avoid memory leaks
		//this.observablesDestroy$.complete();
		this.observablesDestroy$.next(true);
		this.observablesDestroy$.complete();
	}

	protected _debug: string[] = [];
	public log(message: string, level: string = '-') {
		if (this._debug.includes(level) || this._debug.includes('ALL')) {
			console.log(`[${level.toUpperCase()}] ${message}`);
		}
	}
	
	/**
	 * 
	 */
	public set debug(value:string[]) {
		this._debug = value
		window.localStorage.setItem('de', value.join('|'));
	}

	public err(message: string, level: string = '-') {
		if (this._debug.length > 0) {
			console.error(`[${level.toUpperCase()} ERR] ${message}`);
		}
	}

	/**
	 * When changing page (and tabs) the recentService allows to save the records visited 'recently.
	 */
	protected recentService: RecentService = inject(RecentService);
	
	public readonly COLOURS = {
		GREEN: '#34c38f', /// off-brand, based on the skote green pellets
		GREEN2: '#43a047', /// Troly Green
		BLUE: '#0288d1', // Troly blue
		YELLOW: '#fdca40', //Troly Yellow
		ORANGE: '#F57C00', // Trly orange
		PURPLE: '#9537C0', // Troly Purple
		PINK: '#e91e63', // Off-brand, based on the skote red pellets
		RED: '#dd4d4d', // Off-brand, based on the skote red pellets
		LIGHT: '#E0E1E1', // very light version of the dark accent
		SHADED: '#f8f8f8',
		GREY: '#999999',
		DARK: '#2f363a'
	}

	get getRandomHexColor(): string {
		const colors = Object.values(this.COLOURS);
		const randomIndex = Math.floor(Math.random() * colors.length);
		return colors[randomIndex];
	}

	/**
	* Pushes a new object in an array, update the object if it already is present, locating the object based on an optional attribute
	* @param collection array of objects
	* @param element object to add into the array collection
	* @param attr object attribute to compare two objects
	* @returns the updated collection
	*/
	protected pushOrUpdateRecord<T extends TrolyObject>(collection: T[], element: T | T[], attr: string = 'id', insertAt:'end'|'start'='end'): T[] {

		collection = collection || [];
		if (element instanceof Array) {
			element.forEach((_elem) => {
				collection = this.pushOrUpdateRecord(collection, _elem, attr, insertAt)
			})
		} else if (element && element[attr]) {
			let index=-1;
			
			if (element[attr]['_trolyModelName']) {
				index = collection.map(_ => _[attr] && _._trolyModelName == element._trolyModelName).indexOf(element[attr]);
			} else {
				index = collection.map(_ => _[attr]).indexOf(element[attr]);	
			}

			if (index >= 0) {
				collection[index] = Object.assign(collection[index], element);
			} else {
				if (insertAt == 'end') { collection.push(element); } 
				else { collection.unshift(element) }
			}
			//element['highlight'] = true;
		}
		return collection
	}

	/**
	 * 
	 * @param collection 
	 * @param element 
	 * @param attr 
	 * @returns 
	 */
	protected removeRecord<T>(collection: T[], element: T, attr: string = 'id'): T[] {

		collection = collection || []
		if (element) {
			let index = collection.map(_ => _[attr]).indexOf(element[attr]);
			if (index >= 0) {
				collection.splice(index, 1)[0]; // removing 1, returning the only one removed.
			}
		}
		return collection;
	}

	private _service: TrolyService<any>;
	protected get service(): TrolyService<any> {
		if (!this._service) { console.warn(`[${this.__name}].service is used without first being set.`); }
		return this._service;
	}
	protected set service(value: TrolyService<any>) {
		if (this._service) { console.warn(`[${this.__name}].service was set twice within the same context.`); }
		this._service = value;
	}
	protected readonly CACHE_KEYS = {
		'IS_CONDENSED': 'nav.is-condensed.persist',
		'IS_VERTICAL': 'nav.prefer-sidebar.persist',
		'DARK_MODE': 'system.dark-mode.persist',
		'DRAWER_MODE': 'layout.drawer-mode.persist',
	}

	public get darkMode(): 'on' | 'off' | 'auto' {
		return this._service?.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto');
	}

	/**
 	* Set/toggles the darkmode to the next status, or to a defined value -- called externally too // from the top bar navigation menu.
 	* @param value 
 	*/
	public set darkMode(value: null | 'on' | 'off' | 'auto') {
		value = value || (this.darkMode == 'auto' ? 'on' : (this.darkMode == 'on' ? 'off' : 'auto'))
		this._service?.storeUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, value);
	}

	/**
	 * Adds or remove a value from a number, provided either as a string or number, while keeping the same precision
	 * @param num the value to add or remove from
	 * @param delta the value to add or remove from the number received. Use a negative value to substract Defaults to 1
	 * @returns 
	 */
	public addRemoveWithPrecision(num:string|number, delta:number=1):number|string {
		
		// if the number is a float, subtract one from the most meafunction subtractOne(val) {
		let [whole, decimal] = num.toString().split('.');
		
		if (delta == 0) { return num }
		else if (decimal) {
			
			if (decimal.indexOf('.') >= 0) { throw (`addRemoveWithPrecision(${num},${delta}) -- there seems to be more than one decimal point (.) in the value provided`); }

			let newDecimal:number = (parseFloat(`0.${decimal}`)+delta);
			
			if (`${newDecimal}`.split('.')[0] != '0') {
				// if decimal was 0.9 and we have added a value pushing going over the decimal pt (eg 0.9 + 0.2 = 1.1)), 
				// then we need to add that vale to the whole number, and remove from the newDecimal
				whole = (parseInt(whole) + parseInt(newDecimal.toString().split('.')[0])).toString();
				newDecimal = (newDecimal < 0 ? -1 : 1) * parseFloat(`0.${newDecimal.toString().split('.')[1]}`)
			}

			newDecimal = newDecimal + parseInt(whole)

			// trim the floating point precision error -- see https://floating-point-gui.de/
			if (newDecimal.toString().match(/000000\d{1,2}$/)) { 
				newDecimal = parseFloat(`${newDecimal}`.replace(/000000\d{1,2}$/, ''));
			}
			
			// reconstruct the new number and return the same data type.
			return (typeof num == 'string' ? newDecimal.toString() : newDecimal);
			
		} else {
	
			// dealing with a number without decimals -- hence an integer is much easier.
			return (typeof num == 'string' ? (parseFloat(num)+delta).toString() : num+delta);
		}
	}

	protected smallestIncrement(num:number|string,up:boolean=true):number {
		let [whole, decimal] = num.toString().split('.');
		if (decimal) {
			if (decimal.indexOf('.') >= 0) { throw (`smallestIncrement(${num},${up}) -- Yo chico! seems to be more than one decimal point (.) in the value provided`); }
			const zeros = decimal.length;
			decimal = decimal[-1].padStart(zeros-1, '0');
			return parseFloat(`0.${decimal}`) * (up ? 1 : -1)
		} else {
			return up ? 1 : -1;
		}
	}

	// 888b     d888               888          888      888    888                        888 888 d8b
	// 8888b   d8888               888          888      888    888                        888 888 Y8P
	// 88888b.d88888               888          888      888    888                        888 888
	// 888Y88888P888  .d88b.   .d88888  8888b.  888      8888888888  8888b.  88888b.   .d88888 888 888 88888b.   .d88b.
	// 888 Y888P 888 d88""88b d88" 888     "88b 888      888    888     "88b 888 "88b d88" 888 888 888 888 "88b d88P"88b
	// 888  Y8P  888 888  888 888  888 .d888888 888      888    888 .d888888 888  888 888  888 888 888 888  888 888  888
	// 888   "   888 Y88..88P Y88b 888 888  888 888      888    888 888  888 888  888 Y88b 888 888 888 888  888 Y88b 888
	// 888       888  "Y88P"   "Y88888 "Y888888 888      888    888 "Y888888 888  888  "Y88888 888 888 888  888  "Y88888
	//                                                                                                               888
	//                                                                                                          Y8b d88P
	//                                                                                                           "Y88P"

	public selectedRecord?: Object; // used to "select" and pass a record to a modal window -- will not be overriden when the modal is open, but some attribute may be changed or added
	public openModal(template, vars?: TrolyObject|{}, options?: any): boolean {

		this.windowService.clearModal();
		
		// When opening a modal we need to forcefully reset the placeholder (shared with all modals)
		// This is a workaround to pass parameters DYNAMICALLY to the modal component in the template..
		// Assumes the [record] attribute of the modal was set to `selectedRecord`
		const previouSelectedRecord = this.selectedRecord;
		if (vars) {
			this.selectedRecord = (vars instanceof TrolyObject) ? vars : this.service.make(vars);
		}

		this.log(`${this.__name}.openModal() via TrolyComponent`, 'UI')
		if (this.windowService.readyToOpen()) {

			options ||= {}
			options['backdrop'] ||= 'static' // The default behaviour is to prevent clicking on the backdrop, but allow the escape key
			options['keyboard'] ||= true		// This allows to easily prevent a dirty form from being dismissed. See `TrolyModal.resolveModal`

			this.windowService.openModal(template, options);	
			
			if (vars) {
				this.windowService.currentModal.result.finally(() => {
					this.selectedRecord = previouSelectedRecord;
				});
			}
		}

		return false; // used to prevent navigation on (click)="!!openModal"
	}

	public openLargeModal(template, vars?: any, options?: any): boolean {
		options ||= {};
		options['size'] ||= 'lg';
		return this.openModal(template, vars, options);
	}

	public openXLargeModal(template, vars?: any, options?: any): boolean {
		options ||= {};
		options['size'] ||= 'xl';
		return this.openModal(template, vars, options);
	}



	private multiClickTracking:{[key:string]:number}={}
	protected checkMultiClick(key, total:number=2):boolean {
		
		key = `${this.__name}.${key}`
		
		this.multiClickTracking[key] = (this.multiClickTracking[key] || 0) + 1;

		setTimeout(() => { this.multiClickTracking[key]=0; }, 600)
		
		if (this.multiClickTracking[key] >= total) {
			this.multiClickTracking[key] = 0;
			return true;
		} else {
			return false;
		}
	}

}