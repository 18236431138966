import { ValidatorFn, Validators } from "@angular/forms";
import { TopbarNotification } from "../topbar.models";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Company } from "./company.model";
import { Customer } from "./customer.model";
import { Integration } from "./integration.model";
import { Order } from "./order.model";
import { Product } from "./product.model";
import { User } from "./user.model";
import { Warehouse } from "./warehouse.model";

export class Task extends TrolyObject {

	declare company_id: uuid
	declare name: string;
	_name: ValidatorFn[] = [Validators.required];
	
	declare details: string;
	declare due_date: Date;
	_due_date: ValidatorFn[] = [Validators.required];

	declare created_by_id: uuid;
	declare assigned_by_id: uuid;
	declare assigned_to_id: uuid;
	declare completed_at: Date;
	declare completed_by_id: uuid;

	declare taskable_id: uuid;
	declare taskable_type: 'Customer' | 'Integration' | 'Company' | 'Product' | 'Order';
	_taskable_type: ValidatorFn[] = [Validators.required];
	
	declare priority: 'low'|'med'|'high'
	declare status: 'pending'|'assigned'|'completed';
	declare progress_value: number;
	declare progress_notes: {created_at:Date, notes:string}[];
	declare latest_progress_notes: string;
	
	declare provider_data:{
		users: User[]
	}

	declare opportunity_id;
	declare opportunity_badge;
	declare opportunity_link;
	declare opportunity_linkParams;
	declare opportunity_linkFragment;
	declare opportunity_createIntegration;
	
	declare taskable: Customer | Integration | Company | Product | Order | Warehouse;
	
	constructor(values?: Object) {
		super('task', values);
		
		if (values && values['taskable']) {
			if (values['taskable_type'] == 'Product') { this.taskable = new Product(this.taskable['product']) }
			if (values['taskable_type'] == 'Warehouse') { this.taskable = new Warehouse(this.taskable['warehouse']) }
			if (values['taskable_type'] == 'Customer') { this.taskable = new Customer(this.taskable['customer']) }
			if (values['taskable_type'] == 'Integration') { this.taskable = new Integration(this.taskable['integration']) }
			if (values['taskable_type'] == 'Order') { this.taskable = new Order(this.taskable['order']) }
			if (values['taskable_type'] == 'Company') { this.taskable = new Company(this.taskable['company']) }
		}
	}

	public get usersInvolved(): User[] { return (this.provider_data?.users || []).map(_ => new User(_)) }
	public get assignee(): User | null {
		return super._user(this.assigned_to_id, this.usersInvolved)
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			badge: this.priority != 'med'	? this.priority : null,
			link: `/dashboard`,
			linkFragment: 'priorities',
			linkParams: { highlight: this.id } // will trigger the highlightable directive to highlight the record
		}, notification)) as TopbarNotification
	}

	public toString() : string {
		return this.name;
	}
}

export interface IOpportunity {
	badge:string
	createIntegration:string
	link:string
	linkFragment:string
	linkParams:{}
}