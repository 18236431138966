import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';

import { BehaviorSubject, Observable, distinctUntilChanged, filter, finalize, takeUntil, tap } from 'rxjs';
import { TrolyCard } from 'src/app/core/components/troly.card';
import { Company } from 'src/app/core/models/troly/company.model';
import { Product } from 'src/app/core/models/troly/product.model';
import { Signup } from 'src/app/core/models/troly/signup.model';
import { User } from 'src/app/core/models/troly/user.model';
import { TrolyFormGroup } from 'src/app/core/models/troly_form';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { SignupService } from 'src/app/core/services/troly/signup.service';
import { TrolyService } from 'src/app/core/services/troly/troly.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.page.html',
    styleUrls: ['./feedback.page.scss'],
})

/**
 * Signup component
 */

export class FeedbackPage extends TrolyCard {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */

	override readonly __name: string = 'SignupPage';

	declare record?: Signup;

	/**
	 * Whether the user is seeing thir pwd in clear text or not
	 */
	showPwdAsText: boolean = false
	
	/**
	 * Whether or not we want to show / capture the referral code. 
	 */
	public referalCodeChecked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public allowChangingReferralCode:boolean = true;
	public showExhaustedCodeWarning:boolean = false;

	public newUserSignup$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	public partner: Company = null;

	public readonly problemsAtSignup = this.shuffle(['seasonal_business','local_sales','limited_production'])
	
	protected _formFields = [
		{ company: 	['id', 'business_name', 'website_url', 'referral_by_code', 'primary_variety', 'problem_at_signup' ] }, 
		{ user: 		['id', 'fname', 'lname', 'email', 'mobile', 'expertise_level' ] }, 
		{ products: ['id', 'name', 'price'] }, 
	];

	/**
	 * 
	 */
	foodVarieties = { 'cheese':'food','meat':'meat','meat_alt':'meat_alt', 'produce':'produce', 'food':'food' }
	drinkVarieties = {  'wine':'wine','drink':'drink','beer':'beer','cider':'cider', 'spirit':'spirit' }
	otherVarieties = { 'art':'goods', 'events':'virtual', 'goods':'goods','tours':'virtual' }

	/**
 	 * Additional services used this this component. 
 	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
 	 */
	protected signupService: SignupService = inject(SignupService);
	protected cd:ChangeDetectorRef = inject(ChangeDetectorRef);

	/**
 	 * step 1 use for welcome page, step for 2 product and step 3 for account
 	 */
	public step: 0|1|2|3|4 = 1;

	constructor() {

		super();

		this.log(`${this.__name}.constructor`, 'STACK');

		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.signupService; // this also initialises the record.

		this.initForm();

		this.form.removeControl('id') // the form builder always adds an ID field, we don't want it here because the 'root' object is a signup object, which doesn't have an ID
	}

	/**
	 * This is an extension to initForm allowing to attach any change notifiers and act on them.
	 */
	protected attachChangeNotifiers(): void {

		// Validate the Referal Codes and Website URL when they change
		this.form.get('company.referral_by_code').valueChanges.pipe(filter(_ => !!_ && this.step == 1), distinctUntilChanged() ).subscribe(value => this.validateReferralCode(value));
		this.form.get('company.website_url').valueChanges.pipe(filter(_ => !!_ && this.step == 1), distinctUntilChanged() ).subscribe(value => this.validateCompanyWebsiteUrl(value));
		this.form.get('company.primary_variety').valueChanges.pipe(filter(_ => !!_ && this.step == 1), distinctUntilChanged() ).subscribe(value => this.updateAllProductsVariety(value));
		this.form.get('user.email').valueChanges.pipe(filter(_ => !!_ && this.step == 3), distinctUntilChanged() ).subscribe(value => this.validateUserEmail(value));

		// Make sure we stay in sync with the signup service' data
		this.signupService.record$.pipe(filter(_ => !!_), takeUntil(this.observablesDestroy$)).subscribe((_signup) => {
			this.partner = _signup.partner;
			if (_signup.products.length) { this.form.patchValue({products: _signup.products}) }
			this.signupService.storedSignup(_signup); // we store the signup in the service so that we can use it to update the user's session (if they're logged in already)
		});


		// Optionally capture a referral code, when requested, update the form validators. Note we also prevent changing this for referral (link) signups
		this.referalCodeChecked$.pipe(takeUntil(this.observablesDestroy$)).subscribe(value => this.updateFormValidatorsForStep())

		// Optionally capture mobile+experience based on whether we have a new user or not -- alternativel validate password.
		this.newUserSignup$.pipe(takeUntil(this.observablesDestroy$)).subscribe(value => this.updateFormValidatorsForStep());
		
		//
		this.form.get('products').valueChanges.pipe(distinctUntilChanged(),takeUntil(this.observablesDestroy$)).subscribe(values => this.updateFormValidatorsForStep());
	}

	/**
	 * Overrides `TrolyComponent.createArrayElement`
	 * @param attrs attributes already defined or set for the new product
	 * @param arrayName form array name we're trying to create a new object for
	 * @returns 
	 */
	protected override createArrayElement(attrs:{}, arrayName:string): TrolyObject {
		
		// override the default createArrayElement because:
		// 1 - no ID should be set for this new product, this is API-managed
		// 2 - additional attributes are set for the product

		if (this.form.get(arrayName).value.length >= 4) { return null; }

		let newElement = new Product(attrs);
		newElement['_formFields'] = ['name', 'price'];

		return newElement;
	}
	
	/**
	 * 
	 * @param i 
	 * @param arrayName 
	 * @returns 
	 */
	public removeNextArrayElement(i: number, arrayName:string):boolean {
		const item = this.form.get(`${arrayName}.${i+1}`).value
		return item.name == '' && (item.price == '' || item.price == 0);
	}

	// Handle the liveLoading behaviour of this form (changes onBlur)
	public onFieldUpdate<T extends TrolyObject>(service?: TrolyService<any>, form?: TrolyFormGroup, o?: TrolyObject, params?:{}): Observable<T> {
		// here we are driving a mixed form with BOTH onSubmit and onBlur actions -- hence we don't want the default behaviour to apply
		return new Observable<T>((observer) => { observer.complete() });
	}

	/**
	 * Loads initial data for the component, specifically handling referral codes.
	 * If a referral code is present in the route parameters, it attempts to validate
	 * the code and update the form state based on the validation response.
	 */
	protected loadData(): void {

		this.log(`${this.__name}.loadData()`, 'STACK');

		const previousSignup = this.signupService.storedSignup();
		if (previousSignup) { 
			this.form.patchValue(previousSignup.patchableValues(), this.NO_EMIT);
			this.signupService.record$.next(previousSignup); 
		}

		// If a referral code is present in the route parameters, set the form value so 
		// that it gets validated and a partner is loaded / shown
		if (this.routeParams.referral_code) {
			this.allowChangingReferralCode = false
			this.form.get('company.referral_by_code').setValue(this.routeParams.referral_code);
		}

		//this.userService.flushLocalStorage();
		if (this.authenticatedUser) { this.form.get('user').patchValue({fname:this.authenticatedUser.fname}); this.form.get('user.fname').markAsDirty(); }
	}

	/**
	 * validates the referral code and locks the option to not have referral code the field if the code is valid.
	 * @param code 
	 * @param lock 
	 */
	public validateReferralCode(code:string) {
		
		const c = new Company({ referral_by_code: code, business_name: this.form.get('company.business_name').value || this.signupService.genRanHex(8) });
		
		let params = {dryrun:true};
		if (this.routeParams?.partner_id) { params['partner_id'] = this.routeParams.partner_id }

		const obs = this.signupService.saveCompany(c, params).pipe(finalize(() => {
			this.form.doneSaving('company.referral_by_code');
		}));

		if (this.allowChangingReferralCode) { this.showExhaustedCodeWarning = false; }
		this.serviceSubmissionHandler(obs, 0)
	}

	/**
	 * Validates the company website URL entered. May also update the business name if one is found (by the API) and it is empty (in the form).
	 * @param url 
	 */
	public validateCompanyWebsiteUrl(url:string) {

		const c = new Company({ website_url: url, business_name: this.form.get('company.business_name').value /** empty business_name will force the backend to look it up */ });

		const obs = this.signupService.saveCompany(c,{dryrun:true}).pipe(tap(_ => {
			this.form.doneSaving('company.website_url');
			if (this.form.get('company.business_name').value == '') { this.form.patchValue({company:{ business_name:			_.business_name }}) } 	// if the api has found a business for ths website, we apply it
			if (this.form.get('company.primary_variety').value == '') { this.form.patchValue({company:{ primary_variety:	_.primary_variety }}) } // if the api has found a product variety, we apply it
		}));

		this.serviceSubmissionHandler(obs, 0)
	}

	/**
	 * Validates the company website URL entered. May also update the business name if one is found (by the API) and it is empty (in the form).
	 * @param url 
	 */
	public validateUserEmail(email:string) {

		const u = new User({ email: email, fname: this.form.get('user.fname').value });

		const obs = this.signupService.createUser(u,{dryrun:true}).pipe(tap(_ => {
			this.newUserSignup$.next(_.errors['email'] == null); // is the email is already taken, then we don't allow updating the mobile phone.
			this.form.doneSaving('user.email');
		}));

		this.serviceSubmissionHandler(obs, 0)
	}

	/**
	 * 
	 * @param variety 
	 */
	protected updateAllProductsVariety(variety:string) {
		this.form.array('products').controls.forEach((control, i) => {
			this.form.get(`products.${i}.variety`).setValue(variety);
		})
	}

	public updateFormValidatorsForStep(detect:boolean=true) {
		
		if (this.step == 1) {

			this.form.get('user.fname').setValidators([Validators.required]);
			this.form.get('company.business_name').setValidators([Validators.required]);
			this.form.get('company.primary_variety').setValidators([Validators.required]);
			this.form.get('company.website_url').setValidators([Validators.required]);


			if (this.referalCodeChecked$.value) {
				this.form.get('company.referral_by_code').addValidators([Validators.required]);
			} else {
				this.form.get('company.referral_by_code').removeValidators([Validators.required])
				this.form.get('company.referral_by_code').setValue(null);
			}
			/*!!!*/this.form.get('company.referral_by_code').updateValueAndValidity();

			this.form.get('company.problem_at_signup').removeValidators([Validators.required]); // when going back, we don't want the form to be invalid and 'continue' button to not be accessible
			/*!!!*/this.form.get('company.problem_at_signup').updateValueAndValidity(this.NO_EMIT);

			this.form.array('products').controls.forEach((control, i) => {
				this.form.get(`products.${i}.name`).removeValidators([Validators.required]);
				this.form.get(`products.${i}.price`).removeValidators([Validators.required]);
				this.form.get(`products.${i}.variety`).removeValidators([Validators.required]);
				this.form.get(`products.${i}.variety`).updateValueAndValidity(this.NO_EMIT);
			});

		}

		if (this.step == 2) {
			this.form.get('company.problem_at_signup').addValidators([Validators.required]);
			/*!!!*/this.form.get('company.problem_at_signup').updateValueAndValidity(this.NO_EMIT);
			
			//this.updateAllProductsVariety(this.form.get('company.primary_variety').value);
			this.form.array('products').controls.forEach((control, i) => {
				const oneValuePresent:boolean = control.value.name != '' || control.value.price != '';
				this.form.get(`products.${i}.name`).setValidators(oneValuePresent ? [Validators.required] : null);
				this.form.get(`products.${i}.price`).setValidators(oneValuePresent ? [Validators.required] : null);
			});

			if (this.form.array('products').length == 0) { this.addArrayElement('', 'products'); }


			// when going back, we don't want the form to be invalid and 'continue' button to not be accessible
			this.form.get('user.email').removeValidators([Validators.required]);
			this.form.get('user.mobile').removeValidators([Validators.required]);
			/*!!!*/this.form.get('user.email').updateValueAndValidity(this.NO_EMIT);
			/*!!!*/this.form.get('user.mobile').updateValueAndValidity(this.NO_EMIT);
		}


		if (this.step == 3) {
			if (this.newUserSignup$.value) { 
				this.form.get('user.mobile').addValidators([Validators.required]);
				this.form.get('user.mobile').enable();
			} else {
				this.form.get('user.mobile').removeValidators([Validators.required]);
				this.form.get('user.mobile').setValue(null);
				this.form.get('user.mobile').disable();
			}
			this.form.get('user.email').addValidators([Validators.required]);
			/*!!!*/this.form.get('user.email').updateValueAndValidity(this.NO_EMIT);
			/*!!!*/this.form.get('user.mobile').updateValueAndValidity(this.NO_EMIT);
		}

		this.form.updateValueAndValidity();
		if (false && detect) {
			this.cd.detectChanges(); // we're changing the required status which is applied via [required] attribute -- make sure the change detection runs
		}
	}

	/**
	 * Handles form submission for different steps in the signup or update process.
	 * This method determines the current step, prepares the payload based on form changes,
	 * and calls the appropriate service method to save or create resources.
	 */
	onSubmit() {
    
		this.markAsLoading(this.form);
		let changes = this.form.getChanges() as Signup

		if (this.step == 0) { 
			this.router.navigate(['/start/now']);
		}
		if (this.step == 1) { 
			this.serviceSubmissionHandler(this.signupService.saveCompany(new Company(changes.company)));
		}
		if (this.step == 2) { 
			
			let payload = changes.company;

			let nonEmptyProducts = changes.products.filter(_ => _.name != '').map(_ => {
				_.variety = payload.primary_variety; _.weight = 1; return _;
			});
			
			if (nonEmptyProducts.length > 0) { payload['products'] = nonEmptyProducts; }
			
			this.serviceSubmissionHandler(this.signupService.saveCompany(new Company(payload)));
		}
		if (this.step == 3) {
			this.serviceSubmissionHandler(this.signupService.createUser(new User(changes.user)))
		}
	}


	/**
	 * Reusable little function to handle interactions between the service and the form and simplify the component.
	 * @param observable
	 */
	protected serviceSubmissionHandler(observable: any, increment:number=1) {

		this.log(`${this.__name}.onSubmit()`, 'STACK');

		this.form.resetCodes();
		
		observable.subscribe({
			next: (_: Company | User) => {

				/**
				 * Handle any errors coming back from the API
				 */
				if (_.errors.website_url?.find(_ => _.match('has already been taken'))) 		{ this.form.get('company.website_url').setErrors({'invalid':true}); this.form.resetCodes({ error: 'COMPANY_CONFLICT' }); }
				if (_.errors.email?.find(_ => _.match('has already been taken'))) 				{ this.newUserSignup$.next(false); }

				if (_.errors.referral_by_code?.find(_ => _.match('allocation exhausted'))) 	{ this.referalCodeChecked$.next(true); this.showExhaustedCodeWarning = true; }
				if (_.errors.referral_by_code?.find(_ => _.match('invalid code'))) 				{ this.referalCodeChecked$.next(true); this.showExhaustedCodeWarning = false; this.form.get('company.referral_by_code').setErrors({'invalid_code':true}); }

				if (Object.keys(_.errors).length == 0) {
					/**
					 * If there's no errors we can move forward to the next step
					 */
					this.step += increment;

					if (this.step == 4) {
						
						this.userService.storedUser(this.signupService.record$.value.user);
						this.companyService.storedCompany(this.signupService.record$.value.company); 

						this.signupService.flushLocalStorage();

						if (!this.newUserSignup$.value || this.signupService.record$.value?.user?.companies?.length > 1) {
							this.router.navigate(['/start/now'], { fragment:'hello_new' }); // if a user has accesss to more than 1 company, they were a user before and must login
						} else {
							this.router.navigate(['/dashboard']); // session gets automatically created and email is sent to the user to set their password
						}
					} else {
						this.updateFormValidatorsForStep();
					}

					/**
					 * Update the form validators -- this wouldn't be necessary if we had multiple forms for each steps, however we are also capturing different details from different steps. (eg step 1 and 3 has user details)
					 */
					
				}
				this.markAsLoaded(this.form);
			},
			error: (err) => {

				if (err.status == 422) {
					/// we should never hit a 422 (Unprocessable entity) error here
					// if we do get a 422, it's because the CSRF token is invalid, and refreshing the page will reload the user/auth (hence token)
					window.location.reload();
				} else if (this.form.checkObjectErrors(err.error.company) == false || this.form.checkObjectErrors(err.error.user) == false) {
					this.form.setApiError('8291-window-HABIT-driver', this, 'form');
				}
				
			}
		});
	}


	/**
    * Decrements the current step in the multi-step form process.
    * This allows the user to navigate back to the previous step.
	*/
	goTo(increment:number) { this.step += increment; this.updateFormValidatorsForStep(); }

}
