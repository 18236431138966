import { ViewportScroller } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

//import * as LogRocket from 'logrocket';

export enum Layouts {
	Public,
	Admin
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {

	defaultAppTitle = 'Troly';

	Layouts = Layouts; // define internally the enum created above ????????
	layout: Layouts;

	viewport: ViewportScroller = inject(ViewportScroller)
	router: Router = inject(Router)

	ngOnInit(): void {

		this.viewport.setOffset([0, 141])

		// grab the routing data.layout parameter received and 
		// apply the correct layout
		this.router.events.subscribe({
			next: (data) => {
				if (data instanceof RoutesRecognized) {
					this.layout = data.state.root.firstChild.data.layout;
				}
			}
		});
	}
}
