import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import MetisMenu from 'metismenujs';

import { TrolyComponent } from 'src/app/core/components/troly.component';

import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { OrderService } from 'src/app/core/services/troly/order.service';
import { ProductService } from 'src/app/core/services/troly/product.service';
import { WebsocketService } from 'src/app/core/services/troly/websocket.service';
import { MenuItem } from './menu.model';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})

/**
 * Sidebar component
 */
export class SidebarComponent extends TrolyComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() isCondensed = true;

    menu: any;

    @Input() menuItems: MenuItem[] = [];

    @ViewChild('sideMenu') sideMenu: ElementRef;
    @Output() menuActionClicked = new EventEmitter<any>();

    constructor(
        protected ws: WebsocketService,
        protected customerService: CustomerService,
        protected productService: ProductService,
        protected orderService: OrderService,
        ) {
        super();

    }


    ngAfterViewInit() {
        //this.menu = new MetisMenu(this.sideMenu.nativeElement, { toggle: false });
        this.menu = new MetisMenu(this.sideMenu.nativeElement);

        this._activateMenuDropdown();
    }

    ngOnChanges(changes: SimpleChanges): void {

		if (this.sideMenu && this.menuItems && this.menuItems.length > 0) { // on change is also called on init, before the element was initialised (AfterViewInit)

			// In order to show badges on menu items AFTER expansion (not at the same time / causes z-index + wrap issues), 
			// we temporarily apply a class to HIDE the badges and subsequently remove that class, when expanding. 
            if (this.isCondensed) {
                this.sideMenu.nativeElement.classList.add('no-decorations')
            } else {
                setTimeout(() => {
                    this.sideMenu.nativeElement.classList.remove('no-decorations')
                }, 1000);
            }
            if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
                setTimeout(() => {
                    //this.menu = new MetisMenu(this.sideMenu.nativeElement, { toggle: false });
                });
            } else if (this.menu) {
                this.menu.dispose();
            }
        }
    }

    /**
     * remove active and mm-active class
     */
    _removeAllClass(searchClass, removeClass, unlessElType?) {
        const els = document.getElementsByClassName(searchClass);
        [].forEach.call(els, (el) => {
            if (unlessElType == undefined || el.tagName != unlessElType) {
                el.classList.remove(removeClass);
            }
        });
    }

    /**
     * Activate the parent dropdown
     */
    _activateMenuDropdown() {

        this._removeAllClass('mm-active', 'mm-active', 'LI');
        this._removeAllClass('mm-show', 'mm-show', 'UL');
        this._removeAllClass('side-nav-link-ref active', 'active');

        const links = document.getElementsByClassName('side-nav-link-ref');
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.href === links[i]['href']) {
                menuItemEl = links[i];
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.add('active');
            const parentEl = menuItemEl.parentElement;

            if (parentEl) {
                if (!parentEl.classList.contains('is-parent')) {
                    parentEl.classList.add('mm-active');
                }

                const parent2El = parentEl.parentElement.closest('ul');
                if (parent2El && parent2El.id !== 'side-menu') {
                    parent2El.classList.add('mm-show');
                    const parent3El = parent2El.parentElement;

                    if (parent3El && parent3El.id !== 'side-menu') {
                        if (!parent3El.classList.contains('is-parent')) {
                            parent3El.classList.add('mm-active');
                        }

                        const parent4El = parent3El.parentElement;
                        if (parent4El && parent4El.id !== 'side-menu') {
                            parent4El.classList.add('mm-show');
                            const parent5El = parent4El.parentElement;
                            if (parent5El && parent5El.id !== 'side-menu' && !parent5El.classList.contains('is-parent')) {
                                parent5El.classList.add('mm-active');
                            }
                        }
                    }
                }
            }
        }
    }

    showSubMenu(event: any) {
		
        if (!this.isCondensed) {
            const menuItem = event.target.querySelector('.is-parent');

            if (menuItem) {
                const subMenuList = event.target.querySelector('ul.sub-menu');

                if (subMenuList.classList.contains('mm-show')) {
                    subMenuList.classList.remove('mm-show');
                    menuItem.classList.remove('mm-active');
                } else {
                    subMenuList.classList.add('mm-show');
                    menuItem.classList.add('mm-active');
                }
            }
        }
    }

    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item: MenuItem) {
        return item?.subItems !== undefined ? item.subItems.length > 0 : false;
    }

    public onBadgeClick(action):boolean {
        action.stopPropagation();
        return true;
    }

}
