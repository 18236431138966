import { Injectable, inject } from '@angular/core';
import { Order } from 'src/app/core/models/troly/order.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { UserService } from 'src/app/core/services/troly/user.service';
import { Customer } from '../../../core/models/troly/customer.model';
import { Product } from '../../../core/models/troly/product.model';
import { uuid } from '../../../core/models/utils.models';

@Injectable({
    providedIn: 'root',
})
export class RecentService {

    private readonly CACHE_KEYS = {
		RECENT_VISIT: 'li',
		FAV_RECORDS: 'fa'
	 }

	 protected userService:UserService = inject(UserService);

	 constructor() {
		this.loadFavourites();
	 }
    private readonly MAX_LINKS_PER_MODEL = 50;

    protected company_id: uuid;

    public setCompanyId(id: uuid) {
        this.company_id = id;
    }

    public fetchList(modelName:string, company_id?:uuid): Array<any> {
        
        if (company_id) { this.company_id = company_id; }
        let key = `${this.CACHE_KEYS.RECENT_VISIT}_${this.company_id}`;
        let links = JSON.parse(localStorage.getItem(key)) || {};

        return links[modelName] || [];
    }

    public recordVisit(record: TrolyObject, company_id?: uuid, method: string = null): void {
        
        if (company_id) { this.company_id = company_id; }
        if (method) { method = `/${method}`; } else { method = '';}

        if (record == null || record['_trolyModelName'] == null) {
            
        } else {
            let key = `${this.CACHE_KEYS.RECENT_VISIT}_${this.company_id}`;
            let m = record._trolyModelName;

            let link = {
                //id: `${m}-${record.id}`,
                id: record.id,
                label: '',
                url: '',
					 firstAccess: new Date(),
					 lastAccess: new Date(),
					 accessCount: 1,
                meta: {}
            }
            
            switch (m) {
                case 'customer':
                    let c = record as Customer;
                    link.label = `${c.fname ? c.fname + ' ' + (c.lname || ' ')[0] : 'NO NAME'}`;
                    link.url = `/customers/${c.id}${method}`;
                    link.meta = { 
                        avatar_url: c.profile_img?.thumbnail.url,
                        gender: c.gender,
                        initials: `${c.fname ? c.fname[0]: ''}${c.lname ? c.lname[0] : ''}`,
                        membership: (c.currentMembership ? c.currentMembership : ''),
                        trade_account_enabled: c.trade_account_enabled
                    };

                    if (c.profile_img) {
                        //link.meta['avatar_url'] = `https://www.gravatar.com/avatar/${Md5.hashAsciiStr(c.email)}?s=32`;
                    }
                    break

                case 'product':
                    let p = record as Product;
                    link.label = p.name,
                    link.url = `/products/${p.id}${method}`;
                    link.meta = { 
                        avatar_url: p.hero_img?.thumbnail?.url || p.hero_img?.url,
                        variety: p.variety,
                        sku: p.number,
                    };
                    break
            }

            if (link.url != '') {

                let links = JSON.parse(localStorage.getItem(key)) || {};
                links[m] = links[m] || [];
                
                let existingIndex = links[m].findIndex((item) => item.id == link.id);

                if (existingIndex >= 0) {
						link.accessCount += links[m][existingIndex].accessCount || 0;
						link.firstAccess = links[m][existingIndex].firstAccess
                  links[m].splice(existingIndex, 1);
                }

					 if (link.accessCount > 8 && this.favourites[m].length == 0) {
						this.toggleFavourite(record);
					 }
                links[m].unshift(link);

                if (links[m].length > this.MAX_LINKS_PER_MODEL) { 
                    links[m].pop(); 
                }

				localStorage.setItem(key, JSON.stringify(links));
			}
		}
	}


	loadFavourites() {
		let links = JSON.parse(localStorage.getItem(this.CACHE_KEYS.FAV_RECORDS)) || {};
		Object.keys(links).forEach(key => {
			links[key].forEach(record => {
				if (record['_trolyModelName'] == 'customer') { this.favourites[key].push(new Customer(record)); }
				if (record['_trolyModelName'] == 'product') { this.favourites[key].push(new Product(record)); }
				if (record['_trolyModelName'] == 'order') { this.favourites[key].push(new Order(record)); }	
			});
		})

		this.favourites['order'] = this.favourites['order'].sort()
		this.favourites['product'] = this.favourites['product'].sort()
		this.favourites['customer'] = this.favourites['customer'].sort()
	}

	isFavourite(record:TrolyObject):boolean {
		return this.favourites[record._trolyModelName].findIndex(_ => _.sameAs(record)) >= 0
	}

	toggleFavourite(record: TrolyObject, e?:Event): void {

		if (e) { e.preventDefault(); e.stopPropagation(); }

		const presnceIndex = this.favourites[record._trolyModelName].findIndex(_ => _.sameAs(record))
		if (presnceIndex >= 0) {
			this.favourites[record._trolyModelName].splice(presnceIndex,1)
			this.userService.snack('Bookmark removed successfully')
		} else {
			this.favourites[record._trolyModelName].push(record.toLocalStorage());
			this.userService.snack('Bookmark created successfully')
		}
		this.favourites[record._trolyModelName] = this.favourites[record._trolyModelName].sort()
		localStorage.setItem(this.CACHE_KEYS.FAV_RECORDS, JSON.stringify(this.favourites));
	}

	public favourites:{
		'product':Product[],
		'customer':Customer[],
		'order':Order[]
	} = { 'product':[], 'customer': [], 'order':[] }

	
}
