import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { CompanyLockedGuard } from './core/guards/company-locked.guard';
import { RoleGuard } from './core/guards/role.guard';

import { Layouts } from './app.component';
import { FeedbackPage } from './pages/public/feedback.page';
import { RedirectPage } from './pages/public/redirect.page';

const standardCanActivates = [AuthGuard, CompanyLockedGuard, RoleGuard]

export const routes: Routes = [

    /**
     * readn this for routing modules and features; https://medium.com/swlh/angular-organizing-features-and-modules-e582611a720e
     */

    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

	 { path: 'q/:code', redirectTo: "/public/qr/:code" }, 	// used for qr-code handling
	 { path: 'feedback', redirectTo: "/public/feedback" }, 	// used for qr-code handling
	 { path: 'r/:code', redirectTo: "/start/fresh/:code" }, 	// used for referrals 'short'-link
	 { path: 'f/:code', redirectTo: "/public/feedback/:code" }, 	// used for referrals 'short'-link


	{
		path: 'public',
		loadChildren: () => import('./pages/public/public.module').then((m) => m.PublicModule),
		canActivate: [], data: { layout: Layouts.Public }
  	},

    {
        path: 'start',
        loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupModule),
        canActivate: [], data: { layout: Layouts.Public }
    },

    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    },
    {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then((m) => m.CustomersModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    },
    {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductsModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    },
    {
        path: 'apps',
        loadChildren: () => import('./pages/integrations/integrations.module').then((m) => m.IntegrationsModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    },
    {
        path: 'orders',
        loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/company.module').then((m) => m.CompanyModule),
        canActivate: standardCanActivates, data: { layout: Layouts.Admin }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // See: https://www.bennadel.com/blog/3545-enabling-the-second-click-of-a-routerlink-fragment-using-onsameurlnavigation-reload-in-angular-7-1-3.htm
        // Tell the router to use the hash instead of HTML5 pushstate.
        //useHash: true,
        // In order to get anchor / fragment scrolling to work at all, we need to
        // enable it on the router.
        anchorScrolling: "enabled",
        // Once the above is enabled, the fragment link will only work on the
        // first click. This is because, by default, the Router ignores requests
        // to navigate to the SAME URL that is currently rendered. Unfortunately,
        // the fragment scrolling is powered by Navigation Events. As such, we
        // have to tell the Router to re-trigger the Navigation Events even if we
        // are navigating to the same URL.
        onSameUrlNavigation: "reload",
        scrollOffset: [0, 141] // the offset is used to account for the fixed header and toppage nav
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { 
	static components = [ FeedbackPage, RedirectPage ];
}
