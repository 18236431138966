<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">
		<!-- Card on File -->
		<div class="{{ bsCssClassPrefix }}-header">
			<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!form.get('id')?.value">Add a new card</h4>
			<h4 class="{{ bsCssClassPrefix }}-title" *ngIf="form.get('id')?.value"><span>Update card <em>{{ record.brand }} *** {{ record.last4 }}</em></span></h4>

			<button type="button" class="btn-close" [disabled]="form.seconds > 0"
				(click)="resolveModal('x')"></button>
		</div>
		<div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

			<form-status [formGroup]="form" successAction="redirect" (dismissCallback)="resolveModal($event)"
				[record_label]="record?.toString()">
			</form-status>


			<div class="row">
				<div class="col-9">
					<mat-form-field>
						<mat-label>Name on card</mat-label>
						<input matInput formControlName="name" type="text" autocomplete="nope" [value]="defaultName"/>
					</mat-form-field>
				</div>
				<div class="col-3 text-center" *ngIf="!nowEditingRecord">
					<button type="button" [disabled]="true" class="mt-2 btn btn-md btn-outline-secondary">
						<i class="bx bxs-camera align-middle me-2 font-size-18"></i>
						<span>Scan</span>
					</button>
				</div>
				<div class="col-12"></div>
				<div class="col-6">
					<mat-form-field *ngIf="!nowEditingRecord">
						<mat-label>Card number</mat-label>
						<input matInput formControlName="number" type="text" minLength="14"
							maxLength="16" placeholder="#### #### #### ####" />
					</mat-form-field>

					<mat-form-field *ngIf="nowEditingRecord">
						<mat-label>Card number</mat-label>
						<input matInput value="{{record}}" [disabled]="true"/>
					</mat-form-field>
				</div>
				<div class="col-4">
					<mat-form-field>
						<mat-label>Expiry</mat-label>
						<input matInput [matDatepicker]="dp" formControlName="expiry" type="text" placeholder="MM/YYYY">
						<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
						<mat-datepicker #dp
							startView="multi-year"
							(monthSelected)="setMonthAndYear($event, dp)"
							panelClass="expiry-picker">
						</mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>CVV</mat-label>
						<input matInput formControlName="cvc" type="number" autocomplete="nope" [disabled]="nowEditingRecord && form.get('expiry').pristine"/>
					</mat-form-field>
				</div>
				<div class="col-4">
					<mat-form-field>
						<mat-label>Convert currency</mat-label>
						<mat-select formControlName="currency">
							<mat-option value=""></mat-option>
							<mat-option value="AUD">AUD ($)</mat-option>
							<mat-option value="CAD">CAD ($)</mat-option>
							<mat-option value="GBP">GBP (￡)</mat-option>
							<mat-option value="EUR">EUR (€)</mat-option>
							<mat-option value="NZD">NZD ($)</mat-option>
							<mat-option value="USD">USD ($)</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-8">
					<mat-form-field>
						<mat-label>Internal Notes</mat-label>
						<input matInput formControlName="internal_notes" type="text" />
						<mat-hint>Prevents using this card for automated payments in bulk</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="{{ bsCssClassPrefix }}-footer">
			<button type="submit" class="btn btn-primary {{ form.loading }}"
				[disabled]="form.cannotSubmit">
				Add
			</button>
		</div>
	</form>
</div>