import { AfterContentInit, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { TrolyComponent } from 'src/app/core/components/troly.component';


import { ViewEncapsulation } from '@angular/core';
import { ITrolyLoadingStatus, ITrolySocketMessage } from 'src/app/core/models/form_objects';
import { TopbarNotification } from 'src/app/core/models/topbar.models';
import { Company } from 'src/app/core/models/troly/company.model';
import { CompanyCustomer } from 'src/app/core/models/troly/company_customer.model';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { Integration } from 'src/app/core/models/troly/integration.model';
import { Membership } from 'src/app/core/models/troly/membership.model';
import { Order } from 'src/app/core/models/troly/order.model';
import { Product } from 'src/app/core/models/troly/product.model';
import { Task } from 'src/app/core/models/troly/task.model';
import { User } from 'src/app/core/models/troly/user.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { uuid } from 'src/app/core/models/utils.models';
import { IntrojsService } from 'src/app/core/services/introjs.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { OrderService } from 'src/app/core/services/troly/order.service';
import { ProductService } from 'src/app/core/services/troly/product.service';
import { WebsocketService } from 'src/app/core/services/troly/websocket.service';
import { FullscreenService } from 'src/app/shared/fullscreen.service';
import { SidenavService } from 'src/app/shared/sidenav.service';
import { MenuItem } from '../sidebar/menu.model';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
	encapsulation: ViewEncapsulation.None, // allows css styles from search-dropdown to be made available outside of the template itself
})

/**
 * Topbar component
 */
export class TopbarComponent extends TrolyComponent implements AfterContentInit {

	override readonly __name: string = 'TopbarComponent'
	override readonly __path: string = 'layouts/topbar'
	
	statusMessage = null;

	notificationMessages: any[] = [];
	helpOpen: boolean = false;
	helpUnread: number = 0;

	_formFields = ['pref_sample_data']
	_formDefaults: Object = { pref_sample_data: true };
	
	override loading: { companies:ITrolyLoadingStatus, integrations: ITrolyLoadingStatus, record: ITrolyLoadingStatus, troly: ITrolyLoadingStatus }={companies:undefined, integrations:undefined,record:undefined,troly:undefined};
	declare record:User; // no need to initalise the `.service` setter will do this

	@Input() menuItems: MenuItem[] = [];

	@Output() toggleLayoutPrefClicked = new EventEmitter();
	@Output() verticalSidebarCollapseClicked = new EventEmitter();
	@Output() helpRequestClicked = new EventEmitter<number>();
	@Output() menuActionClicked = new EventEmitter<any>();

	protected fullscreenService: FullscreenService = inject(FullscreenService);
	protected sidenavService: SidenavService = inject(SidenavService);
	protected ws: WebsocketService = inject(WebsocketService);

	constructor(
		public introjsService: IntrojsService,
		private productService: ProductService,
		private orderService: OrderService,
		private customerService: CustomerService,
		) {
		super();

		this.service = this.userService;
		this.initForm()

		setInterval(() => { this.statusProgress = this.statusProgress + '.'; if (this.statusProgress.length > 3) { this.statusProgress = ''; } }, 750);
	}

	public statusProgress:string=''

	// The best place to attach data loading (subscribes) and assign to forms.
	protected loadData() {

		super.loadData();

		this.log(`${this.__name}.loadData()`, 'STACK');

		this.companyService.windowService.indicator.pipe(takeUntil(this.observablesDestroy$)).subscribe((saveMessage) => {
			this.statusMessage = saveMessage;
		});

		this.notificationMessages = this.companyService.cachedDbKey(`${this.__name}.notificationMessages`) || []


		this.subscribeRecordDistinct<User>().subscribe(_user => {
			this.initRecordAttributeOnce('companies', this.userService.loadCompanies(), _user); // loadCompanies -- safe call within subscribeRecord, required to make sure we render a list of companies the current user has access to.
		})

		// !! here we are not attaching the companyService directly -- we're wanting to subscribe to notifications, no interested in ongoing company record updates.
		// this doesn't impact our usage of this.seletedCompany in the template as it DOES get updated.
		this.subscribeDistinct<Company>(this.companyService).subscribe((_company) => {
			
			this.initRecordAttributeOnce('integrations', this.companyService.loadIntegrations(), this.selectedCompany, this.companyService).subscribe(); // required to make sure we can display a list of connected dashboards.

			this.ws.connectCompanyChannel(this.selectedCompany.id)
				.subscribe((_msg: ITrolySocketMessage) => {

					let notification = { isUnread:true } as Partial<TopbarNotification>

					if (_msg.operation == 'new') {

						notification.status = 'new'
						
						if (_msg.model != 'order' || _msg.data['status'] == 'confirmed') {
							// Orders created as draft are not yet confirmed (for processing) and not worth notifying
							// Only orders created as confirmed (eg. by the website, or other app) because they need processing
							// orders imported as historical, or orders imported as comleted are notified either.
							this.companyService.incrementUiKey(`${_msg.model}-new-count`);
						}


						if (_msg.model == 'customer') {
							notification = new Customer(_msg.data).toNotification(notification);
						}

						if (_msg.model == 'product') {
							notification = new Product(_msg.data).toNotification(notification);
						}
						if (_msg.model == 'order' && ['confirmed','packed','labelled'].includes(_msg.data['status'])) {

							// Orders created as draft are not yet confirmed (for processing) and not worth notifying
							// Only orders created as confirmed (eg. by the website, or other app) because they need processing
							// orders imported as historical, or orders imported as comleted are notified either.
							notification = new Order(_msg.data).toNotification(notification);
						}
						if (_msg.model == 'task') {
							if (_msg.data['created_by_id'] != this.authenticatedUser.id) {
								notification = new Task(_msg.data).toNotification(notification);
							}
						}
						if (_msg.model == 'integration') {
							
							// Integrations are installed and almost immediately configured, so there's no need to detect the creation of the record
							notification = new Integration(_msg.data).toNotification(notification);

							this.pushOrUpdateRecord(this.selectedCompany.integrations, notification.data)
							this.companyService._next(this.selectedCompany);
						}
						if (_msg.model == 'membership') {
							notification = new Membership(_msg.data).toNotification(notification);
						}

						if (notification) {
							this.companyService.incrementUiKey(`${_msg.model}-new-count`);
						}
					}

					if (_msg.operation == 'update') {
						if (_msg.model == 'company') {
							this.companyService._next(new Company(_msg.data)); // the company record notifier is always connected to the currently selectedCompany and this will be enforced via  _next
						} else if (_msg.model == 'user') {
							this.userService._next(new User(_msg.data)); // the user record notifier is connected to the currently authenticated user
						} else if (_msg.model == 'product') {
							this.productService._next(new Product(_msg.data)) // the product record notifier is connected to the product currently being edited
						} else if (_msg.model == 'customer') {
							this.customerService._next(new Customer(_msg.data)) // the user record notifier is connected to the customer currently being edited
						} else if (_msg.model == 'company_customer') {
							let currentCustomer = this.customerService.record$.value
							if (_msg.data['customer_id'] == currentCustomer?.id) {
								// When receive a company_customer, if the customer currently loaded matches, we can update it and send notifications
								this.pushOrUpdateRecord(currentCustomer.company_customers, new CompanyCustomer(_msg.data))
								this.customerService._next(currentCustomer)
							}

						} else if (_msg.model == 'integration') {
							
							notification = new Integration(_msg.data).toNotification(notification);

							const current = this.selectedCompany.addon((_msg.data as Integration).provider)
							const newStatus = (_msg.data as Integration).status 

							if (current && current.status != newStatus && ['uninstalled', 'ready', 'error','warning'].includes(newStatus)) {
								// notifications are only relevant on status change
								// and only when there's an error, or the app is good to go. we don't care for configuration changes.
								
								this.pushOrUpdateRecord(this.selectedCompany.integrations, new Integration(_msg.data))
								this.companyService._next(this.selectedCompany);
							}
						} else if (_msg.model == 'order') {
							this.orderService._next(new Order(_msg.data)) // ditto -- the order record notifier is connected to the order currently being edited
						}
						// we don't really manage other records with full editing screens, they are normally loaded, quickly edited and that's it.
						// as a result, it's better for the individual component, when needed to attach the socket directly.
						// see for instance the CustomersClubsCard around `this.attachRecordsSocket`
					}

					if (_msg.model == 'company_customer') {
						const existingNotification = this.notificationMessages.find(_ => _.type == 'customer' && _.data.id == _msg.data['customer_id'])
						if (existingNotification) {
							notification = Object.assign(existingNotification, notification)
							notification.data['company_customers'] = [new CompanyCustomer(_msg.data)]
						}
					}

					if (notification.data) { 
						this.pushOrUpdateRecord(this.notificationMessages, notification, 'id', 'start')
						this.notificationMessages = this.notificationMessages.filter(_ => _.isUnread || this.dateNow.diff(_.timestamp, 'hour') > 24 )
						this.companyService.storeDbKey(`${this.__name}.notificationMessages`, this.notificationMessages)
					}
				});

		});

		this.ws.connectUserChannel(this.authenticatedUser.email)
			.subscribe((_) => {
				console.log(`${this.__name} received USER socket`)
				console.log(_)
			});
	}

	public get topBarApps(): Integration[] {
		const apps = this.selectedCompany.addonsInstalled().filter(_ => _.env?.admin_url) || []
		
		const appsClicked = this.companyService.cachedUiKey(`${this.__name}.appsClicked`, {})

		return apps.sort((a,b) => appsClicked[a.provider] && appsClicked[a.provider] != appsClicked[b.provider] ? (appsClicked[b.provider] || 0) - appsClicked[a.provider] : a.name.localeCompare(b.name));
	}

	/**
	 * If notification is specified it will be marked as read and user redirected, otherwise all notifications will be might as seen
	 * @param notification 
	 */
	readAndNavigate(notification?:TopbarNotification): boolean {
		
		if (notification) {
			this.companyService.storeDbKey(`${this.__name}.notificationMessages`, this.notificationMessages)
			notification.isUnread = false;

			if (notification.layoutAction) {
				this.layoutAction(notification.layoutAction);
			} else if (notification.link) {
				this.router.navigate([notification.link], { fragment:notification.linkFragment, queryParams: notification.linkParams })
			}

		} else {
			this.markAsLoading('record');
			this.notificationMessages.map((o) => { o.isUnread = false; })
			this.companyService.storeDbKey(`${this.__name}.notificationMessages`, this.notificationMessages)
			setTimeout(_ => { this.markAsLoaded('record') }, 250)
		}
		return false
	}
	public get hasUnreadNotifications():number {
		return this.notificationMessages.filter(_ => _.isUnread).length
	}
	public get sortedNotifications(): TopbarNotification[] {
		return this.notificationMessages ? this.notificationMessages.sort((a,b)=>b.timestamp - a.timestamp) : []
	}

	public get hasNewBadge(): number {
		return this.authenticatedUser.badges?.length || 1
	}

	ngAfterContentInit(): void {
		if ((<any>window).Headway) { (<any>window).Headway.init(); }
		if ((<any>window).Intercom) {

			// update the unread count number when available
			(<any>window).Intercom('onUnreadCountChange', (unreadCount) => { this.helpUnread = unreadCount; });

			// mark the help as open/closed when managed from the intercom widget
			(<any>window).Intercom('onHide', () => { this.helpOpen = false; });
			(<any>window).Intercom('onShow', () => { this.helpOpen = true; });
		}
	}

	onSearchSelect(selection:TrolyObject): void {

		this.log(`${this.__name}.onSelect`, 'STACK');

		// depending on our selections class we redirect
		// to a different location
		let optionLocation = '';
		let queryParams = {};

		switch (selection._trolyModelName) {
			case 'customer':
//				const selectedCustomerTab = this.router.url.matches()
				optionLocation = `/customers/${selection.id}`;
				const selectedCustomerTab = this.router.url.match(/\/customers\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(\/[a-z]*)/)
				if (selectedCustomerTab) { optionLocation += selectedCustomerTab[1] }
				break;
			
			case 'order':
				optionLocation = `/orders/${selection.id}/process`;
			
			case 'product':
				optionLocation = `/products/${selection.id}`;
				const selectedProductTab = this.router.url.match(/\/products\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(\/[a-z]*)/)
				if (selectedProductTab) { optionLocation += selectedProductTab[1] }

				const selectedOrderTab = this.router.url.match(/\/orders\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(\/[a-z]*)?/)
				if (selectedOrderTab) { 
					optionLocation = `/orders/${selectedOrderTab[1]}`;
					if (selectedOrderTab[2]) { optionLocation += selectedOrderTab[2] }
					queryParams['add_product'] = selection.id;
				}

				break;
		}

		// now redirect to the selected option
		this.router.navigate([optionLocation], { queryParams: queryParams });
	}

	/**
	 * Logout the user
	 */
	public logout() {
		this.authService.logout('thanks');
	}

	toggleHelp(event: any) {
		event.preventDefault()
		this.helpOpen = !this.helpOpen;
		this.helpRequestClicked.emit(!this.helpOpen ? -1 : this.helpUnread);
	}

	/**
	 * Toggles the right sidebar
	 */
	toggleLayoutPref(event: any) {
		event.preventDefault();
		this.toggleLayoutPrefClicked.emit();
	}

	/**
	 * Toggle the menu bar when having mobile screen
	 */
	toggleVerticalCollapse(event: any) {
		event.preventDefault();
		this.verticalSidebarCollapseClicked.emit();
	}


	/**
	 * Switch the active company to another
	 */
	async switchCompany(company_id: uuid) {
		if (company_id != this.selectedCompany.id) {
			const region = this.authenticatedUser.company_user(company_id).db_region as any
			this.authService.changeCompany(company_id, region);
		}
	}

	/**
	 * Returns true or false if given menu item has child or not
	 * @param item menuItem
	 */
	hasItems(item: MenuItem) {
		return item?.subItems !== undefined ? item.subItems.length > 0 : false;
	}

	public onMenuClick(action): boolean {

		if (action) {
			this.menuActionClicked.emit(action)
		}
		return false;
	}

	
	public toggleDebug(event: any) {
		event.preventDefault();
		if (this._debug[0] == 'ALL') {
			super.debug = [];
		} else {
			super.debug = ['ALL'];
		}
	}
}