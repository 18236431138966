import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { uuid } from 'src/app/core/models/utils.models';

@Directive({
    selector: '[highlightable]',
    host: {
        "[class.bx-flash]": "highlightable && (highlightable.id == highlightedId || recentlyUpdated) && !mode",
		  "[class.bx-flash-fast]": "highlightable && (highlightable.id == highlightedId || recentlyUpdated) && mode == 'fast'",
    },
})
export class HighlightableDirective implements OnChanges {

	@Input() highlightable:TrolyObject = null;
	@Input('highlightable.mode') mode:'fast';
	@Input('highlightable.updates') showUpdates:boolean=true;

	protected timeoutClock;

	protected recentlyUpdated:boolean=false;
	protected highlightedId:uuid;
	 
	constructor(protected element: ElementRef, protected route: ActivatedRoute) {

		this.route.queryParams.pipe(filter(_ => _['highlight'])).subscribe((_) => this.highlightedId == _['highlight'])

	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.highlightable.currentValue) {

			let bestDate = this.showUpdates ? (this.highlightable.updated_at || this.highlightable.created_at) : this.highlightable.created_at;
			if (bestDate) {
				
				if (typeof bestDate == 'string' ) {  
					// highlightable is coming from [highlightable] and it seems like the 'class' typecast gets lost when it's taken out of a object attribute
					// when loading a list of customers, that's fine, but when looking at a listo of company.packaging (which ARE objects, with _trolyModelName), 
					// but the typecast doesn't exist and it lands here as an anonymous hash, so we need to re-typecast it
					bestDate = new Date(bestDate); }
				this.recentlyUpdated = (new Date().getTime() - bestDate.getTime()) <= (this.mode == 'fast' ? 1500 : 30000)

				if (this.recentlyUpdated) {
					if (this.timeoutClock) { clearTimeout(this.timeoutClock); }
					this.timeoutClock = setTimeout(() => {
						this.recentlyUpdated = false; // when a record is updated, we force the highlight to "reset"
					}, 10000)
				}
			}

			if (this.highlightable.id == this.highlightedId) {
				this.element.nativeElement.scrollIntoView({behavior: "smooth", block: "start" }) // this could cause problems if we have different things on a screen being scrolled to simultaneously
			}
		}
	}
}
