import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Integration } from 'src/app/core/models/troly/integration.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { IntegrationService } from 'src/app/core/services/troly/integration.service';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';
import { SweetAlertOptions } from 'sweetalert2';
import { ListAppsEventsCard } from '../../integrations/list-apps-events/list-apps-events.card';

@Component({
	selector: 'create-integration-modal',
	templateUrl: './create-integration.modal.html',
	styleUrls: ['./create-integration.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule, ListAppsEventsCard]

})
export class CreateIntegrationModal extends TrolyModal<Integration> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'IntegrationModal';
	override readonly __path:string = 'shared/ui/quick-create/integration';

	@Input() focusOn: 'info'|'config'|'ops'|'track'|'request'|'help' = 'info'; /*'info' | 'config' | 'ops' | 'track' | 'request' | 'help' = 'info';*/
	
	// the record is always received and must have AT LEAST a provider value.
	@Input() record?: Integration = new Integration();
	
	// the record is A) loaded as installed from /companies/:id/integrations/:provider, and if not, B) loaded as standard from /integrations/:provider -- both include config data

	_formFields = [];
	_formDefaults = {};

	private integrationService: IntegrationService = inject(IntegrationService); // used to save (and retrieve) the record being dealth with

	constructor() {
		super();

		this.service = this.integrationService;
		this.integrationService.currentLang = this.translateService.currentLang // this enables auto-translation of integrations objects
		this.form = new TrolyObject().toFormGroup();

		this.markAsLoading(this.form)

	}

	protected receiveInputParams(changes: SimpleChanges): void {
		this.log(`${this.__name}.receiveInputParams()`, 'STACK');
		if (changes.record && changes.record.currentValue) {
			this.record = new Integration(changes.record.currentValue);
		}
		super.receiveInputParams(changes);
	}

	setupFormToInstall(): void {

		this.setFocus('info');

		this._formFields = ['code', 'provider']
		this._formDefaults = {
			code: this.record.env?.oauth2_url ? 'pending' : '',
			provider: this.record.provider
		};

		this.initForm();

		if (this.route.snapshot.queryParams['code']) {
			// if the URL includes a code, then we are ready to launch the install process (oauth is done)
			this.patchDirty({code:this.route.snapshot.queryParams['code']});
			// we have received a code, let's continue the install
			this.onInstall();
		}

	}

	setupFormToConfigure(): void {

		this._formFields = ['id','send_notifications_to','show_in_navigation']
		this._formDefaults = {}

		this.initForm();

		let params = {};

		this.record.fieldConfigs().forEach((f) => {
			if (f.name[0] != '_' && this.record.params) {
				params[f.name] = this.fb.control(this.record.params[f.name], f.validations);
			}
		})

		this.form.addControl('params', this.fb.group(params));
		//this.form.patchValue({ params: this.record.params })
		this.form.updateValueAndValidity();
		
		this.markAsLoaded(this.form)
		this.form.enable()

		
		// apps available on request can be activated behind the scene and configured
		if (!this.record.isInstalled() && this.record.isRequestOnly()) { this.setFocus('request'); }

		else if (this.record.isReadyForConfig()) { this.setFocus('config'); }
		else if (this.record.isInstalled()) {
			// handle the ()Input param for focusOn
			this.setFocus('track');
		}
		else { this.setFocus('info'); }

	}

	setupFormToRequest(): void {

		this._formFields = ['provider','status'];//'usage','frequency','urgency', 'budget','budget:none','budget:some','budget:sponsor','budget:ongoing']
		this._formDefaults = { status: 'requested', request_details: { budget:[] } }

		this.initForm();

		this.form.addControl('request_details', this.fb.group({
			usage: this.fb.control('', [Validators.required]),
			frequency: this.fb.control('', [Validators.required]),
			urgency: this.fb.control('', [Validators.required]),
			budget: this.fb.control([], [Validators.required])
		}));

		this.form.patchValue(this.record.patchableValues())

		if (this.record.status == 'requested') { this.form.disable(); }
	}

	/// init forms and vars at the most appropriate time
	protected loadData(): void {

		super.loadData();

		this.log(`${this.__name}.loadData()`, 'STACK');

		// note: the /companies/:id/integration/:id_or_provider may auto-install if required, or throw a 404 error if not found
		this.integrationService.find(this.record.id || this.record.provider).subscribe({
			next: (_integration: Integration) => {

				this.record = _integration
				
				this.pushOrUpdateRecord(this.selectedCompany.integrations, this.record, 'provider');
				this.companyService._next(this.selectedCompany);
				
				if (this.record.layout && !this.record.fieldConfigs()) { debugger; }

				this.setTemporaryWindowTitle({ name: this.record.name });

				if (this.record.isInstalled()) {
					this.setupFormToConfigure();
				} else if (this.record.isRequestOnly()) {
					this.setupFormToRequest();
				} else {
					this.setupFormToInstall();
				}

				this.markAsLoaded('record');
			},

			error: (err) => {
				if (err.status == 404) { // never installed
					this.integrationService.find(this.record.provider).subscribe((_integration) => {
						this.record = _integration;
						this.markAsLoaded('record');
						this.setupFormToInstall();
					});
				} else {
					this.service.snack('Could not be loaded', 'error');
					this.resolveModal('close');
				}
			}
		});


	}

	/**
	 * The best place to attach 'changes' hooks such as `valueChanges` and `statusChanges`. 
	 * This method must be overrident in the component itself.
	 */
	protected attachChangeNotifiers() {
		this.log(`${this.__name}.attachChangeNotifiers()`, 'SUBSTACK');

		if (this.route.snapshot.queryParams['error']) {
			// default oauth error parameter -- provider sends ?error=redirect_uri_mismatch#oauth_error or else.
			this.form.resetCodes({ error: 'OAUTH_ERROR_REMOTE' });
		}
	}

	onInstall(): void {

		this.log(`${this.__name}.onInstall()`, 'STACK');

		this.markAsLoading(this.form);

		if (this.record.env.oauth2_url && this.form.get('code').value == 'pending') {
			// if the code is the placeholder we have placed in _formDefaults, then redirect to the oauth mechanism
			window.location.href = this.record.env.oauth2_url;
			return; // navigation triggers ngDestroy -- this ensures we do not run any code past this point

		} else if (this.form.valid) {

			let payload = {provider:this.record.provider};
			if (this.record.env.oauth2_url) {
				// if we have an oauth process enabled, and a code has come through, let's indicate as such.
				this.form.resetCodes({ info: 'OAUTH_CODE_CHECK' });
				payload['code'] = this.form.get('code').value;
			}

			this.integrationService.create(new Integration(payload)).subscribe((_integration: Integration) => {

				if (['connected', 'ready'].includes(_integration.status)) {

					this.record = _integration;
					
					this.pushOrUpdateRecord(this.selectedCompany.integrations, _integration);

					this.companyService._next(this.selectedCompany);
					
					this.setupFormToConfigure();
					this.form.resetCodes({ success: (_integration.status == 'ready' ? 'READY' : 'INSTALLED') })

					this.form._timer = setTimeout(() => {
						this.resolveModal('hide');
					}, this.form.countdown(6));
					
				} else if (this.form.code == 'OAUTH_CODE_CHECK') {
					
					this.form.checkObjectErrors(_integration);
					this.form.resetCodes({ error: 'OAUTH_ERROR_LOCAL' });
					
					this.markAsLoadingError(this.form);
					this.form.reset(); // prevents

				} else {
					console.error(_integration);
					this.form.setApiError('8923-PERUSE-administration-cube', this as TrolyComponent, this.form);
				}
			});

		} else {
			// when would this happen...? the submit button should be disabled until the form is valid
			console.error('not-sure-where-this-would-happen-213  ?')
		}
	}

	onSave(): void {

		this.log(`${this.__name}.onSave()`, 'STACK');
		this.markAsLoading(this.form);

		let preStatus = this.record.status;

		this.onFormUpdate(this.integrationService, this.form, this.record).subscribe((_integration) => {

		//this.integrationService.save(new Integration(this.form.getRawValue())).subscribe((_integration) => {

			// numerous integration properties (translations, urls, etc) are returned by the GET request, but not not retured by the 
			// PUT call on the api, in order to send this result as ._next for everywhere in the app, let's make sure we have all the properties
			this.pushOrUpdateRecord(this.selectedCompany.integrations, _integration);
			this.companyService._next(this.selectedCompany);
			this.record = _integration as Integration;
			this.updateForm();

			this.form.resetCodes({ success: 'SAVED' })

			if (this.focusOn == 'config' && preStatus == 'connected') { this.setFocus(this.record.processor == 'Base' ? 'ops' : 'track'); }

			this.form._timer = setTimeout(() => {
				this.resolveModal('hide');
			}, this.form.countdown(6));

			this.markAsLoaded(this.form);
		}, (err) => {
			this.markAsLoadingError(this.form);
		});
	}

	private _ondemand: { id: string, name: string }[];
	get onDemandOperations(): { id: string, name: string }[] {

		if (this._ondemand == null) {
			this._ondemand = !this.record.ondemand_options ? [] : (this.record.ondemand_options.split("&").map((_) => { let o = _.split("="); return { id: o[0], name: o[1] } }))
		}
		return this._ondemand;

	}
	onDemand(op: string): void {
		
	}

	onRequest(): void {
		let changes = this.form.getChanges();
		changes['provider'] = this.record.provider;
		changes['status'] = 'requested';

		this.form.disable();
		this.integrationService.saveOrCreate(new Integration(changes)).subscribe((_integration) => {

			//this.setupFormToInstall()
			this.form.resetCodes({ success: 'REQUESTED' })
			//this.form.reset(); // ensure there are no lingering changes detected and warns the user -- default behaviour of the resolveModal
			//this.resolveModal('uninstalled');
			this.form._timer = setTimeout(() => {
				this.resolveModal('close');
			}, this.form.countdown());

		});
	}

	uninstallIntegration(modalTitle: string, modalBody: string): boolean {

		let options: SweetAlertOptions = this.alertService.alertOptions('warning', this.__name+'.Buttons.Alerts.uninstall', 'cancel');
		options.title = modalTitle;
		options.html = modalBody;
		//options.footer = this.crispHelpdeskLink('Learn about automated emails','xfgvbr');

		this.alertService.confirm(options).then((_) => {

			this.companyService.status('Removing integration');
			this.integrationService.remove(this.record.id).subscribe({
				next: (_integration) => {
					
					this.record = _integration
					
					this.companyService.status();
					//this.companyService.snack('Integration removed', 'success');

					this.pushOrUpdateRecord(this.selectedCompany.integrations, _integration);
					this.companyService._next(this.selectedCompany);

					this.setupFormToInstall()
					this.form.resetCodes({ success: 'UNINSTALLED' })
					//this.form.reset(); // ensure there are no lingering changes detected and warns the user -- default behaviour of the resolveModal
					//this.resolveModal('uninstalled');
					this.form._timer = setTimeout(() => {
						this.resolveModal('hide');
					}, this.form.countdown());
				},
				error: (err) => {
					this.companyService.snack('Integration could not be removed', 'error');
				}
			});
		});

		return false; // required so that we can use the !! operator on (click) and prevent the href navigation
	}

	public hasConfigs(): boolean {
		return this.record.fieldConfigs().filter(_ => _.name[0] != '_').length > 0
	}
	

	setFocus(tab: 'info' | 'config' | 'ops' | 'track' | 'request' | 'help'): boolean {

		this.focusOn = tab;
		
		// whatever has been requested; 
		// if it's not installed, or being requested, then it the only option is info
		if (!this.record.isInstalled() && !['info','request'].includes(tab)) { this.focusOn = 'info'; }

		// if it's not configured and has configuration, force it
		if (this.record.isReadyForConfig() && this.hasConfigs()) { this.focusOn = 'config'; }

		return false; // required to ensure we can call setFocus on click from an <A> tag, without triggering a browser routing change (navigating to '#')

	}

	public resolveModal(from?: string, vars?: any): boolean {

		// the default resolveModal only closes the popup, here we want to make sure any queryString parameters are removed
		// eg. in the context of oauth ?code=something 
		if (Object.keys(this.route.snapshot.queryParams).length > 0) {
			debugger;
			this.router.navigate([this.route.snapshot.url], {queryParams:{}})
		}

		return super.resolveModal(from, vars)
	}
}