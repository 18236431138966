import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { TrolySearch } from '../core/models/form_objects';
import { Customer } from '../core/models/troly/customer.model';
import { Order } from '../core/models/troly/order.model';
import { Product } from '../core/models/troly/product.model';
import { Task } from '../core/models/troly/task.model';
import { User } from '../core/models/troly/user.model';
import { BaseLayout } from './base.layout';

import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { Integration } from '../core/models/troly/integration.model';
import { Interaction } from '../core/models/troly/interaction.model';
import { uuid } from '../core/models/utils.models';
import { CustomerService } from '../core/services/troly/customer.service';
import { OrderService } from '../core/services/troly/order.service';
import { ProductService } from '../core/services/troly/product.service';
import { UserService } from '../core/services/troly/user.service';
import { MENU } from './sidebar/menu';
import { MenuItem } from './sidebar/menu.model';

@Component({
	selector: 'admin-layout',
	templateUrl: './admin.layout.html',
	styleUrls: ['./admin.layout.scss'],
})
export class AdminLayout extends BaseLayout implements OnInit, AfterViewInit {

	isCondensed: boolean = false;
	isVertical: boolean = true;

	menuItems: MenuItem[] = [];

	protected orderService: OrderService = inject(OrderService);
	protected customerService: CustomerService = inject(CustomerService);
	protected productService: ProductService = inject(ProductService);
	protected userService: UserService = inject(UserService);

	constructor() {

		super();

		this.menuItems = MENU;

		this.companyService.record$.pipe(filter(_ => !!_), takeUntil(this.observablesDestroy$)).subscribe(_company => {
			if (_company.integrations) { // we are not loading integrations here, the topbar is already doing that and so are other components.
				_company.integrations.map((_integration) => {
					if (_integration.status != 'uninstalled') {
						this.addAddonMenu(_integration.provider, _integration.name, _integration.logo, _integration.env?.admin_url, _integration.status, _integration.show_in_navigation); // adds or updates the the menu item
					} else {
						this.addAddonMenu(_integration.provider); // removes the menu item
					}
				});
			}
		});
	}

	/** 
	 * The side drawer is controlled by the layout, however opend/closed depending on the 
	 * user-ui cached setting (see `GenericService.cacheUiKey` and `GenericService.uiNotifier$`)
	 */
	drawerMode: MatDrawerMode = 'over';
	@ViewChild('drawer') drawer: MatDrawer;

	/**
	 * Change the open/closed/over/side status of the page drawer.
	 * @param mode if 'side' or 'over' this opens the drawer. anything else, closes.
	 */
	public toggleDrawerPin(mode?:string) {
		mode ||= this.drawerMode == 'over' ? 'side' : 'over'
		this.userService.storeUiKey(this.CACHE_KEYS.DRAWER_MODE, mode);
	}
	

	protected cd:ChangeDetectorRef = inject(ChangeDetectorRef);
	
	ngOnInit() {

		super.ngOnInit();

		// retrieve the user default setting if one.
		this.isCondensed = this.companyService.cachedUiKey<boolean>(this.CACHE_KEYS.IS_CONDENSED, this.isCondensed);
		this.isVertical = this.companyService.cachedUiKey<boolean>(this.CACHE_KEYS.IS_VERTICAL, this.isVertical);


		// listen to changes and apply the correct body classes and attributes
		this.userService.uiNotifier$.subscribe((_db) => {
			if (_db[this.CACHE_KEYS.IS_VERTICAL] !== undefined) {
				this.isVertical = _db[this.CACHE_KEYS.IS_VERTICAL] as boolean;
			}
			document.body.setAttribute('data-layout', this.isVertical ? 'vertical' : 'horizontal');
			document.body.classList.toggle('sidebar-enable', this.isVertical)

			if (_db[this.CACHE_KEYS.IS_CONDENSED] !== undefined) {
				this.isCondensed = _db[this.CACHE_KEYS.IS_CONDENSED] as boolean;
			}

			document.body.classList.toggle('vertical-collapsed', this.isVertical && this.isCondensed);

			if (this.drawer && _db[this.CACHE_KEYS.DRAWER_MODE]) {
				// note: 👆 the drawer is only available after the view has loaded
				if (_db[this.CACHE_KEYS.DRAWER_MODE] == 'side' || _db[this.CACHE_KEYS.DRAWER_MODE] == 'over') {
					if (!this.drawer.opened) { this.drawer.open(); }
					this.drawerMode = _db[this.CACHE_KEYS.DRAWER_MODE] as MatDrawerMode;
					this.cd.detectChanges(); // this is to make sure that Angular is aware that the view might have changed based on which panel is displayed (ultimately, just the classes applied based on the currentPanel value)
				} else {
					if (this.drawer.opened) { this.drawer.close(); }
				}
			}
		});


		//
		// 1. Listening to UI notifications for badge count display
		// 2. Load the initial values
		//
		const defaultParams = { company_id: this.selectedCompany.id }
		// attach live 'new customers' count badge
		this.orderService.uiNotifier$.pipe(filter(_ => _['order-new-count'])).subscribe((_) => {
			this.updateBadge('orders-all', null, _['order-new-count'], 'primary');
		});
		if (!this.orderService.cachedUiKey('order-new-count')) {
			this.orderService.search('', 1, 0 , { was_created_on: '' }).pipe(filter(_ => !!_)).subscribe((_: TrolySearch<Order>) => {
				this.orderService.storeUiKey<number>('order-new-count', _.meta.count, 1);
			});
		}

		// attach live 'new customers' count badge
		this.customerService.uiNotifier$.pipe(filter(_ => _['customer-new-count'])).subscribe((_) => {
			this.updateBadge('customers', 'customers-list', _['customer-new-count'], 'success');
		});
		if (!this.customerService.cachedUiKey('customer-new-count')) {
			this.customerService.search('', 1, 0 , { was_created_on: '' }).pipe(filter(_ => !!_)).subscribe((_: TrolySearch<Customer>) => {
				this.customerService.storeUiKey<number>('customer-new-count', _.meta.count, 1);
			});
		}

		// attach live 'new products' count badge
		this.productService.uiNotifier$.pipe(filter(_ => _['product-new-count'])).subscribe((_) => {
			this.updateBadge('products', 'products-list', _['product-new-count'], 'success');
		});
		if (!this.productService.cachedUiKey('product-new-count')) {
				this.productService.search('', 1, 0 , { was_created_on: '' }).pipe(filter(_ => !!_)).subscribe((_: TrolySearch<Product>) => {
				this.productService.storeUiKey<number>('product-new-count', _.meta.count, 1);
			});
		}

		// attach live 'new users' count badge
		this.userService.uiNotifier$.pipe(filter(_ => _['user-new-count'])).subscribe((_) => {
			this.updateBadge('company', 'company-security', _['user-new-count'], 'warning');
		});
		if (!this.userService.cachedUiKey('user-new-count')) {
			this.userService.search('', 1, 0 , { was_created_on: '' }).pipe(filter(_ => !!_)).subscribe((_: TrolySearch<User>) => {
				this.userService.storeUiKey<number>('user-new-count', _.meta.count, 2);
			});
		}
		
	}

	ngAfterViewInit(): void {
		this.authService.layoutActionNotifier.pipe(filter(_ => !!_),takeUntil(this.observablesDestroy$)).subscribe((_) => this.layoutAction(_) );
	}

	isMobile() {
		const ua = navigator.userAgent;
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
	}

	/**
	 * on settings button clicked from topbar
	 */
	onToggleLayoutPreference(value?: string) {

		value = value || (this.isVertical ? 'horizontal' : 'vertical')
		this.userService.storeUiKey<boolean>(this.CACHE_KEYS.IS_VERTICAL, value == 'vertical')
	}

	/**
	 * On mobile toggle button clicked
	 */
	onToggleSidebarCompact(value?: boolean) {
		if (value === undefined) { value = !this.isCondensed; }
		this.userService.storeUiKey<boolean>(this.CACHE_KEYS.IS_CONDENSED, value)
	}


	/**
	 * 
	 * @param event 
	 */
	onHelpRequest(event: number) {

		if ((<any>window).Intercom) {
			if (event >= 0) {
				(<any>window).Intercom(event > 0 ? 'showMessages' : 'show')
			} else {
				(<any>window).Intercom('hide')
			}
		}

		if ((<any>window).$crisp) {
			if (event >= 0) {
				(<any>window).$crisp.push(['do', 'chat:open'])
			} else {
				(<any>window).$crisp.push(['do', 'chat:close'])
			}
		}
	}

	public addAddonMenu(provider: string, name?: string, icon?: string, url?: string, status?: string, show_in_navigation?: string) {

		let addonsMenu = this.menuItems.find(_ => _.id == 'addons');

		if (name && (
				show_in_navigation == 'always' || (['connected','error','warning'].includes(status) && show_in_navigation != 'never')
			)) {
			let index = addonsMenu.subItems.findIndex(_ => _.id == 'Addons.' + provider)
			let menu = {};

			if (index >= 0) { menu = addonsMenu.subItems[index]; }

			icon = icon || `https://res.cloudinary.com/subscribility-p/image/upload/cdn/assets/app-static/addons/${provider.toLowerCase()}-icon.png`

			Object.assign(menu, {
				id: 'Addons.' + provider,
				label: name.indexOf(' ― ') >= 0 ? name.split(' ― ')[0] : name,
				icon: icon,
				//link: '/frame/'+encodeURIComponent(url),
				//link: url,
				//link: '/apps/dashboard/'+provider,
				action: { createIntegration:true, integration: { provider: provider } },
				status: ['connected', 'warning', 'error'].includes(status) ? status : ''
			});

			// Add the currently installed add-ons for this company to the menu
			// items so we can display them in the collapse menu
			addonsMenu.subItems.splice(
				index >= 0 ? index : 0, // splice at the known index, or at the beginning of the array
				index >= 0 ? 1 : 0, // remove (1) known item and replace, or replace nothing (0)
				menu);

			addonsMenu.subItems.sort((a, b) => {

				// if there's no label (the ID is used to generate the label -- only for dashboard and add menus)
				// then push down, else, sort alpha
				return !a.label ? 1 : (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0;
			})

		} else {
			// remove the element if found.
			addonsMenu.subItems = addonsMenu.subItems.filter(_ => _.id != 'Addons.' + provider);
		}
	}

	public enableTemporaryMenu(menuId: string, label: string, url: string) {

		//this.menuItems.forEach((_parent) => {
		//_parent.find
		//});
		//let [_parent, _child] = parentchild.split('/');

		//var regexp = MENU.find((parent) => { return parent.id == _parent; }).subItems.find((children) => { return children.id == _child }).link
		//let menu = this.menuItems.find((parent) => { return parent.id == _parent; }).subItems.find((children) => { return children.id == _child })

	}


	private updateBadge(mainMenu, childMenu, count, variant) {

		if (count > 0) {
			this.menuItems.map((parent) => {
				if (parent.id === mainMenu) {
					if (childMenu) {
						parent.subItems.map((child) => {
							if (child.id === childMenu) {
								// create a badge for the mainMenu.childMenu
								child.badge = {
									variant: variant, text: count, link: child.link, linkQueryParams: { was_created_on: '' }
								}
							}
						})
					} else {
						parent.badge = {
							variant: variant, text: count, link: parent.link, linkQueryParams: { was_created_on: '' }
						}
					}
				}
			});
		} else {
			// remove the badge from the mainMenu.childMenu
			this.menuItems.map((parent) => {
				if (parent.id === mainMenu) {
					if (childMenu) {
						parent.subItems.map((child) => {
							if (child.id === childMenu) {
								child.badge = null;
							}
						})
					} else {
						parent.badge = null;
					}
				}
			});
		}
	}

	@ViewChild('createCustomer') createCustomerModal: ElementRef;
	@ViewChild('createProduct') createProductModal: ElementRef;
	@ViewChild('editOrder') editOrderModal: ElementRef;
	@ViewChild('createTask') createTaskModal: ElementRef;
	@ViewChild('createInteraction') createInteractionModal: ElementRef;
	@ViewChild('createSms') createSmsModal: ElementRef;
	@ViewChild('createEmail') createEmailModal: ElementRef;
	@ViewChild('createIntegration') createIntegrationModal: ElementRef;
	
	selectedIntegration: Integration = new Integration();
	selectedInteraction: Interaction = new Interaction();
	selectedCustomer: Customer = new Customer();
	selectedOrder: Order = new Order();
	selectedProduct: Product = new Product();
	selectedTask: Task = new Task();
	selectedRecordIds: uuid[]=[]; // used to received selected records to be passed to a modal // this is not meant to be null

	public layoutAction(message: { [key: string]: any; }): void {

		this.windowService.clearModal();
		
		// this is list of records being selected and sent for a 'bulk-enabled' modal window
		if (message['record_ids']) { this.selectedRecordIds = message['record_ids'] }
		
		if (message['customer']) { this.selectedCustomer = message['customer']; }
		if (message['product']) { this.selectedProduct = message['product']; }
		if (message['order']) { this.selectedOrder = message['order']; }
		if (message['task']) { this.selectedTask = message['task']; }
		if (message['interaction']) { this.selectedInteraction = message['interaction']; }
		if (message['integration']) { this.selectedIntegration = message['integration']; }

		if (message['createTask']) { this.openModal(this.createTaskModal); }
		if (message['createInteraction']) { this.openModal(this.createInteractionModal); }
		if (message['createCustomer']) { this.openModal(this.createCustomerModal); }
		if (message['createProduct']) { this.openModal(this.createProductModal); }
		
		if (message['editOrder']) { this.openXLargeModal(this.editOrderModal) }
		if (message['createIntegration']) { this.openLargeModal(this.createIntegrationModal) }

		if (message['sendEmail']) { this.openModal(this.createEmailModal); }
		if (message['sendSms']) { this.openModal(this.createSmsModal); }

		/**
		 *  arch: There's a question around which is better: having modal-compoents
		 */
			
		this.windowService.currentModal?.result.then((_) => {
			this.selectedCustomer = new Customer();
			this.selectedProduct = new Product();
			this.selectedTask = new Task();
			this.selectedIntegration = new Integration();
			this.selectedInteraction = new Interaction();
		});

	}
}
