import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TrolyListComponent } from 'src/app/core/components/list.component';
import { ITrolySocketMessage } from 'src/app/core/models/form_objects';
import { Company } from 'src/app/core/models/troly/company.model';
import { IntegrationLog } from 'src/app/core/models/troly/integration_log.model';
import { uuid } from 'src/app/core/models/utils.models';
import { IntegrationService } from 'src/app/core/services/troly/integration.service';
import { IntegrationLogService } from 'src/app/core/services/troly/integration_log.service';
import { WebsocketService } from 'src/app/core/services/troly/websocket.service';
import { TrolyAngularModule } from '../../../angular.module';
import { TrolySharedModule } from '../../../shared.module';

@Component({
	selector: 'list-apps-events-card',
	templateUrl: './list-apps-events.card.html',
	styleUrls: ['./list-apps-events.card.scss'],
	standalone: true,
	imports: [ TrolyAngularModule, TrolySharedModule]
})
export class ListAppsEventsCard extends TrolyListComponent<IntegrationLog> {

	override readonly __name:string = 'ListAppsEventsCard'
	override readonly __path:string = 'shared/ui/integrations/list-apps-events'

	/**
	 * The currently loaded Company as advertised by `@this.service` and subscribed to by `@this.subscribeRecord`.
	 * @see TrolyComponent.record (Override)
	 */
	declare record:Company;

	appsFilter = 'all';
	appsFilters = ['all'];

	severityFilter = 'all'
	severityFilters = ['all', '_status', 'success', 'warning', 'error', '_type', 'info', 'debug']

	@Input() cardTitle: string = '<em>Real-Time Apps Tracking</em>';
	@Input() containerCss: string = 'card';
	@Input() toolbar: string = "_all";
	@Input() defaultFilter: {};

	@Input() helpCollection: string = "";

	@Input() pagination: string = "_all"; //
	@Input() advancedCol: boolean = true;
	@Input() applicationCol: boolean = true;

	@Input() loggable_type?: 'Customer' | 'Product' | 'Order'
	@Input() loggable_id?: uuid;

	@Input() provider?: string;
	@Input() integration_id?: uuid;

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected integrationLogService: IntegrationLogService = inject(IntegrationLogService);
	protected ws: WebsocketService = inject(WebsocketService);
	protected integrationService: IntegrationService = inject(IntegrationService);

	constructor() {
		super();

		this.log(`${this.__name}.constructor`, 'STACK');
			
		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.integrationLogService;
		
		// overrides the default service notifier to ensure we are only notified of these (POS-ony) recrds. must be set before cache key
		this.listNotifier = new BehaviorSubject<IntegrationLog[]>([]); 

		this.record_restrictions = {
			with_loggable: true
		}

		this.selectingRecords = true; // enabling the showsegment toggle

	}

	protected receiveInputParams(changes: SimpleChanges): void {

		//This widget is used in various contexts
		// 1 - Tracking a single specific record (loggable_id and loggable_type being set)
		// 2 - Tracking a single application installed (integration_id is set)
		// 3 - Tracking a all applications for a given provider (provider is set)
		// 4 - Tracking operations onto a TYPE of record with (loggable_type being set, but no loggable_id)

		if (changes.loggable_type?.currentValue) {
			this.record_restrictions['loggable_type'] = this.loggable_type;
		}

		if (changes.loggable_id?.currentValue) {
			this.record_restrictions['loggable_id'] = this.loggable_id;
		}

		if (changes.provider?.currentValue) {
			this.record_restrictions['provider'] = this.provider;
		}

		if (changes.integration_id?.currentValue) {
			this.record_restrictions['integration_id'] = this.integration_id;
		}

		if (changes.defaultFilter?.currentValue) {
			this.setQuery(this.defaultFilter);
		}

		super.receiveInputParams(changes);
	}


	protected loadData(): void {

		if (this.loggable_id) {
			this.search_params['severity'] = ['info', 'debug','warning','error']; // default to show all logs
		}

		super.loadData();

		this.log(`${this.__name}.loadData`, 'STACK');
	}

	protected attachChangeNotifiers(): void {

		super.attachChangeNotifiers();

		this.log(`${this.__name}.attachChangeNotifiers`, 'STACK');

		this.subscribeRecordDistinct<Company>(this.companyService).subscribe(_company => {
			
			this.initRecordAttributeOnce('integrations', this.companyService.loadIntegrations());  // loadIntegrations, safe within subscribeRecord

			let _marketing = ['Mailchimp', 'CampaignMonitor', 'ConstantContact'];
			_company.integrations.filter(_ => _marketing.includes(_.provider)).map((_) => {
				if (!this.appsFilters.includes('_email')) { this.appsFilters.push('_email'); this.appsFilters.push('email_all'); }
				this.appsFilters.push(_.provider);
			});

			let _sales = ['Wordpress', 'Shopify', 'Tasting'];
			_company.integrations.filter(_ => _sales.includes(_.provider)).map((_) => {
				if (!this.appsFilters.includes('_website')) { this.appsFilters.push('_website'); this.appsFilters.push('website_all'); }
				this.appsFilters.push(_.provider);
			});

			let _reporting = ['Xero', 'GDrive', 'Office365'];
			_company.integrations.filter(_ => _reporting.includes(_.provider)).map((_) => {
				if (!this.appsFilters.includes('_data')) { this.appsFilters.push('_data'); this.appsFilters.push('data_all'); }
				this.appsFilters.push(_.provider);
			});

			let _misc = ['UsaTaxReporting', 'Facebook', 'Twitter', 'LinkedIn', 'Instagram', 'commweb'];
			_company.integrations.filter(_ => _misc.includes(_.provider)).map((_) => {
				if (!this.appsFilters.includes('_misc')) { this.appsFilters.push('_misc'); /* no 'all' element for misc */ }
				this.appsFilters.push(_.provider);
			});
		});

	}

	public setQuery(value?: {}): boolean {

		value = value || {}

		let reload: boolean = this.records == null;

		if (value['app'] && this.appsFilters != value['app']) {
			this.appsFilter = value['app'];

			if (this.appsFilter.match('^all$')) {
				delete this.search_params['processor']
				delete this.search_params['provider']
			} else if (this.appsFilter.match('_all$')) {
				this.search_params['processor'] = this.appsFilter.replace('_all', '');
				delete this.search_params['provider'];
			} else {
				this.search_params['provider'] = this.appsFilter;
				delete this.search_params['processor'];
			}
			reload = true;
		}

		if (value['severity'] && this.severityFilter != value['severity']) {

			this.severityFilter = value['severity'];
			
			if (this.severityFilter == 'all') {
				delete this.search_params['severity']
			} else if (this.severityFilter == 'success') {
				this.search_params['severity'] = ['info', 'debug'];
			} else {
				this.search_params['severity'] = this.severityFilter;
			}
			reload = true;
		}

		if (reload) {
			this.loadRecords();
		}

		return false // allows to have a (click) handler with !! operator (so that there's no route rewrite via href=#)
	}

	/**
	 * Applies the filtering business logic before life adding // updating the record in the list
	 * @param event 
	 */
	addFromSocket(event: ITrolySocketMessage): void {

		if (this.record_restrictions['loggable_id'] && this.record_restrictions['loggable_id'] != event['data']['loggable_id']) { return; }
		if (this.record_restrictions['loggable_type'] && this.record_restrictions['loggable_type'] != event['data']['loggable_type']) { return; }
		if (this.search_params['provider'] && this.search_params['provider'] != event['data']['provider']) { return; }
		if (this.search_params['severity'] && !this.search_params['severity'].includes(event['data']['severity'])) { return; }

		super.addFromSocket(event)
	}
}
