import { Component, OnInit, inject } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { BaseComponent } from '../core/components/base.component';
import { AuthService } from '../core/services/auth.service';
import { CompanyService } from '../core/services/troly/company.service';
import { UserService } from '../core/services/troly/user.service';
// Even though this is called 'Component' we declare as a directive
// as that is the requirement for base class components in Angular 9+
@Component({
	template: ''
})

// Base component that enables a single method of destroying observable
// subscriptions when a component is destroyed
export abstract class BaseLayout extends BaseComponent implements OnInit {


	protected userService: UserService = inject(UserService);
	protected companyService: CompanyService = inject(CompanyService);

	protected authService: AuthService = inject(AuthService);

	constructor() {
		super();

		const companyUpdate = this.companyService.record$.pipe(takeUntil(this.observablesDestroy$),filter(_ => !this.selectedCompany?.id || _.sameAs(this.selectedCompany)))
		const userUpdate = this.userService.record$.pipe(takeUntil(this.observablesDestroy$),filter(_ => !this.authenticatedUser?.id || _.sameAs(this.authenticatedUser)))

		companyUpdate.subscribe((_) => { this.selectedCompany = _; });
		userUpdate.subscribe((_) => { this.authenticatedUser = _; });

		this.service = this.userService; // used to esure we can load user layout preferences
		
	}

	ngOnInit() {

		// this serves to apply the actual darkmode attribute to the body
		this.service.uiNotifier$.subscribe((_db) => {
			if (_db[this.CACHE_KEYS.DARK_MODE] !== undefined) {
				if (_db[this.CACHE_KEYS.DARK_MODE] == 'auto') {
					let event = window.matchMedia('(prefers-color-scheme: dark)')
					document.body.setAttribute('data-sidebar', event.matches ? 'dark' : 'light');
				} else {
					document.body.setAttribute('data-sidebar', _db[this.CACHE_KEYS.DARK_MODE] == 'on' ? 'dark' : 'light');
				}
			}
		})

		// listen to system darkmode setting being applied and "apply it" ourselves if needed
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
			if (this.service?.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto') == 'auto') {
				this.darkMode = 'auto'; // we specify to "stay" in auto mode, so that the value gets rewrite, and the nofifier causes the logic (subscribed -- above) to be retriggered
			}
		});

		this.darkMode = this.service?.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto');
	}
}